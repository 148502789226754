import React, { useEffect, useState } from 'react'
import BreadcrumbBack from '../../../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../../../components/button/CustomButton'
import {  useLocation, useNavigate, useParams } from 'react-router-dom'
import { deleteManyAdminTestSeriesQuestion, getSubModuleQuestionDetails } from '../../../../../service/admin/adminTestSeries.service'
import Loaders from '../../../../../components/loaders/Loaders'
import PaginationDataGrid from '../../../../../components/tables/PaginationDataGrid'
import Models from '../../../../../components/models/Models'
import WordUpload from '../../../../../components/uploads/WordUpload'
import TableButton from '../../../../../components/button/TableButton'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AdminGroupTestQuestions() {
    const [open, setOpen] =  useState(false)
    const [subModuleDetails, setSubModuleDetails] = useState<any>();
    const [pageLoading, setPageLading] = useState(false)
    const navigate = useNavigate()
    const subModuleId:any = useParams()
    const location = useLocation();
    const query = new URLSearchParams(location.search)
    const fetchSubModuleDetails =async(id:string)=>{
    
        let get:any = await getSubModuleQuestionDetails(id)
        if(get?.status === "success"){
            setSubModuleDetails(get?.message)
        }
    }
    useEffect(()=>{
        setPageLading(true)
        fetchSubModuleDetails(subModuleId.id)
        setPageLading(false)
    },[])
    const handleFileUpload = ()=>{
      setOpen(!open)
    }
    const handleDeleteAllQuestions = ()=>{
      Swal.fire({
        title: 'Are you sure want to Delete all Questions ?',
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true,
        preConfirm: async(value) => {
          const del:any = await deleteManyAdminTestSeriesQuestion(subModuleId.id)
              if(del?.status === 'success'){
                fetchSubModuleDetails(subModuleId.id)
              return toast.success(del.message);
              }
              
        }
      })
    }
  return (
    <>
    {
        pageLoading === true ? 
            <Loaders type='spinning' />
        
            :
            <>
             <Helmet>
    <title>{`Group Test Series   | ${window.location.hostname}`}</title>
</Helmet>
            <BreadcrumbBack title={`${subModuleDetails?.name}'s Questions`}>
            <TableButton  onClick={handleFileUpload} text='Upload Question' icon={true} title='upload' toottip='Upload Word'  />
           
             <CustomButton
             onClick={(()=>navigate(`/admin/test-series/submodules-group-test/questions/create/${subModuleId.id}?name=${subModuleDetails?.name}&md=${query.get("md")}&mod=${query.get("mod")}&cat=${query.get("cat")}`))}
             
       title="Create Questions"
       icon={true}
       iconName="create"
     />
          {
              subModuleDetails?.questions > 0 &&
              <TableButton  onClick={handleDeleteAllQuestions} text='Delete All Questions' icon={true} title='delete' toottip='Delete all questions'  />
            }
   </BreadcrumbBack>
   <div className="my-2 p-3 rounded-xl bg-gray-200">
   
    <div className="flex flex-row flex-wrap justify-between gap-3 my-1">
    <p>Total Questions: {subModuleDetails?.questions}</p>
    <p>Duration: {subModuleDetails?.duration}</p>
    <p>Attempts: {subModuleDetails?.attempt}</p>
    <p>Marks: {subModuleDetails?.marks}</p>
    <p>Negative Marks: {subModuleDetails?.negative_marks}</p>
    </div>
   </div>
   <div className="my-2">
     <PaginationDataGrid name="adminTestSeriesQuestion" dataId="group" id={subModuleId.id} otherId={`?name=${subModuleDetails?.name}&md=${query.get("md")}&mod=${query.get("mod")}&cat=${query.get("cat")}`} />
   </div>

   <Models name='Upload Questions' isOpen={open}  isClose={setOpen} >
            <WordUpload name='groupTestSeriesQuestion' data={subModuleId.id} other={[query.get('mod'),query.get('md')]} accept='.doc, .docx'   open={setOpen} />
        </Models>
   </>
    }
 </>
  )
}
