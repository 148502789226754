import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Player from "../player/Player";
export default function ImageSliderSimple(props: { images: any }) {
    const handleIMgClick = (url:string)=>{
        window.open(url, "_blank", "noopener,noreferrer");
    }
  return (
    <div className="h-[300px]">
      
        {props.images?.length > 0 &&
         <Slide>
            {
                props.images.map((image: any,index:number) => (
                    <div className="each-slide-effect text-center flex justify-center  bg-gray-200 items-center" key={index}>
                        {
                            image?.type === "img" &&
                            <img onClick={(()=>handleIMgClick(image?.url))}  src={image?.url} className=" h-[250px] hover:cursor-pointer" alt="" />
                        }
                        {
                            image?.type === "vid" &&
                            <Player url={image?.url}  />
                        }
                    </div>
                ))
            }
          </Slide>
         }
      
    </div>
  );
}
