import React, { useEffect, useState } from 'react'
import { getStudentSubscription, getStudentSubscriptionPaymentHistory } from '../../../service/student/studentCourses.service';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import { FaAngleDown, FaCalendar, FaCalendarCheck, FaCalendarTimes, FaRupeeSign } from 'react-icons/fa';
import moment from 'moment';
import Loaders from '../../../components/loaders/Loaders';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { BreadCrumRoutes } from '../../../helpers/helpers';
import DataNotFound from '../../../components/errors/DataNotFound';
import { Helmet } from 'react-helmet-async';

export default function StudentSubscription() {

    const [studentSubscription, setStudentSubscription] = useState<any>([]);
    const [paymentSubscription, setPaymentSubscription] = useState<any>([]);
    const [pageLoad, setPageLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paymentLoad, setPaymentLoad] = useState(false);
    const [openAccordions, setOpenAccordions] = useState<number[]>([]);
    const [breadCrumData, setBreadCrumData] = useState<any>()
    const toggleAccordion = (index: number) => {
        if (openAccordions.includes(index)) {
          setOpenAccordions(openAccordions.filter(i => i !== index)); // Close accordion
        } else {
            fetchSubscriptionPayment(studentSubscription[index].id)
          setOpenAccordions([...openAccordions, index]); // Open accordion
        }
      };
    const fetchSubscription = async()=>{
        setPageLoad(true);
        const get:any = await getStudentSubscription();
        if(get?.status === "success"){
            setPageLoad(false);
            setStudentSubscription(get?.message);
        }
        setPageLoad(false);
    }
    const fetchSubscriptionPayment = async(id:string)=>{
        setPaymentLoad(true)
        const get:any = await getStudentSubscriptionPaymentHistory(id);
        if(get?.status === "success"){
            setPaymentLoad(false);
            setPaymentSubscription(get?.message);
        }
        setPaymentLoad(false);
    }
    useEffect(()=>{
        fetchSubscription()
        setBreadCrumData(BreadCrumRoutes("Subscriptions",'/student/subscription',"studentsubscription",true))
    },[])
    const getPayments = (id:string,amount:any)=>{
        setPaymentSubscription([])
        if(amount !== "0"){
            fetchSubscriptionPayment(id)
        }else{
            setPaymentSubscription([])
        }
        
    }
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      };
      
  return (
    
        pageLoad ? 
        <Loaders type='spinner' />
        :
        <>
         <Helmet>
    <title>{`Subscription | ${window.location.hostname}`}</title>
</Helmet>
  <BreadCrumb routes={breadCrumData}  />
    <div className='my-5'>
        {
            studentSubscription.length > 0 ?
                studentSubscription.map((item:any, index:number)=>(
                    <Accordion disabled={item.course_amount === "0" ? true : false} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}
                    sx={{
                        marginY:2,
                         boxShadow:0,
                        borderLeft: 1,
                        borderLeftWidth:4,
                        borderLeftColor:"#9333ea",
                      
                        background:'rgb(229 231 235)',
                        '&.Mui-disabled': {
                            backgroundColor: 'rgb(229 231 235)',
                            opacity:1
                            },
                        
                    }}
                    >
        <AccordionSummary
            onClick={(()=>getPayments(item.id,item.course_amount))}
          expandIcon={<FaAngleDown />} 
          aria-controls={`panel${index+1}-content`}
          id={`panel${index+1}-header`}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            '&.Mui-disabled': {
                opacity:1
                },
            
        }}
        >
          <div className=' hover:cursor-pointer  w-[100%] ' >
                            <div className="!flex  lg:flex-row  flex-col md:flex-col sm:flex-col justify-between " >
                            <div className='flex items-center gap-2 '>
                                {
                                    moment(item?.expiry_date).isBefore(new Date()) ? 
<p className='bg-red-500 px-4 py-1 rounded-lg mr-5 text-white'>Expired</p>
                                    :
<p className='bg-green-500 px-4 py-1 rounded-lg mr-5 text-white'>Active</p>
                                }
                             <img src={item.course.image} className='h-[50px] rounded-lg' alt="" /> <p className='text-lg font-bold'>{item.course.name} </p>
                            </div>
                           <div className='flex gap-4 mr-0 sm:mr-0 md:mr-10 mt-5 sm:mt-5 md:mt-0 sm:flex-col flex-col md:flex-row  '>
                           <div className='flex gap-2 items-center'>
                                    <FaRupeeSign className=' bg-purple-900 text-white p-2 text-[2.2rem] rounded-lg' />
                                    <div>
                                        <p className=' font-bold text-sm'>Amount</p>
                                        <p className='text-sm'>{item.course_amount === "0" ? "FREE" : item.course_amount}</p>
                                    </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <FaCalendarCheck className=' bg-purple-600 text-white p-2 text-[2.2rem] rounded-lg' />
                                    <div>
                                        <p className=' font-bold text-sm'>Started On</p>
                                        <p className='text-sm'>{moment(item.start_date).format("DD MMM YYYY")}</p>
                                    </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <FaCalendarTimes className=' bg-red-600 text-white p-2 text-[2.2rem] rounded-lg' />
                                    <div>
                                        <p className=' font-bold text-sm'>Expires On</p>
                                        <p className='text-sm'>{moment(item.expiry_date).format("DD MMM YYYY")}</p>
                                    </div>
                                </div>
                           </div>
                            </div>
                            </div>
        </AccordionSummary>
        <AccordionDetails>
        {
                                paymentSubscription.length > 0 &&
                                <div className="relative overflow-x-auto rounded-lg  max-h-[300px] ">
                                   {
                                    paymentLoad ? "Loading..." :
                                    <>
                                     <p>Payement History</p>
                                    <table className='w-full   text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
                                        <thead className="text-xs rounded-lg text-gray-200 uppercase  bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
                                            <th className="py-2 px-2 text-center">#</th>
                                            <th className="py-2 px-2 text-center">Paid</th>
                                            <th className="py-2 px-2 text-center">Remaining</th>
                                            <th className="py-2 px-2 text-center">Date</th>
                                            <th className="py-2 px-2 text-center">Status</th>
                                            <th className="py-2 px-2 text-center">Download</th>
                                        </thead>
                                        <tbody>
                                            {
                                                paymentSubscription.map((item:any,index:number)=>(
                                                    <tr key={index} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 text-center even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                        <td className='px-2 py-2 '>{index+1}</td>
                                                        <td className='px-2 py-2 text-center'>{item.paid}</td>
                                                        <td className='px-2 py-2 text-center'>{item.remaining}</td>
                                                        <td className='px-2 py-2 text-center'>{moment(item.date).format("DD MMM YYYY")}</td>
                                                        <td className='px-2 py-2 text-center'>{item.complete ? "Complete" : "Not Complete"}</td>
                                                        <td className='px-2 py-2 text-center'>
                                                            {/* <button className='text-white bg-purple-700 px-3 py-1 rounded-lg'>Download</button> */}
                                                            </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    </>
                                   }
                                    </div>
                            }
        </AccordionDetails>
      </Accordion>
                    
                    
                ))
                :
                <div className="col-span-12">
                <DataNotFound title={"No Live CLass Found"} />
            </div>
        }
    </div>
    </>
    
  )
}
