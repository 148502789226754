import React, { useEffect, useState } from 'react'
import { studentCourseHoldPayments } from '../../../service/student/studentCourses.service';
import Loaders from '../../../components/loaders/Loaders';
import { Helmet } from 'react-helmet-async';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { BreadCrumRoutes } from '../../../helpers/helpers';
import { FaRupeeSign } from 'react-icons/fa';
import moment from 'moment';

export default function HoldPayments() {

    const [pageLoad, setPageLoad] = useState(false);
    const [fetchData, setFetchData] = useState([]);
    const [breadCrumData, setBreadCrumData] = useState<any>()
    const fetch = async()=>{
        setPageLoad(true)  
       let get:any = await studentCourseHoldPayments(); 
       if(get?.status === "success"){
        setPageLoad(false) 
        setFetchData(get?.message)
       }
       setPageLoad(false) 
    }
    useEffect(()=>{
        fetch()
        setBreadCrumData(BreadCrumRoutes("Pending Payments",'/student/payment/hold',"studentspaymenthold",true))
    },[])
  return (
    pageLoad ? 
    <Loaders type='spinner' />
    :
    <>
     <Helmet>
    <title>{`Pending Payments | ${window.location.hostname}`}</title>
    </Helmet>
    <BreadCrumb routes={breadCrumData}  />
    
<div className="relative overflow-x-auto rounded-lg  max-h-[300px] min-h-[100px]">
    <table className="w-full   text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs rounded-lg text-gray-200 uppercase  bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                 <th scope="col" className="py-2 px-2">S.No</th>
                 <th scope="col" className="py-2 px-2">Course</th>
                 <th scope="col" className="py-2 px-2">Amount</th>
                 <th scope="col" className="py-2 px-2">Date</th>
                 <th scope="col" className="py-2 px-2">Transaction Id</th>
                 <th scope="col" className="py-2 px-2">Payment Method</th>
                 <th scope="col" className="py-2 px-2">Status</th>
            </tr>
        </thead>
        <tbody>
         {
            fetchData.length > 0 ?
                fetchData.map((row:any,index:number) =>(
                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 text-center even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td className='px-2 py-2 text-center'>
                            {index+1}
                        </td>
                        <td className='px-2 py-2 text-center'>
                            <p className='flex flex-row  items-center'>
                               <img src={row?.course?.image} className='w-[30px] h-[30px] rounded-full' alt="" />
                              <p> {row?.course?.name}</p>
                            </p>
                        </td>
                        <td className='px-2 py-2 text-center'>
                        <p className='flex flex-row  items-center'>
                             <FaRupeeSign/> {row?.amount}
                            </p>
                        </td>
                        <td className='px-2 py-2 text-center'>
                        <p className='flex flex-row  items-center'>
                              {
                                moment(row?.createAt).format("DD MMM YYYY")
                              }   
                            </p>
                        </td>
                        <td className='px-2 py-2 text-center'>
                        <p className='flex flex-row  items-center'>
                            {row?.transaction_id}
                        </p>
                        </td>
                        <td className='px-2 py-2 text-center'>
                        <p className='flex flex-row  items-center'>
                            {row?.adminPaymentMaster?.name}
                        </p>
                        </td>
                        <td className='px-2 py-2 text-center'>
                        <button className='flex flex-row  items-center px-3 py-2 bg-purple-600 text-white  font-semibold rounded-xl'>
                            pending
                        </button>
                        </td>
                    </tr>
                    
                ))
            :null
         }
        </tbody>
    </table>
</div>
</>
  )
}
