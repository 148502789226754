import { adminCouponMasterType, adminCourseCouponTypes } from "../../types/AdminTypes";
import instance from "../instace";


export const getDiscountTypes = async()=>{
    try{
        const get:any = await instance.get(`/api/admin/coupon/discount_types`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const createAdminCouponMaster = async(datas:adminCouponMasterType)=>{
    try{
        const get:any = await instance.post('/api/admin/coupon/master/create',{
            name:datas.name,
            max_uses:datas.max_uses,
            start_date:datas.start_date,
            end_date:datas.end_date,
            fk_discount_type_id:datas.fk_discount_type_id,
            discount_value:datas.discount_value,
            status:datas.status,
        })
        return get.data;
     }catch(e){
        return false;
    }
}


export const getAdminCouponMaster = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/coupon/master/get/?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}


export const showAdminCouponMaster = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/coupon/master/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAdminCouponMaster = async(id:string,datas:adminCouponMasterType)=>{
    try{
        const get:any = await instance.put(`/api/admin/coupon/master/edit/${id}`,{
            name:datas.name,
            max_uses:datas.max_uses,
            start_date:datas.start_date,
            end_date:datas.end_date,
            fk_discount_type_id:datas.fk_discount_type_id,
            discount_value:datas.discount_value,
            status:datas.status,
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const updateStatusAdminCouponMaster = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/coupon/master/status/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteAdminCouponMaster = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/coupon/master/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}



export const createAdminCouponMasterCourses = async(datas:adminCourseCouponTypes)=>{
    try{
        const get:any = await instance.post('/api/admin/coupon/master-courses',{
            fk_couponMaster_id:datas.fk_couponMaster_id,
            fk_course_id:datas.fk_course_id,
        })
        return get.data;
     }catch(e){
        return false;
    }
}


export const getAdminCouponMasterCourses = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/coupon/master-courses/${id}`)
        return get.data;
    }catch(e){
        return false;
    }
}

export const deleteAdminCouponMasterCourses = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/coupon/master-courses/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}

