import React, { useEffect, useState } from 'react'
import { FaUsers } from 'react-icons/fa'
import Clock from '../../components/calendar/Clock'
import Calendar from '../../components/calendar/Calendar'
import SimpleTable from '../../components/tables/SimpleTable'
import MUITableGrid from '../../components/tables/MUITableGrid'
import ImageSliderSimple from '../../components/Slider/ImageSliderSimple'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { AdminDashboardCountStudentCourseService, AdminDashboardFeedService, AdminDashboardListStudentService } from '../../service/admin/admin.service'
import ImageSliderSimple2 from '../../components/Slider/ImageSliderSimple2'
import Models from '../../components/models/Models'
import FeedModels from '../../components/models/FeedModels'
import { Helmet } from 'react-helmet-async'
import parse from 'html-react-parser';
export default function AdminDashboard() {

  const [dataCount,setDataCount] =useState<any>();
  const [listStudentCount,setListStudentCount] =useState<any>()
  const [promotionsList, setPromotionsList] = useState<any>([]);
  const [feedList, setFeedList] = useState<any>([]);
  const [showFeed, setShowFeed] = useState<any>(false);
  const [feedModelData, setFeedModelData] = useState<any>();
  const fetchCount = async()=>{
    let count:any = await AdminDashboardCountStudentCourseService();
    if(count?.status === "success"){
      
      setDataCount(count?.message)
    }
    
  }
  const fetchFeeds = async()=>{
    let get:any= await AdminDashboardFeedService();
    if(get?.status === "success"){
      setPromotionsList(get?.message?.promotions)
      let newP:any = [];
      get?.message?.promotions?.length > 0 &&
        get?.message?.promotions.map((item:any)=>{
          if(item?.type === 0){
            newP.push({
            url:item?.url,
            type:"img"
            })
          }else{
            newP.push({
              url:item?.url,
              type:"vid"
              }) 
          }
        })
        newP.length > 0 ? setPromotionsList(newP) : setPromotionsList([])
          let newFeed:any = [];
          get?.message?.feeds?.length > 0 &&
            get?.message?.feeds.map((item:any)=>{
              if(item?.feedsType?.name === "Image"){
                newFeed.push({
                url:item?.url,
                name:item?.name,
                id:item?.id,
                type:"img"
                })
              }else if(item?.feedsType?.name === "Text"){
                newFeed.push({
                  url:item?.url,
                  name:item?.name,
                  id:item?.id,
                  type:"Text"
                  })
              }else{
                newFeed.push({
                  url:item?.url,
                name:item?.name,
                id:item?.id,
                type:"vid"
                  }) 
              }
            })
            newFeed.length > 0 ? setFeedList(newFeed) : setFeedList([])
    }
  }
  const fetchList = async()=>{
    let get:any= await AdminDashboardListStudentService();
    if(get?.status === "success"){
      setListStudentCount(get?.message)
    }
  }
  useEffect(()=>{
    fetchCount();
    fetchList();
    fetchFeeds();
  },[])
  const navigate = useNavigate()
  const handleFeedModel = (id:string)=>{
    setShowFeed(!showFeed)
    setFeedModelData({
      id:id
    })
  }
  return (
    <> 
     <Helmet>
    <title>{`Dashboard | ${window.location.hostname}`}</title>
</Helmet>
     <div className=" grid grid-cols-12 gap-2">
      <div className=' col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-8 '>
        <div className=' rounded-md bg-gray-800 flex justify-between items-center  h-[150px] shadow-lg w-full'>
         <div className='pl-3'>
         <p className='text-3xl font-sans font-bold  text-gray-200'>Welcome Back, <span className='capitalize '>{JSON.parse(Cookies.get('login')!).name}</span></p>
         <p className=' text-xl  font-semibold  text-gray-300'>Have a Good day at work</p>
         </div>
        </div>
        <div className="my-2">
          <div className="grid grid-cols-12 gap-2">
            <div className=" sm:col-span-12 col-span-12 md:col-span-6">
              <div className="card bg-white shadow-lg  rounded-lg p-3">
                <div className='flex flex-row gap-4 items-center justify-start'>
                  <FaUsers className='text-[3.4rem] bg-purple-400 p-1 rounded-lg text-white' />
                 <div className=' leading-7'>
                 <p className='text-[2rem] font-bold'>{dataCount?.totalCount}</p>
                 <p className='text-lg text-gray-600'>Total Students</p>
                 </div>
                </div>
                <hr className="my-4" />
                <div className='flex items-center justify-between'>
                    <div className='flex  items-center '>
                      <p className='text-lg text-gray-600'>Paid : </p> <p className='text-xl font-bold pl-1'> {dataCount?.paidCount}</p>
                    </div>
                    <div className='flex  items-center '>
                      <p className='text-lg text-gray-600'>Free : </p> <p className='text-xl font-bold pl-1'> {dataCount?.freeCount}</p>
                    </div>
                </div>
              </div>
              
            </div>
            <div className=" sm:col-span-12 col-span-12 md:col-span-6">
              <div className="card bg-white shadow-lg  rounded-lg p-3">
                <div className='flex flex-row gap-4 items-center justify-start'>
                  <FaUsers className='text-[3.4rem] bg-purple-400 p-1 rounded-lg text-white' />
                 <div className=' leading-7'>
                 <p className='text-[2rem] font-bold'>{dataCount?.totalCourse}</p>
                 <p className='text-lg text-gray-600'>Total Courses</p>
                 </div>
                </div>
                <hr className="my-4" />
                <div className='flex items-center justify-between'>
                    <div className='flex  items-center '>
                      <p className='text-lg text-gray-600'> Master : </p> <p className='text-xl font-bold pl-1'> {dataCount?.courseMaster}</p>
                    </div>
                    <div className='flex  items-center '>
                      <p className='text-lg text-gray-600'> Category : </p> <p className='text-xl font-bold pl-1'> {dataCount?.courseCategory}</p>
                    </div>
                </div>
              </div>
              
            </div>
            {
              feedList?.length > 0 && 
              <div className='my-2 col-span-12 bg-white p-2 rounded-lg '>
                <div className="flex justify-between">
        <p className='text-xl  font-semibold  text-gray-800'>Feeds</p>
        <a onClick={(()=>navigate("/admin/feeds"))} className='text-md  font-semibold  mr-3 hover:cursor-pointer  text-blue-800'>View All</a>
        </div>
        <div className="my-2 inline-block whitespace-nowrap overflow-y-auto">
                    {/* <ImageSliderSimple2> */}
                    {
                      feedList.map((item:any,index:number) =>(
                        <div key={index} onClick={()=>handleFeedModel(item?.id)} className='each-slide-effect px-3 inline-block '>
                          {
                            item?.type === "img" &&
                              <img  src={item?.url} className=" h-[100px] w-[100px] rounded-full hover:shadow-lg hover:cursor-pointer" alt="" />
                          }
                          {
                            item?.type === "Text" &&
                              <p className=" overflow-hidden text-sm h-[100px] w-[100px] rounded-full flex items-center bg-gray-400 hover:shadow-lg hover:cursor-pointer">{
                                parse(item?.name)
                              }</p>
                          }
                          
                        </div>
                      ))
                    }
                    {/* </ImageSliderSimple2> */}
                 </div>
              </div>
            }
            <div className="col-span-12 mt-3">
            <div className="flex justify-between">
        <p className='text-xl  font-semibold  text-gray-800'>New Students</p>
        <a onClick={(()=>navigate("/admin/students"))} className='text-md  font-semibold  mr-3 hover:cursor-pointer  text-blue-800'>View All</a>
        </div>
              <MUITableGrid name="AdminDashBoardRecentStudents" dataObject={listStudentCount?.newStu} />
            </div>
            <div className="col-span-12 mt-3">
            <div className="flex justify-between">
        <p className='text-xl  font-semibold  text-gray-800'>Exipring Students</p>
        <a onClick={(()=>navigate("/admin/installments"))} className='text-md  font-semibold  mr-3 hover:cursor-pointer  text-blue-800'>View All</a>
        </div>
              <MUITableGrid name="AdminDashBoardExpriyStudents" dataObject={listStudentCount?.expStu} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12  md:col-span-6 lg:col-span-4">
      <div className='bg-gray-200 dark:bg-secondary p-1  sm:hidden hidden md:block  rounded-md w-full  '>
      <div className="flex justify-center flex-col items-center">
      <Clock />
      </div>
      <Calendar date={new Date()} />
        </div>
        {
          promotionsList.length > 0 &&
          <div className="mt-2 p-1">
            <div className="flex justify-between">
            <p className='text-xl  font-semibold  text-gray-800'>Promotions</p>
            <a onClick={(()=>navigate("/admin/promotions"))} className='text-md  font-semibold  mr-3 hover:cursor-pointer  text-blue-800'>View All</a>
            </div>
            <ImageSliderSimple images={promotionsList} />
            </div>
        }
      </div>
     </div>
     <Models width="w-[30vw]"  name={`Feed`} isOpen={showFeed} isClose={setShowFeed}  >
          <FeedModels data={feedModelData}  open={setShowFeed}  />
        </Models>
    </>
  )
}
