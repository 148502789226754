import React from 'react'
import { PieChart } from '@mui/x-charts/PieChart';

export default function PieChartMUI(props:{data:any,width?:number,height?:number}) {
  return (
    <PieChart
    slotProps={{
       legend: {
           direction: 'column',
           position:{vertical: 'top', horizontal: 'right'},
         labelStyle: {
           fontSize: 13,
           fill: 'gray',
         },
       },
     }}
series={[
{
  
 data:props.data
},
]}

width={props.width  ? props.width : 250}
height={props.height ? props.height : 150}
/>
  )
}
