import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

export default function HlsPlayer(props:{playlistUrl:any,data:any}) {
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement:any = videoRef.current;

        // Check if the browser can play HLS natively
        if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
            videoElement.src = props.playlistUrl;
        } else if (Hls.isSupported()) {
            const hls = new Hls({
                xhrSetup: function (xhr, url) {
                    xhr.setRequestHeader("account", props.data ? props.data : "null");
                    xhr.setRequestHeader("X-Custom-Header", `${Date.now()}`);
                },
                manifestLoadingTimeOut: 10000,
                manifestLoadingMaxRetry: 3,
                startLevel: -1,
                levelLoadingMaxRetry: 3,
                fragLoadingMaxRetry: 3,
            });

            hls.loadSource(props.playlistUrl);
            hls.attachMedia(videoElement);

            hls.on(Hls.Events.ERROR, (event, data) => {
                if (data.fatal) {
                    console.error('HLS.js fatal error:', data);
                    hls.destroy();
                }
            });

            // Cleanup on component unmount
            return () => {
                if (hls) {
                    hls.destroy();
                }
            };
        } else {
            console.error('HLS.js is not supported in this browser');
        }
    }, [props.playlistUrl]);

    return (
        <div>
            <video ref={videoRef} controls width="100%" autoPlay />
        </div>
    );
}
