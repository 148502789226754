import React, { useEffect, useState } from 'react'
import { ActiveAdminCourseCategoryTab, orderAdminCourseCategoryTab } from '../../../../service/admin/adminCourses.service';
import Loaders from '../../../../components/loaders/Loaders';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactBeautiful from '../../../../components/dragAndDrop/ReactBeautiful';
import { toast } from 'react-toastify';

export default function CourseCategoryTabOrders() {
    const [fetchData, setFetchData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newOrder, setNewOrder] = useState([])
    const [items, setItems] = useState( [
      { id: '1', content: 'Item 1' },
      { id: '2', content: 'Item 2' },
      { id: '3', content: 'Item 3' },
    ]);
    const fetchs =  async()=>{ 
        let get:any = await ActiveAdminCourseCategoryTab();
        if(get?.status === "success"){
            setFetchData(get?.message)
            setLoading(false)
        }
    }
    const initialItems = [
        { id: '1', content: 'Item 1' },
        { id: '2', content: 'Item 2' },
        { id: '3', content: 'Item 3' },
      ];
     
    useEffect(()=>{
        setLoading(true)
        fetchs()
    }, [])
    const handleSave = async()=>{
      console.log(newOrder)
      if(newOrder.length === 0){
        return toast.info("nothing to change")
      }
      let newData:any =[];
      newOrder.map((item:any,index:number)=>{
        newData.push({id:item.id, order:index+1})
      })
      let update:any = await orderAdminCourseCategoryTab(newData);
      if(update?.status === 'success'){
        return toast.success(update?.message)
      }
      // orderAdminCourseCategoryTab
    }
  return (
    loading ? 
    <Loaders type="spinner" />
    :
    <>
    <div>
      <ReactBeautiful data={fetchData} newOrder={setNewOrder} />
      <div className="w-full flex justify-end gap-3">
        <button onClick={handleSave} className=' px-4 py-2 bg-purple-800 text-white rounded-lg'>Save</button>
      </div>
    </div>
    </>
  )
}
