import React, { useEffect, useState } from 'react'
import { getAdminPendingPayments } from '../../../service/admin/adminpayments.service';
import Loaders from '../../../components/loaders/Loaders';
import { Helmet } from 'react-helmet-async';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid';

export default function AdminPendingPayments() {
    const [pageLoading, setPageLoading] = useState(false);
    const [fetchData, setFetchData] = useState([])

    const fetch = async()=>{
        const get:any = await getAdminPendingPayments();
        if(get?.status === "status"){
            setFetchData(get.message);
        }
    }
    useEffect(()=>{
        fetch()
    },[]);
  return (
    pageLoading ? 
     <Loaders type="spinner" />
     :
     <>
      <Helmet>
    <title>{` Pending Payments   | ${window.location.hostname}`}</title>
    </Helmet>
    <BreadcrumbBack title={`Pending Payments`}></BreadcrumbBack>
    <div className="my-3">
        <PaginationDataGrid name="adminPendingPayments" />
    </div>
     </>
  )
}
