import { Spinner } from '@material-tailwind/react';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { studentVideoWatchDefaultInterval } from '../../service/student/student.service';
import Cookies from 'js-cookie';
import './CustomVimeoPlayer.css';
import { FaAngleRight, FaExpand, FaPlay, FaStop, FaVolumeMute } from 'react-icons/fa';
import { FaGear, FaMinimize, FaVolumeHigh } from 'react-icons/fa6';
import { LinearProgress, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { RiForward15Fill,RiReplay15Fill } from "react-icons/ri";
import Hls from "hls.js";
import PlauginError from '../../pages/error/PlauginError';
export default function YoutubePlayer2(props: { url: string, width?: number, watchTime: any, lastWatch?: any, type:string,data?:any }) {
  const [isReady, setIsReady] = useState(false);
 const [url,setUrl] = useState<any>("https://devstreaming-cdn.apple.com/videos/streaming/examples/adv_dv_atmos/main.m3u8")
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isBuffering, setIsBuffering] = useState(true);
  const [speed, setSpeed] = useState<number>(1);
  const [phone, setPhone] = useState<any>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [controlsVisible, setControlsVisible] = useState(true);
  const [duration, setDuration] = useState(0); 
  const playerRef = useRef<any>();
  const playerWrapperRef = useRef<HTMLDivElement | null>(null);
  const [volume, setVolume] = useState<any>(0.8);
  const [hasError, setHasError] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [selectedSpeed, setSelectedSpeed] = useState(1);
  const [lastWatchTime, setLastWatchTime] = useState<number>(0);
  const [loading, setLoading] = useState(false); 
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [forwardStatus, setForwardStatus] = useState(false);
  const [backwardStatus, setBackwardStatus] = useState(false);
  const [intruder, setIntruder] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick1 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  // Fetch and set the video default interval from the backend
  useEffect(() => {
    const fetchDefaultInterval = async () => {
      let get: any = await studentVideoWatchDefaultInterval();
      if (get?.status === "success") {
        Cookies.set("VideoDefaultInterval", get?.message);
      }
    };
    
    if (!Cookies.get("VideoDefaultInterval")) {
      Cookies.set("VideoDefaultInterval",'4');
      // fetchDefaultInterval();
    }
  }, []);

  // Handle fullscreen functionality
  const handleFullscreen = () => {
    if (playerWrapperRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        playerWrapperRef.current.requestFullscreen();
      }
    }
  };

  // Set initial phone from cookies and handle fullscreen state
  useEffect(() => {
    let watermark:string  = "Chahal Academy"
    let get = JSON.parse(Cookies.get("login")!)
    if(get){
      watermark = get.phone
    }
    setPhone(watermark);
  
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  // Set initial video time on load
  const onReady = () => {
    setIsBuffering(false);
    setIsReady(true);
    const startAt = props.lastWatch ? Math.ceil(parseInt(props.lastWatch)) : 0;
    playerRef.current.seekTo(startAt, 'seconds');
  };

  // Toggle play/pause functionality
  const handlePlayStop = () => {
    setIsPlaying(prev => !prev);
  };

  // Update progress bar and played time in real-time
  const handleProgress = (progress: any) => {
    setLoading(false)
    setPlayed(progress.played); // Update the fraction of video played
    let delay:number = Cookies.get("VideoDefaultInterval") ? parseInt(Cookies.get("VideoDefaultInterval")!) : 8
    if (progress.playedSeconds - lastWatchTime >= delay) {

      setLastWatchTime(progress.playedSeconds); // Update the last triggered time
      props.watchTime(progress.playedSeconds); // Call the watchTime function
    }
    // props.watchTime(progress.playedSeconds); // Trigger any external watchTime logic
  };

  // Handle duration update on video load
  const handleDuration = (duration: any) => {
    setDuration(duration);
  };

  // Seek video based on progress bar input
  const handleSeekChange = (event: any) => {
    const newPlayed = parseFloat(event.target.value);
    setPlayed(newPlayed);
    playerRef.current.seekTo(newPlayed);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);

    // Clean up event listeners
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('msfullscreenchange', handleFullscreenChange);
    };
  }, []);
  // Format time in MM:SS format for display
  const formatTime = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  const toggleMute = () =>{
    setIsMuted(!isMuted)
    setVolume(!isMuted ? 0 : 0.5)
  };
  const handleError = (e:any) => {
    if(e && e !== ""){
      console.log("player error" , e)
      // toast.error(e)
      // setHasError(true);
    } // Set error state to true when video fails to load
  };
  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    setIsMuted(newVolume === 0); // Automatically mute if volume is set to 0
  };

  

  const customConfig = {
    autoStartLoad: true,
    startPosition: -1,
    maxBufferLength: 30,
  maxMaxBufferLength: 50,
  lowLatencyMode: false,
  fpsDroppedMonitoringPeriod: 10000,
  fpsDroppedMonitoringThreshold: 0.1,
  appendErrorMaxRetry: 5,
    // xhrSetup: (xhr:any) => {
    //   xhr.setRequestHeader("account", props.data ? props.data : "null");
    //   xhr.setRequestHeader("X-Custom-Header", Date.now());
    // },
  };

  useEffect(() => {
    if (isReady && playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
    }
  }, [isReady]);
  useEffect(() => {
    const handleFullscreenChange = () => {
      // @ts-ignore
      if (document.fullscreenElement || document.webkitFullscreenElement) {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);

    // Clean up event listeners
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('msfullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleForward = ()=>{
    if (playerRef.current) {
      setLoading(true);
      setForwardStatus(true);
      const currentTime = playerRef.current.getCurrentTime();
      const duration = playerRef.current.getDuration();
      const newTime = Math.min(currentTime + 15, duration); // Ensure it doesn't exceed the duration
      playerRef.current.seekTo(newTime, "seconds");
     
    }
    // playerRef.current.seekTo(playtime);
  }
  const handleBackward = ()=>{
    if (playerRef.current) {
      setLoading(true);
      setBackwardStatus(true)
      const currentTime = playerRef.current.getCurrentTime();
      const duration = playerRef.current.getDuration();
      const newTime = Math.min(currentTime - 15, duration); // Ensure it doesn't exceed the duration
      playerRef.current.seekTo(newTime, "seconds");
     
    }
  }
  useEffect(()=>{
  
    if(forwardStatus === true){
      setTimeout(() => {
        setForwardStatus(false); // Hide controls after 2 seconds of inactivity
      }, 900);
    }
    if(backwardStatus === true){
      setTimeout(() => {
        setBackwardStatus(false); // Hide controls after 2 seconds of inactivity
      }, 900);
    }
  },[forwardStatus,backwardStatus])
  useEffect(() => {
    let timer: NodeJS.Timeout;

    // Function to show controls and reset the hide timer
    const handleUserInteraction = () => {
      setControlsVisible(true);
      clearTimeout(timer); // Clear previous timeout
      timer = setTimeout(() => {
        setControlsVisible(false); // Hide controls after 2 seconds of inactivity
      }, 2000);
    };

    // Event listeners for mouse, touch, and keyboard interactions
    const handleKeyPress = (event: KeyboardEvent) => {
      // List of keys that should trigger showing the controls (e.g., space, arrows)
      const interactiveKeys = ['Space', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter'];
      if (interactiveKeys.includes(event.key)) {
        handleUserInteraction(); // Show controls if these keys are pressed
      }
    };

    // Event listeners for mouse and touch interactions
    document.addEventListener('mousemove', handleUserInteraction);
    document.addEventListener('touchstart', handleUserInteraction);
    document.addEventListener('touchend', handleUserInteraction);
    document.addEventListener('keydown', handleKeyPress); // Listen for keyboard events

    // Initial hide controls timeout
    timer = setTimeout(() => {
      setControlsVisible(false);
    }, 3000);

    // Cleanup event listeners on component unmount
    return () => {
      clearTimeout(timer);
      document.removeEventListener('mousemove', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
      document.removeEventListener('touchend', handleUserInteraction);
      document.removeEventListener('keydown', handleKeyPress); // Remove keyboard listener
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handleBackward(); // Trigger backward functionality
      } else if (event.key === 'ArrowRight') {
        handleForward(); // Trigger forward functionality
      }else if(event.key === "Backspace") {
        handlePlayStop()
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  function checkForIDM() {
    const videos = document.getElementsByTagName('video');
    for (const video of videos) {
        if (video.hasAttribute('__idm_id__')) {
            return true; 
        }
    }
    return false; // IDM not detected (yet)
}
// function swapVideoSrc() {
//   const video:any = document.querySelector('video');
//   video.src = props.url;
// }

// setTimeout(() => {
//   if (!checkForIDM()) {
//       swapVideoSrc();
//   }
// }, 500);
useEffect(()=>{
  let idmCheckInterval:any;

function startIDMCheck() {
    idmCheckInterval = setInterval(() => {
        if (checkForIDM()) {
          setIntruder(true);
          setUrl(null)
            clearInterval(idmCheckInterval);
            // window.location.href = "/player-error";
        }else{
          setIntruder(false);
          console.log("tring.....")
          setUrl(props.url)
        }
    }, 1000); // Check every second
}

startIDMCheck()
},[])
  
  return (
    intruder ? 
    <PlauginError />
    :
    <>
<div className=' '>
      {
        
        !isReady &&
          <div className='w-[100%] min-h-[450px] grid place-content-center'>
          <Spinner />
          </div>
      }
      
      
      <div className={`video-container relative ${isFullscreen ? "fullscreen" : ""}`} ref={playerWrapperRef} style={{ display: isReady ? 'block' : 'none' }}>
     
      <ReactPlayer
        ref={playerRef}
        onContextMenu={(e:any) => e.preventDefault()}
        url={url}
        volume={volume}
        config={{ 
          file: { 
            forceHLS:props.type === "Vimeo" ? true : false,

            attributes: {
              crossOrigin: "anonymous",
              preload: "auto", 
              onContextMenu: (e:any) => e.preventDefault()
            } ,
            hlsOptions: customConfig,
          } ,
          vimeo: {
            playerOptions: {
                quality: 'auto', // Use automatic quality adjustment
            },
          }
          
        }}
        playbackRate={speed} 
        onProgress={handleProgress}
        onDuration={handleDuration}
        onBuffer={(()=>setLoading(true))}
        onBufferEnd={(()=>setLoading(false))}
        playing={isPlaying}
        onError={handleError}
        onReady={onReady}
        controls={false}
        className={`${isFullscreen ? "react-player-fullscreen" : "react-player"}`}
      />
       
        <Tooltip title={isPlaying ? "stop" : "play"}>
            <button className={`${!isPlaying && `bg-overlay`} ${isFullscreen ? ' h-[100vh] w-[100%]' : `  h-[450px] w-[100%]`} play-button  hover:cursor-pointer absolute   top-[0vh]  `} onClick={handlePlayStop}>
              {!isPlaying &&  <FaPlay className='text-[1rem] text-white hover:cursor-pointer absolute top-[45%] left-[45%] bg-purple-500 p-3 rounded-full h-[50px] w-[50px]' />}
            </button>
          </Tooltip>
          {
            backwardStatus && 
            <RiReplay15Fill className='text-4xl backwardStatusIcon text-gray-400 hover:cursor-pointer absolute top-[45%] left-[20%]' />
          }
          {
            forwardStatus && 
            <RiForward15Fill className='text-4xl forwardStatusIcon text-gray-400 hover:cursor-pointer absolute top-[45%] left-[80%]' />
          }
          {
        loading &&
        <div className=' absolute w-full top-0 z-[9]'>
           <p>
           <LinearProgress 
           sx={{position:"relative", backgroundColor: '#d3d3d3', '& .MuiLinearProgress-bar': { backgroundColor: '#a855f7' } }} 
         />
           </p>
        </div>
      }
        {/* Custom Controls */}
        <div className={`controls w-full  ${controlsVisible ? 'block' :'hidden'}`}>
          <div className="px-3 flex gap-3 items-center text-white">
          {formatTime(played * duration)}
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={played}
            onChange={handleSeekChange}
            className='hover:cursor-pointer w-full h-[5px]'
           
          /> 
          {formatTime(duration)}
          </div>
          <div className="flex items-center justify-between gap-3 mt-2">
          <div className='flex gap-3 items-center justify-between w-full mx-3'>
         
          <div  className='flex items-center relative justify-between mb-2 gap-4'>
         <div className=' w-full absolute'>
         <div className=' flex items-center'>
         <Tooltip title="volume">
          <button className="mx-2 mt-1" onClick={toggleMute}>
            {isMuted ? <FaVolumeMute className='text-lg text-white' /> : <FaVolumeHigh className='text-lg text-white' />}
          </button>
        </Tooltip>
          {/* Custom Progress Bar */}
          <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          style={{ width: '50px', height:'5px',marginTop:"2px" }}
        />
         </div>
         </div>
        
         </div>
         <div className='flex items-center gap-3 '>
          <Tooltip title={isPlaying ? "stop" : "play"}>
            <button className="play-button ml-2 hover:cursor-pointer " onClick={handleBackward}>
              <RiReplay15Fill className='text-2xl text-white hover:cursor-pointer' />
            </button>
          </Tooltip>
          <Tooltip title={isPlaying ? "stop" : "play"}>
            <button className="play-button ml-2 hover:cursor-pointer " onClick={handlePlayStop}>
              {isPlaying ? <FaStop className='text-lg text-white hover:cursor-pointer ' /> : <FaPlay className='text-lg text-white hover:cursor-pointer' />}
            </button>
          </Tooltip>
          <Tooltip title={isPlaying ? "stop" : "play"}>
            <button className="play-button ml-2 hover:cursor-pointer " onClick={handleForward}>
            <RiForward15Fill className='text-2xl text-white hover:cursor-pointer' />
            </button>
          </Tooltip>
          </div>
         <div className='flex gap-4 items-center '>
          <Tooltip title="Play speed">
          <button 
             className='text-white relative'
             id="demo-positioned-button"
             aria-controls={open ? 'demo-positioned-menu' : undefined}
             aria-haspopup="true"
             aria-expanded={open ? 'true' : undefined}
             onClick={handleClick}
          >
          <FaGear className='text-xl' />
          </button>
        </Tooltip>
         <Tooltip title="full screen">
          <button className="" onClick={handleFullscreen}>
            {isFullscreen ? <FaMinimize className='text-lg text-white' /> : <FaExpand className='text-xl text-white' />}
          </button>
        </Tooltip>
         </div>
          </div>
          </div>
        </div>

        <div className="watermark">
          {phone}
        </div>
      </div>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem >
          <button className='w-[10rem]'
            id="demo-positioned-button1"
            aria-controls={open1 ? 'demo-positioned-menu1' : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? 'true' : undefined}
            onClick={handleClick1}
          >
            <div className="flex items-center justify-between">
              <div className="">
                Speed
              </div>
              <div className="flex gap-2 items-center">
                {speed}x
                <FaAngleRight className=''/>
              </div>
            </div>
          </button>
        </MenuItem>
        <MenuItem>
        <button className='w-[10rem]'>
            <div className="flex items-center justify-between">
              <div className="">
                Quality
              </div>
              <div className="flex gap-2 items-center">
                auto
                {/* <FaAngleRight className=''/> */}
              </div>
            </div>
          </button>
          </MenuItem>
      </Menu>
      <Menu
        id="demo-positioned-menu1"
        aria-labelledby="demo-positioned-button1"
        anchorEl={anchorEl1}
        open={open1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={(()=>setSpeed(0.5))} >
        <button >
          0.5x
        </button>
        </MenuItem>
        <MenuItem onClick={(()=>setSpeed(0.75))}>
        <button >
          0.75x
        </button>
          </MenuItem>
          <MenuItem onClick={(()=>setSpeed(1))}>
        <button >
          1x
        </button>
          </MenuItem>
          <MenuItem onClick={(()=>setSpeed(1.5))}>
        <button >
          1.5x
        </button>
          </MenuItem>
          <MenuItem onClick={(()=>setSpeed(2))}>
        <button >
          2x
        </button>
          </MenuItem>
      </Menu>
    </div>
    </>
  );
}
