import React, { useEffect, useState } from 'react'
import { freeStudentSubscribe, getCourseTestSeriesList, getCourseVideosList, getStudentCourseDetails } from '../../../service/student/studentCourses.service';
import { useLocation, useNavigate } from 'react-router-dom';
import Loaders from '../../../components/loaders/Loaders';
import { FaAngleDown, FaClipboard, FaDotCircle, FaRupeeSign, FaStar, FaStopwatch, FaVideo } from 'react-icons/fa';
import { BreadCrumRoutes, truncateString } from '../../../helpers/helpers';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import moment from 'moment';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { Helmet } from 'react-helmet-async';
import Models from '../../../components/models/Models';
import CoursePayment from './CoursePayment';
export default function CourseDetails() {
    const [loading, setLoading] = useState(false)
    const [pageLoad,setPageLoad] = useState(false);
    const [courseDetail, setCourseDetail] = useState<any>()
    const [shortDescription, setShortDescription] = useState<any>(`<h1>single</h1>`)
    const [courseVideos, setCourseVideos] = useState([])
    const [courseTestSeries, setCourseTestSeries] = useState([])
    const [breadCrumRoutes, setBreadCrumRoutes] = useState<any>();
    const [open,setOpen] = useState(false);
    const [courseInclude, setCourseInclude] = useState({
        test:0,
        video:0
    })
    const location = useLocation();
    const navigation = useNavigate()
    const fetchCourseDetails = async(slug: string) => {
        setPageLoad(true);
        const get = await getStudentCourseDetails(slug);
        if(get?.status === "success"){
            setCourseDetail(get?.message)
            setShortDescription(get.message.description)
            let testCount = 0
            let testSubModules = 0
                get?.message?.course_video_relation.map((e:any)=> {
                    testSubModules+= e?.videoSubModule?._count?.videoRelation
                })
                get?.message?.courseRelationTestSeries.map((e:any)=> {
                    testCount+= e?.testSubModules?._count?.testSeries
                })

                setCourseInclude({
                    test:testCount,
                    video:testSubModules
                })
            setPageLoad(false);
        }
        setPageLoad(false)
    }
    useEffect(() => {
        fetchCourseDetails(location.pathname.split("/")[location.pathname.split("/").length - 1])
        setBreadCrumRoutes(BreadCrumRoutes("Course",location.pathname,"studentcoursesListCourse",false))
    },[]);
    
    const handlePayment = async()=>{
        setLoading(true)
            if(courseDetail?.amount === "0"){
                const pay:any = await freeStudentSubscribe(courseDetail?.id);
        if(pay?.status === "success"){
            setLoading(false)
            fetchCourseDetails(location.pathname.split("/")[location.pathname.split("/").length - 1])
           return toast.success(pay.message)
        }
            }else{
                // setLoading(false)
                navigation(`/student/courses/checkout/${courseDetail?.id}`)
                // setOpen(true)
            }
        setLoading(false)
    }
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      };

      const handleCourseContent = async(type:string)=>{
        if(type === "test"){
            let get:any = await getCourseTestSeriesList(courseDetail?.id)
            if(get?.status === "success"){
                setCourseTestSeries(get?.message)
                let count = 0;
                
            }
        }
        if(type === "video"){
            let get:any = await getCourseVideosList(courseDetail?.id)
            if(get?.status === "success"){
                setCourseVideos(get?.message)
            }
        }
      }

  return (
    <>
    {
        pageLoad ? <Loaders type='spinner' />  : 
        <>
         <Helmet>
            <title>{`Course Detail | ${window.location.hostname}`}</title>
        </Helmet>
         <BreadCrumb routes={breadCrumRoutes}  />
        <div className=' hidden sm:hidden md:hidden lg:block'>
            <div className="grid grid-cols-12 gap-2">
                <div className=" col-span-8">
                    <div className='flex justify-between gap-3  p-3 rounded-2xl min-h-[100px]'>
                    <h1 data-tooltip={courseDetail?.name} data-tooltip-location="bottom" className='font-bold text-2xl font-serif'>{courseDetail?.name}</h1>

                    
                    </div>
                    <div className='my-3 border border-gray-300  rounded-lg p-3'>
                        <h5 className='text-lg font-bold text-purple-900 '>Course Description</h5>
                        <div className='my-3 text-justify pr-3'>
                        {
                            parse(shortDescription)
                           }
                        </div>
                    </div>
                    <div className='my-3 border border-gray-300  rounded-lg p-3'>
                        <h5 className='text-lg font-bold text-purple-900 '>Course Content</h5>
                        <div className='my-3 text-justify pr-3'>
                        <Accordion  expanded={expanded === `panel1`} onChange={handleChange(`panel1`)}
                    sx={{
                        marginY:1,
                         boxShadow:0,
                        border:0,
                      
                        background:'#c4b5fd',
                        '&.Mui-disabled': {
                            backgroundColor: '#c4b5fd',
                            opacity:1
                            },
                        
                    }}
                    >
        <AccordionSummary
         onClick={(()=>handleCourseContent("video"))}
          expandIcon={<FaAngleDown />} 
          aria-controls={`panel1-content`}
          id={`panel1-header`}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            '&.Mui-disabled': {
                opacity:1
                },
            
        }}
        >
          <div className=' hover:cursor-pointer  w-[100%] ' >
                            <div className="!flex  lg:flex-row  flex-col md:flex-col sm:flex-col justify-between " >
                            <div className='flex items-center gap-2 '>
                            
                              <p className='text-lg font-bold'>Course Videos</p>
                            </div>
                          
                            </div>
                            </div>
        </AccordionSummary>
        <AccordionDetails>
        {
            courseVideos?.length > 0 && 
            <div className="relative overflow-x-auto rounded-lg  max-h-[300px] ">
            <table className='w-full  border-collapse  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
                                            <thead className="text-xs rounded-lg text-gray-200 uppercase  bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
                                                <th className="py-2 px-2 text-center">#</th>
                                                <th className="py-2 px-2 text-center">Name</th>
                                                <th className="py-2 px-2 text-center">Duration</th>
                                            </thead>
                                            <tbody className='bg-gray-100'>
                                                {
                                                    courseVideos.map((item:any,index:number)=>(
                                                        <tr className=''>
                                                             <td className='px-2 py-2 text-center border-b  border-t-0 border-l-0  border-gray-400 border-r-0'>{index+1}</td>
                                                             <td className='px-2 py-2 text-left  border-b  border-t-0 border-l-0  border-gray-400 border-r-0'>{item?.name}</td>
                                                             <td className='px-2 py-2 text-center  border-b  border-t-0 border-l-0  border-gray-400 border-r-0'>{moment.utc(moment.duration(item?.length, 'minutes').asMilliseconds()).format('H:mm')}</td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                                </table>
            </div>
        }

        </AccordionDetails>
      </Accordion>
      <Accordion  expanded={expanded === `panel2`} onChange={handleChange(`panel2`)}
                    sx={{
                        marginY:1,
                         boxShadow:0,
                        border:0,
                      
                        background:'#c4b5fd',
                        '&.Mui-disabled': {
                            backgroundColor: '#c4b5fd',
                            opacity:1
                            },
                        
                    }}
                    >
        <AccordionSummary
         onClick={(()=>handleCourseContent("test"))}
          expandIcon={<FaAngleDown />} 
          aria-controls={`panel2-content`}
          id={`panel2-header`}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            '&.Mui-disabled': {
                opacity:1
                },
            
        }}
        >
          <div className=' hover:cursor-pointer  w-[100%] ' >
                            <div className="!flex  lg:flex-row  flex-col md:flex-col sm:flex-col justify-between " >
                            <div className='flex items-center gap-2 '>
                            
                              <p className='text-lg font-bold'>Course Test Series</p>
                            </div>
                          
                            </div>
                            </div>
        </AccordionSummary>
        <AccordionDetails>
        {
            courseTestSeries?.length > 0 && 
            <div className="relative overflow-x-auto rounded-lg  max-h-[300px] ">
            <table className='w-full border-collapse   text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
                                            <thead className="text-xs rounded-lg text-gray-200 uppercase  bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
                                                <th className="py-2 px-2 text-center">#</th>
                                                <th className="py-2 px-2 text-center">Name</th>
                                                <th className="py-2 px-2 text-center">Questions</th>
                                                <th className="py-2 px-2 text-center">Total Marks</th>
                                                <th className="py-2 px-2 text-center">Negative Marks</th>
                                                <th className="py-2 px-2 text-center">Duration</th>
                                            </thead>
                                            <tbody className='bg-gray-100'>
                                                {
                                                    courseTestSeries.map((item:any,index:number)=>(
                                                        item?.testSeries.length > 0 &&
                                                        <tr>
                                                             <td className='px-2 py-2 text-center border-b  border-t-0 border-l-0  border-gray-400 border-r-0 '>{index+1}</td>
                                                             <td className='px-2 py-2 text-center border-b  border-t-0 border-l-0  border-gray-400 border-r-0 '>{item?.name}</td>
                                                             <td className='px-2 py-2 text-center  border-b  border-t-0 border-l-0  border-gray-400 border-r-0'>{item?.testSeries.length}</td>
                                                             <td className='px-2 py-2 text-center border-b  border-t-0 border-l-0  border-gray-400 border-r-0 '>{item?.marks}</td>
                                                             <td className='px-2 py-2 text-center  border-b  border-t-0 border-l-0  border-gray-400 border-r-0'>{item?.negative_marks}</td>
                                                             <td className='px-2 py-2 text-center border-b  border-t-0 border-l-0  border-gray-400 border-r-0'>{moment.utc(moment.duration(item?.duration, 'minutes').asMilliseconds()).format('H:mm')}</td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                                </table>
            </div>
        }

        </AccordionDetails>
      </Accordion>
                        </div>
                    </div>
                </div>
                <div className="col-span-4">
                    <div className='border relative border-gray-300 rounded-lg p-2'>
                    {
                                        courseDetail?.popular && <p className=' absolute z- bg-purple-700 p-2 flex gap-1 items-center rounded-full top-3 left-3 text-white'> <FaStar className='' /></p>
                                    }
                        <img src={courseDetail?.image} className=' rounded-md w-full h-auto' alt="" />
                        <div className="my-3 flex justify-between">
                            <p className='flex items-center text-xl'>{courseDetail?.amount === "0" ? "FREE" : <><FaRupeeSign />{courseDetail?.amount}</>}</p>
                            <p className='flex items-center text-xl'><FaStopwatch /> {courseDetail?.duration} Days</p>
                        </div>
                        {
                            courseDetail?.studentSubscription.length === 0 ? 
                            <button onClick={handlePayment} disabled={loading} className='w-full p-2 rounded-lg my-4 text-white bg-gradient'>{
                                loading ? "Loading..." : " Buy Now"
                            } </button>
                            :<button disabled={true} className='w-full p-2 rounded-lg text-white bg-gray-400'> Already Buy </button>
                        }
                        
                    </div>
                    <div className='border border-gray-300 rounded-lg p-2 my-2'>
                       <p className='text-xl font-semibold'>Includes</p>
                       <ul className='my-3'>
                        <li className='flex gap-2 items-center my-2'>
                            <FaVideo className='text-purple-500' /> Videos : <span className='font-semibold'>{courseInclude.video}</span>
                        </li>
                        <li className='flex gap-2 items-center my-2'>
                            <FaClipboard className='text-purple-500' /> Test Series : <span className='font-semibold'>{courseInclude.test}</span>
                        </li>
                       </ul>
                        
                    </div>
                </div>
            </div>
        </div>
        <div className=' block sm:block md:block lg:hidden'>
            
               
                  <div className='border relative border-gray-300 rounded-lg p-2'>
                    {
                                        courseDetail?.popular && <p className=' absolute z- bg-purple-700 p-2 flex gap-1 items-center rounded-full top-3 left-3 text-white'> <FaStar className='' /></p>
                                    }
                        <img src={courseDetail?.image} className=' rounded-md w-full h-atuo' alt="" />
                        <div className="my-3 flex justify-between">
                            <p className='flex items-center text-xl'>{courseDetail?.amount === "0" ? "FREE" : <><FaRupeeSign />{courseDetail?.amount}</>}</p>
                            <p className='flex items-center text-xl'><FaStopwatch /> {courseDetail?.duration} Days</p>
                        </div>
                        {
                            courseDetail?.studentSubscription.length === 0 ? 
                            <button onClick={handlePayment} disabled={loading} className='w-full p-2 rounded-lg my-4 text-white bg-gradient'>{
                                loading ? "Loading..." : " Buy Now"
                            } </button>
                            :<button disabled={true} className='w-full p-2 rounded-lg text-white bg-gray-400'> Already Buy </button>
                        }
                        
                   
            </div>
            <div className='flex justify-between gap-3  p-3 rounded-2xl min-h-[100px]'>
                    <h1 data-tooltip={courseDetail?.name} data-tooltip-location="bottom" className='font-bold text-2xl font-serif'>{courseDetail?.name}</h1>

                    
                    </div>
                    <div className='my-3 border border-gray-300  rounded-lg p-3'>
                        <h5 className='text-lg font-bold text-purple-900 '>Course Description</h5>
                        <div className='my-3 text-justify pr-3'>
                        {
                            parse(shortDescription)
                           }
                        </div>
                    </div>
                    <div className='border border-gray-300 rounded-lg p-2 my-2'>
                       <p className='text-xl font-semibold'>Includes</p>
                       <ul className='my-3'>
                        <li className='flex gap-2 items-center my-2'>
                            <FaVideo className='text-purple-500' /> Videos : <span className='font-semibold'>{courseInclude.video}</span>
                        </li>
                        <li className='flex gap-2 items-center my-2'>
                            <FaClipboard className='text-purple-500' /> Test Series : <span className='font-semibold'>{courseInclude.test}</span>
                        </li>
                       </ul>
                        
                    </div>
                    <div className='my-3 border border-gray-300  rounded-lg p-3'>
                        <h5 className='text-lg font-bold text-purple-900 '>Course Content</h5>
                        <div className='my-3 text-justify pr-3'>
                        <Accordion  expanded={expanded === `panel1`} onChange={handleChange(`panel1`)}
                    sx={{
                        marginY:1,
                         boxShadow:0,
                        border:0,
                      
                        background:'#c4b5fd',
                        '&.Mui-disabled': {
                            backgroundColor: '#c4b5fd',
                            opacity:1
                            },
                        
                    }}
                    >
        <AccordionSummary
         onClick={(()=>handleCourseContent("video"))}
          expandIcon={<FaAngleDown />} 
          aria-controls={`panel1-content`}
          id={`panel1-header`}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            '&.Mui-disabled': {
                opacity:1
                },
            
        }}
        >
          <div className=' hover:cursor-pointer  w-[100%] ' >
                            <div className="!flex  lg:flex-row  flex-col md:flex-col sm:flex-col justify-between " >
                            <div className='flex items-center gap-2 '>
                            
                              <p className='text-lg font-bold'>Course Videos</p>
                            </div>
                          
                            </div>
                            </div>
        </AccordionSummary>
        <AccordionDetails>
        {
            courseVideos?.length > 0 && 
            <div className="relative overflow-x-auto rounded-lg  max-h-[300px] ">
            <table className='w-full  border-collapse  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
                                            <thead className="text-xs rounded-lg text-gray-200 uppercase  bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
                                                <th className="py-2 px-2 text-center">#</th>
                                                <th className="py-2 px-2 text-center">Name</th>
                                                <th className="py-2 px-2 text-center">Duration</th>
                                            </thead>
                                            <tbody className='bg-gray-100'>
                                                {
                                                    courseVideos.map((item:any,index:number)=>(
                                                        <tr className=''>
                                                             <td className='px-2 py-2 text-center border-b  border-t-0 border-l-0  border-gray-400 border-r-0'>{index+1}</td>
                                                             <td className='px-2 py-2 text-left  border-b  border-t-0 border-l-0  border-gray-400 border-r-0'>{item?.name}</td>
                                                             <td className='px-2 py-2 text-center  border-b  border-t-0 border-l-0  border-gray-400 border-r-0'>{moment.utc(moment.duration(item?.length, 'minutes').asMilliseconds()).format('H:mm')}</td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                                </table>
            </div>
        }

        </AccordionDetails>
      </Accordion>
      <Accordion  expanded={expanded === `panel2`} onChange={handleChange(`panel2`)}
                    sx={{
                        marginY:1,
                         boxShadow:0,
                        border:0,
                      
                        background:'#c4b5fd',
                        '&.Mui-disabled': {
                            backgroundColor: '#c4b5fd',
                            opacity:1
                            },
                        
                    }}
                    >
        <AccordionSummary
         onClick={(()=>handleCourseContent("test"))}
          expandIcon={<FaAngleDown />} 
          aria-controls={`panel2-content`}
          id={`panel2-header`}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            '&.Mui-disabled': {
                opacity:1
                },
            
        }}
        >
          <div className=' hover:cursor-pointer  w-[100%] ' >
                            <div className="!flex  lg:flex-row  flex-col md:flex-col sm:flex-col justify-between " >
                            <div className='flex items-center gap-2 '>
                            
                              <p className='text-lg font-bold'>Course Test Series</p>
                            </div>
                          
                            </div>
                            </div>
        </AccordionSummary>
        <AccordionDetails>
        {
            courseTestSeries?.length > 0 && 
            <div className="relative overflow-x-auto rounded-lg  max-h-[300px] ">
            <table className='w-full border-collapse   text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
                                            <thead className="text-xs rounded-lg text-gray-200 uppercase  bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
                                                <th className="py-2 px-2 text-center">#</th>
                                                <th className="py-2 px-2 text-center">Name</th>
                                                <th className="py-2 px-2 text-center">Questions</th>
                                                <th className="py-2 px-2 text-center">Total Marks</th>
                                                <th className="py-2 px-2 text-center">Negative Marks</th>
                                                <th className="py-2 px-2 text-center">Duration</th>
                                            </thead>
                                            <tbody className='bg-gray-100'>
                                                {
                                                    courseTestSeries.map((item:any,index:number)=>(
                                                        item?.testSeries.length > 0 &&
                                                        <tr>
                                                             <td className='px-2 py-2 text-center border-b  border-t-0 border-l-0  border-gray-400 border-r-0 '>{index+1}</td>
                                                             <td className='px-2 py-2 text-center border-b  border-t-0 border-l-0  border-gray-400 border-r-0 '>{item?.name}</td>
                                                             <td className='px-2 py-2 text-center  border-b  border-t-0 border-l-0  border-gray-400 border-r-0'>{item?.testSeries.length}</td>
                                                             <td className='px-2 py-2 text-center border-b  border-t-0 border-l-0  border-gray-400 border-r-0 '>{item?.marks}</td>
                                                             <td className='px-2 py-2 text-center  border-b  border-t-0 border-l-0  border-gray-400 border-r-0'>{item?.negative_marks}</td>
                                                             <td className='px-2 py-2 text-center border-b  border-t-0 border-l-0  border-gray-400 border-r-0'>{moment.utc(moment.duration(item?.duration, 'minutes').asMilliseconds()).format('H:mm')}</td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                                </table>
            </div>
        }

        </AccordionDetails>
      </Accordion>
                        </div>
                    </div>
        </div>
        <Models name='Select Course Payment method' isOpen={open} isClose={setOpen}  >
        <CoursePayment  open={setOpen} data="" />
        </Models>
        </>
    }
    </>
  )
}
