import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import {  getStudentMasterCourses } from '../../../service/student/studentCourses.service';
import { BreadCrumRoutes, truncateString } from '../../../helpers/helpers';
import { FaCheck,  FaRupeeSign, FaStar, FaStopwatch } from 'react-icons/fa';
import Loaders from '../../../components/loaders/Loaders';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { Helmet } from 'react-helmet-async';

export default function StuCourses() {
    
    const [courseMaster, setCouseMaster] = useState<any>([]);
    const [imageLoad, setImageLoad] = useState(true);
    const [pageLoad, setPageLoad] = useState(false);
    const [breadCrumRoutes, setBreadCrumRoutes] = useState<any>();
    const navigate = useNavigate()
    const location = useLocation()
    const fetchCourseMaster= async()=>{
        setPageLoad(true)
        let get:any = await getStudentMasterCourses(location.pathname.split('/')[3]);
        if(get?.status === "success"){
            setCouseMaster(get?.message)
            setPageLoad(false)
        }
        setPageLoad(false)
    }
    useEffect(()=>{
        fetchCourseMaster()
        setBreadCrumRoutes(BreadCrumRoutes("Courses List",location.pathname,"studentcoursesList",false))
    },[])

  return (
    <div >
         <Helmet>
    <title>{`Courses List | ${window.location.hostname}`}</title>
</Helmet>
        {
            pageLoad ? <Loaders type='spinner' /> : 
            <>
            <BreadCrumb routes={breadCrumRoutes}  />
        {
            courseMaster[0]?.course.length > 0 ? 
                <>
                    <div className="grid grid-cols-12 gap-3">
                       
                        {
                             courseMaster[0]?.course.map((e:any,index:number)=>(
                                <div key={index} className=' col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3'>
                                    <div data-tooltip={e.name} data-tooltip-location="bottom"  className=' rounded-2xl bg-gray-200 hover:border-gray-300 border-gray-200 border-2 hover:shadow-lg hover:border-2'>
                                    {
                                        e.popular && <p className=' absolute bg-purple-700 p-2 flex gap-1 items-center rounded-full left-1 top-1 text-white'> <FaStar className='' /></p>
                                    }
                                    {
                                        e.studentSubscription.length !== 0 && 
                                        <p  className='absolute right-1 text-white p-2 top-2  rounded-lg bg-green-700'> <FaCheck /> </p>
                                    }
                                    <p className=' absolute bg-purple-500 py-1 px-2 flex gap-1 items-center rounded-md right-2 bottom-[7rem] text-white'>   {e.amount === "0" ? "FREE" : <><FaRupeeSign/>{e.amount}</>} </p>
                                    <img src={e.image} className=' rounded-2xl  w-full' alt="" />
                                   
                                    <div onClick={(()=>navigate(`/student/courses/detail/${e.slug}`))} className='px-2 py-2  flex justify-between items-center '>
                                        <p onClick={(()=>navigate(`/student/courses/detail/${e.slug}`))}  className='hover:cursor-pointer font-semibold text-xl mb-2'>{truncateString(e.name,15)}
                                        </p>
                                        <p className='flex gap-1  items-center '><FaStopwatch/>{e.duration} Days</p>

                                        
                                    
                                    </div>
                                    
                                    <div className='px-2 py-2    '>
                                   
                                        <button onClick={(()=>navigate(`/student/courses/detail/${e.slug}`))} className='w-full p-2 rounded-lg text-white bg-purple-800'> Know More </button>
                                     
                                    </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </>
        :null    
    }

            </>

        }
         
    </div>
  )
}
