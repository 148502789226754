import { AdminCourseCategoryTypes, AdminCourseMastersTypes, CoursesType } from "../../types/AdminTypes";
import instance from "../instace";

export const createAdminCourseMaster = async(data:AdminCourseMastersTypes)=>{
    try{
        const get:any = await instance.post('/api/admin/courses/course-master/create',{
            name:data.name,
            on_app:data.on_app,
            on_web:data.on_web,
            status:data.status,
            image:data.image
         })
         return get.data;
    }catch(e){
        return false;
    }
}
export const getAdminCourseMaster = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/courses/course-master/get?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showAdminCourseMaster  = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/course-master/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const slugAdminCourseMaster  = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/course-master/slug/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAdminCourseMaster  = async(id:string,data:AdminCourseMastersTypes)=>{
    try{
        const get:any = await instance.put(`/api/admin/courses/course-master/edit/${id}`,{
            name:data.name,
            on_app:data.on_app,
            on_web:data.on_web,
            status:data.status,
            image:data.image
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const updateStatusAdminCourseMaster  = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/courses/course-master/status/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const updateOnAppAdminCourseMaster  = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/courses/course-master/on-app/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const updateOnWebAdminCourseMaster  = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/courses/course-master/on-web/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteAdminCourseMaster  = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/courses/course-master/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}





export const createAdminCourseCategory = async(data:AdminCourseCategoryTypes)=>{
    try{
        const get:any = await instance.post('/api/admin/courses/course-category/create',{
            name:data.name,
            status:data.status,
            fk_courseMaster_id:data.fk_courseMaster_id,
            image:data.image
         })
         return get.data;
    }catch(e){
        return false;
    }
}
export const getAdminCourseCategory = async(courseMasterId:string,page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/courses/course-category/get/${courseMasterId}?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showAdminCourseCategory   = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/course-category/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAdminCourseCategory  = async(id:string,data:AdminCourseCategoryTypes)=>{
    try{
        const get:any = await instance.put(`/api/admin/courses/course-category/edit/${id}`,{
            name:data.name,
            status:data.status,
            image:data.image
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const updateStatusAdminCourseCategory  = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/courses/course-category/status/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteAdminCourseCategory   = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/courses/course-category/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const getActiveCourseCategory   = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/course-category/active/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}






export const createAdminCourse = async(data:CoursesType)=>{
    try{
        const get:any = await instance.post('/api/admin/courses/course/create',{
            fk_courseMaster_id:data.fk_courseMaster_id,
            fk_courseCategory_id:data.fk_courseCategory_id,
            fk_batch_id:data.fk_batch_id,
            name:data.name,
            duration:data.duration,
            amount:data.amount,
            description:data.description,
            image:data.image,
            order:data.order,
            popular:data.popular,
            required_login:data.required_login,
            on_app:data.on_app,
            on_web:data.on_web,
            status:data.status
         })
         return get.data;
    }catch(e){
        return false;
    }
}
export const getAdminCourse = async(courseMasterId:string,page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/courses/course/get/${courseMasterId}?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showAdminCourse   = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/course/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAdminCourse  = async(id:string,data:CoursesType)=>{
    try{
        const get:any = await instance.put(`/api/admin/courses/course/edit/${id}`,{
            fk_courseMaster_id:data.fk_courseMaster_id,
            fk_courseCategory_id:data.fk_courseCategory_id,
            fk_batch_id:data.fk_batch_id,
            name:data.name,
            duration:data.duration,
            amount:data.amount,
            description:data.description,
            image:data.image,
            popular:data.popular,
            required_login:data.required_login,
            on_app:data.on_app,
            status:data.status,
            on_web:data.on_web,
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const updateStatusAdminCourse  = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/courses/course/status/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteAdminCourse   = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/courses/course/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}

export const getCourseWithOutBatch   = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/course/withoutBatch/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const getAllCourseActive   = async()=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/course/all/active`);
        return get.data;
    }catch(e){
            return false;
    }
}

export const createAdminCourseCategoryTab = async(name:string,status:boolean,img?:any)=>{
    try{
        const get:any = await instance.post(`/api/admin/courses/category-tab`,{
            name:name,
            status:status,
            image:img
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const getAdminCourseCategoryTab = async()=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/category-tab`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const ActiveAdminCourseCategoryTab = async()=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/category-tab-active`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const ShowAdminCourseCategoryTab = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/category-tab/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAdminCourseCategoryTab = async(id:string,name:string,status:boolean,img?:any)=>{
    try{
        const get:any = await instance.put(`/api/admin/courses/category-tab/edit/${id}`,{
            name:name,
            status:status,
            image:img
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const statusAdminCourseCategoryTab = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/courses/category-tab/status/${id}`,{
            status:status,
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteAdminCourseCategoryTab = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/courses/category-tab/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const orderAdminCourseCategoryTab = async(data:any)=>{
    try{
        const get:any = await instance.post(`/api/admin/courses/category-tab/orders`,{
            data:data
        });
        return get.data;
    }catch(e){
            return false;
    }
}





export const createAdminCourseCategoryTabCourse = async(tabId:string,courses:any)=>{
    try{
        const get:any = await instance.post(`/api/admin/courses/category-tab/course`,{
          tabId:tabId,
          courses:courses
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const getAdminCourseCategoryTabCourse = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/category-tab/course/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const ActiveAdminCourseCategoryTabCourse = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/category-tab-active/course-active/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}

export const statusAdminCourseCategoryTabCourse = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/courses/category-tab/status/course/${id}`,{
            status:status,
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteAdminCourseCategoryTabCourse = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/courses/category-tab/delete/course/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const orderAdminCourseCategoryTabCourse = async(data:any)=>{
    try{
        const get:any = await instance.post(`/api/admin/courses/category-tab/course/orders`,{
            data:data
        });
        return get.data;
    }catch(e){
            return false;
    }
}



export const getAllActiveCourseList = async(query?:any)=>{
    try{
        const get:any = await instance.get(`/api/admin/courses/course-all${query ? `?query=${query}` : ''}`);
        return get.data;
     }catch(e){
            return false;
    }
}