import React, { useEffect, useState } from "react";
import Loaders from "../../../components/loaders/Loaders";
import { Helmet } from "react-helmet-async";
import {
  BreadCrumRoutes,
  randomNumber,
  toBoolean,
} from "../../../helpers/helpers";
import BreadCrumb from "../../../components/breadcrumb/BreadCrumb";
import { useLocation } from "react-router-dom";
import DropZoneUploadImages from "../../../components/dropzone/DropZoneUploadImages";
import Input from "../../../components/form/Input";
import formValidationRules from "../../../helpers/formvalidationRules";
import { SubmitHandler, useForm } from "react-hook-form";
import SearchableSelect from "../../../components/form/SearchableSelect";
import { getActiveGendersService } from "../../../service/commonService";
import {
  getStudentProfile,
  StudentProFileImageUpdate,
  StudentProfileUpdate,
} from "../../../service/student/student.service";
import moment from "moment";
import { FaEdit, FaTimesCircle } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import Models from "../../../components/models/Models";
import Cookies from "js-cookie";
import AdminProfilePasswordUpdate from "./AdminProfilePasswordUpdate";
import { AdminProFileImageUpdate, AdminProfileUpdate, getAdminProfile } from "../../../service/admin/admin.service";

export default function AdminProfile() {
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [breadCrumRoutes, setBreadCrumRoutes] = useState<any>();
  const [genderData, setGenderData] = useState([]);
  const [url, setUrl] = useState<any>();
  const [dropUrl, setDropUrl] = useState<any>()
  const [stuData, setStuData] = useState<any>();
  const [editInfomation, setEditInfomation] = useState(true);
  const [resetInformation, setResetInformation] = useState(12);
  const [open, setOpen] = useState(false);
  const [updateProfileImage, setUpdateProfileImage] = useState(false);
  const [checkUploadedFilesStatus, setCheckUploadedFilesStatus] =
    useState<any>(false);
    
  const location = useLocation();
  
  const fetchStudent = async () => {
    setPageLoad(true);
    let get: any = await getAdminProfile()
    if (get?.status === "success") {
      setStuData(get?.message);
      setUrl(get?.message?.profileImg);
      setPageLoad(false);
    }
    setPageLoad(false);
  };
  useEffect(() => {
    setBreadCrumRoutes(
      BreadCrumRoutes("Profile", location.pathname, "studentProfile", true)
    );
  }, []);

  const { control, reset, handleSubmit } = useForm<any>({
    values: {
      name: stuData?.name,
      email: stuData?.email,
      phone: stuData?.phone,
      center: stuData?.instituteCenter?.name,
    },
  });
  const onSubmit: SubmitHandler<any> = async (data: any) => {
    setLoading(true);
    let fetch: any;
    data.status = true;

    fetch = await AdminProfileUpdate(data);
    if (fetch?.status === "success") {
      setLoading(false);
      setEditInfomation(true);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchStudent();
    reset({
      name: stuData?.name,
      email: stuData?.email,
      phone: stuData?.phone,
      center: stuData?.instituteCenter?.name,
    });
  }, [setResetInformation, resetInformation]);

  const updateProImage = async(url:string)=>{
    let update:any = await AdminProFileImageUpdate(url)
    if(update?.status === "success"){
      setUrl(url)
      setUpdateProfileImage(false)
      let getTopNavIMg = JSON.parse(Cookies.get('login')!);
      console.log(getTopNavIMg)
      let newData:any  = {
        email:getTopNavIMg.email,
        name:getTopNavIMg.name,
        password_update:getTopNavIMg.password_update,
        phone:getTopNavIMg.phone,
        role:getTopNavIMg.role,
        token:getTopNavIMg.token,
        profileImg:url
      }
      Cookies.set('login', JSON.stringify(newData))
      toast.success(update.message)
    }
  }
  useEffect(()=>{
    if(dropUrl){
      updateProImage(dropUrl)
    }
  },[dropUrl])

  return pageLoad ? (
    <Loaders type="spinner" />
  ) : (
    <>
      <Helmet>
        <title>{`Profile | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadCrumb routes={breadCrumRoutes} />

      <div className="my-2 grid grid-cols-12 gap-2">
        <div className="col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-3">
          <div className="border border-gray-300 rounded-lg p-3 bg-white">
            <div className="flex items-center justify-between ">
              <p className="text-lg text-gray-600 font-bold mb-3">Profile Image</p>
              {updateProfileImage === true ? (
                  <Tooltip title="cancle changes">
                    <button
                      type="button"
                      onClick={() => 
                        setUpdateProfileImage(!updateProfileImage)
                      }
                    >
                      <FaTimesCircle className=" text-2xl mb-3 text-red-600  hover:cursor-pointer" />
                    </button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit Information">
                    <button
                      type="button"
                      onClick={() => 
                        setUpdateProfileImage(!updateProfileImage)
                      }
                    >
                      <FaEdit className=" text-2xl mb-3 text-gray-600 hover:cursor-pointer" />
                    </button>
                  </Tooltip>
                )}
            </div>
            <hr />
            {
              updateProfileImage ?
              <DropZoneUploadImages
              heigth="min-h-[20vh]"
              imageSize="400*400"
              maxFile={1}
              data={setDropUrl}
              status={setCheckUploadedFilesStatus}
            /> : 

            <img src={url} className="min-h-[20vh] w-full rounded-lg" alt="" />
            }
            {/* <DropZoneUploadImages
              heigth="min-h-[20vh]"
              maxFile={1}
              data={setUrl}
              url={url}
              status={setCheckUploadedFilesStatus}
            /> */}
          </div>
        </div>

        <div className="col-span-12 sm:col-span-12 md:col-span-8 lg:col-span-9 ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="border border-gray-300 rounded-lg p-3 bg-white">
              <div className="flex items-center justify-between ">
                <p className="text-lg text-gray-600 font-bold mb-3">
                  Personal Information
                </p>
                {editInfomation === false ? (
                  <Tooltip title="cancle changes">
                    <button
                      type="button"
                      onClick={() => {
                        setEditInfomation(!editInfomation);
                        setResetInformation(randomNumber(3));
                      }}
                    >
                      <FaTimesCircle className=" text-2xl mb-3 text-red-600  hover:cursor-pointer" />
                    </button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit Information">
                    <button
                      type="button"
                      onClick={() => setEditInfomation(!editInfomation)}
                    >
                      <FaEdit className=" text-2xl mb-3 text-gray-600 hover:cursor-pointer" />
                    </button>
                  </Tooltip>
                )}
              </div>
              <hr />
              <div className="my-3">
                <Input
                  name="name"
                  type="text"
                  control={control}
                  readonly={editInfomation}
                  label="Name"
                  placeholder="expiry date"
                />
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-12 sm:col-span-12 md:col-span-6">
                    <Input
                      name="email"
                      type="email"
                      control={control}
                      readonly={editInfomation}
                      label="Email"
                      placeholder="expiry date"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-12 md:col-span-6">
                    <Input
                      name="phone"
                      type="text"
                      control={control}
                      readonly={editInfomation}
                      label="Phone"
                      placeholder="expiry date"
                    />
                  </div>
                </div>
                
                <Input
                  name="center"
                  type="text"
                  readonly={true}
                  control={control}
                  label="Center Name"
                  placeholder="Center Name"
                />
              </div>
              {editInfomation === false && (
                <div className="flex justify-end">
                  <button
                    disabled={loading}
                    type="submit"
                    className="flex items-center bg-purple-700 gap-2 text-white rounded-xl px-3 py-2"
                  >
                    <FaEdit /> {loading ? "updating..." : "Submit"}
                  </button>
                </div>
              )}
            </div>
          </form>
          <div className="border border-gray-300 rounded-lg p-3 bg-white my-5">
            <div className="flex items-center justify-between ">
              <p className="text-lg text-gray-600 font-bold mb-3">Password</p>
              <Tooltip title="Edit Password">
                <button type="button" onClick={(()=>setOpen(!open))}>
                  <FaEdit className=" text-2xl mb-3 text-gray-600 hover:cursor-pointer" />
                </button>
              </Tooltip>
            </div>
            <hr />
            <div className="my-3">
              <Input
                name="password"
                type="password"
                readonly={true}
                control={control}
                defaultValue="********"
                label="Current Password"
                placeholder="*******"
              />
            </div>
          </div>
        </div>
      </div>
      <Models name='Password Update' isOpen={open}  isClose={setOpen} >
            <AdminProfilePasswordUpdate open={setOpen} />
        </Models>
    </>
  );
}
