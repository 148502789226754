import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AdminVimeoModulesTypes } from '../../../../types/AdminTypes';
import { setContentUpdate } from '../../../../redux/contentUpdateSlice';
import { toast } from 'react-toastify';
import Input from '../../../../components/form/Input';
import formValidationRules from '../../../../helpers/formvalidationRules';
import RadioButton from '../../../../components/form/RadioButton';
import { statusList, toBoolean } from '../../../../helpers/helpers';
import FormButton from '../../../../components/button/FormButton';
import { createAdminVideosModule, editAdminVideosModule, showAdminVideosModule } from '../../../../service/admin/adminVideoes.service';

export default function VimeoModuleCE(props:{type:string,isOpen:any,data?:any}) {


    const [fetchData, setFetchData] =useState<any>();
    const [loading, setLoading] = useState(false);
    const [pageContent, setPageContent] = useState({
        button:"Create New Account",
        title:"Create Account"
    })
    const dispatch  = useDispatch()
    const fetchDa = async (id:string) => {
      let get:any = await showAdminVideosModule(id)
      if(get?.status === "success"){
        setFetchData(get?.message)
      }
    }
    useEffect(()=>{
        if(props.type === "edit"){
          fetchDa(props.data.id)
            setPageContent({
                   button:"Update  Account",
        title:"Update Account"
            })
        }
    },[])
    const { handleSubmit, control } = useForm<AdminVimeoModulesTypes>({
        values:{
            name:fetchData?.name,
            status:fetchData?.status ? fetchData?.status  === true ? 'true' : 'false' :'true',
            restriction:fetchData?.attempt,
        }
      });

      const onSubmit: SubmitHandler<AdminVimeoModulesTypes> = async(data:any) => {
        setLoading(true);
        let fetch:any;
        data.status = data.status ?  toBoolean(data.status) : fetchData?.status;
       if(props.type==="create"){
        fetch = await createAdminVideosModule(data);
       }
       if(props.type === "edit"){
        fetch = await editAdminVideosModule(props.data.id,data);
       }
       if(fetch?.status=== "success"){
        dispatch(setContentUpdate({
          id:Math.random() * (100000 - 111111)
        }))
        setLoading(false)
        props.isOpen(false)
        return toast.success(fetch.message);
      }
      setLoading(false)
      
      };  
  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>
     <Input 
       name="name"
       control={control}
       rules={{
        ...formValidationRules.required(),
        ...formValidationRules.minLength(3)
       }}
       label="Module Name"
       placeholder="module name"
      />
      <RadioButton
        name="status"
        control={control}
        rules={{...formValidationRules.required()}}
        options={statusList}
        label="status"
      />
      <br/>
       <FormButton loading={loading} title={pageContent.button} />
     </form>
    </>
  )
}
