import React, { useState } from 'react'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../components/button/CustomButton'
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid'
import Models from '../../../components/models/Models'
import AdminExamAlertCE from './AdminExamAlertCE'
import { Helmet } from 'react-helmet-async'

export default function AdminExamAlert() {
    const [open,setOpen] = useState(false)
  return (
    <>
     <Helmet>
    <title>{` Exam Alert    | ${window.location.hostname}`}</title>
</Helmet>
    <BreadcrumbBack title={`Exam Alert`}>
    <CustomButton onClick={(()=>setOpen(!open))} bg='bg-purple-800'  title='Create Exam Alert' icon={true} iconName='create'    />
   </BreadcrumbBack>
    <div className="my-3">
    <PaginationDataGrid  name="adminExamAlert" /> 
    </div>

    <Models name='Create Exam Alert' isOpen={open} isClose={setOpen}  >
        <AdminExamAlertCE type='create' open={setOpen} />
    </Models>
    </>
  )
}
