// RadioButton.tsx
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { inputError, inputField, inputRadioField } from '../../helpers/Classes';

interface Option {
    value: string;
    label: string;
  }

interface RadioButtonProps {
  name: string;
  control: Control<any>;
  rules?: object;
  options: { value: string; label: string }[];
  label: string;
  defaultValue?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({ name, control, rules, options,defaultValue, label }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue || '',
  });   
  return (
    <div>
      <fieldset>
        <legend className={`${error ? 'text-red-500' : 'text-gray-600 dark:text-gray-400'} ml-1`}>
          {label} {rules && (rules as any).required && <span style={{ color: 'red' }}>*</span>}
        </legend>
        {options.map((option) => (
          <label key={option.value} htmlFor={`${name}-${option.value}`} className={`scaled-radio px-2 ${error ? 'text-red-500' : ''}`}>
            
            <input
            className='mt-4'
              type="radio"
              id={`${name}-${option.value}`}
              {...field}
              value={option.value}
              checked={field.value === option.value}
            />
            {option.label}
          </label>
        ))}
        {error && <p style={{ color: 'red' }}>{error.message}</p>}
      </fieldset>
    </div>
  );
};

export default RadioButton;
