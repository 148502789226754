import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import BreadcrumbBack from '../../../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../../../components/button/CustomButton'
import { useLocation } from 'react-router-dom';
import { getAdminCouponMasterCourses } from '../../../../../service/admin/adminCoupon.service';
import Models from '../../../../../components/models/Models';
import AdminCouponCoursesCreate from './AdminCouponCoursesCreate';
import MUITableGrid from '../../../../../components/tables/MUITableGrid';

export default function AdminCouponCourses() {
    const location = useLocation()
    const [open,setOpen] = useState(false);
    const [couponId, setCouponId] = useState<any>(location.pathname.split('/')[6])
    const [fetchData, setFetchData] = useState([])
  
    const fetch = async(id:string)=>{
        const get:any = await getAdminCouponMasterCourses(id)
        if(get?.status === "success"){
            setFetchData(get?.message)
            
        }
    }
    useEffect(()=>{
        setCouponId(location.pathname.split('/')[6])
        fetch(location.pathname.split('/')[6])
    },[])
  return (
    <>
    <Helmet>
    <title>{`Coupon Master | ${window.location.hostname}`}</title>
    </Helmet>
    <BreadcrumbBack title={`Coupon Master Courses `}>
    <CustomButton onClick={(()=>setOpen(!open))}  title='Add Courses' icon={true} iconName='create'    />
    </BreadcrumbBack>
    <div className="my-2">
        <MUITableGrid name="adminCouponMaster" id={couponId} />
    </div>
    <Models name='Add Course' isOpen={open} isClose={setOpen}  >
        <AdminCouponCoursesCreate id={couponId} open={setOpen} />
    </Models>
   </>
  )
}
