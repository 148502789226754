import React, { useEffect, useState } from "react";
import Loaders from "../../../components/loaders/Loaders";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import logo from "../../../assets/images/logo.jpg";
import parse from "html-react-parser";
import CountDownTimer from "../../../components/Timers/CountDownTimer";
import { getStudentTestSeriesQuestions, StudentTestSeriesSubmit } from "../../../service/student/student.service";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
export default function StudentTestSeries() {
  const [pageLoad, setPageLoad] = useState(false);
  const [fetchData, setFetchData] = useState<any>([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [questionBtn, setQuestionBtn] = useState(0)
  const [selectedQuestion, setSelectedQuestion] = useState<any>(localStorage.getItem('storedQuestion')  ? JSON.parse(localStorage.getItem('storedQuestion')!) : [])
  const [QuestionTimer, setQuestionTimer] = useState(0);
  const [remainingTime,setRemainingTime] = useState<any>();

  const [submitType, setSubmitType] = useState(0)
  const [loading, setLoading] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();

  let getRoutes: any = Cookies.get("routes");
  let route: any = JSON.parse(getRoutes);
  let check = route.filter((e: any) => e.key === "studentTestSeriesModule");
  const fetchVideos = async (id: string) => {
    setPageLoad(true);
    let get: any = await getStudentTestSeriesQuestions(
      id,
      check[0].link.split("/")[4].split("?")[0]
    );
    if (get?.status === "success") {
      setPageLoad(false);
      setFetchData(get?.message);
      setQuestionTimer(localStorage.getItem("timer") ? parseInt(localStorage.getItem("timer")!) : get?.message?.duration*60 )
    }
    setPageLoad(false);
  };

  useEffect(() => {
    fetchVideos(
      location.pathname.split("/")[location.pathname.split("/").length - 1]
    );
  }, []);
  useEffect(() => {
    // Prevent right-click context menu
    const handleContextMenu = (event:any) => {
      event.preventDefault();
    };

    // Prevent text copying
    const handleCopy = (event:any) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("copy", handleCopy);

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("copy", handleCopy);
    };
  }, []);
  
  const handleQuestionChange = (id:any)=>{
    setQuestionCount(id)
  }
  const handleNextQuestion = ()=>{
    setQuestionCount(questionCount+1)
  }
  const handlePrevQuestion = ()=>{
    setQuestionCount(questionCount-1)
  }
  const handleQuesrtionSelection = (event:any)=>{
    let id:any = event.target.value.split('::')[0]
    let selectedOption:any = event.target.value.split('::')[2]
    
    setSelectedQuestion((prevItems:any) => {
      const itemExists = prevItems.some((item:any) => item.id === id);
      if (itemExists) {
        return prevItems.map((item:any) =>
          item.id === id ? { ...item, option: selectedOption,} : item
        );
      } else {
        return [...prevItems, { id, option: selectedOption }];
      }
    });
    localStorage.setItem('storedQuestion', JSON.stringify(selectedQuestion))
  }
  const isOptionSelected = (option: number) => {
    if(fetchData?.testSeries?.length > 0){

      const currentQuestion = fetchData?.testSeries[questionCount];
      const selected = selectedQuestion.find(
        (item: any) => item.id === currentQuestion.id
      );
      return selected ? selected.option === option : false;
    }
    return false;
  };
  useEffect(() => {
    if (remainingTime) localStorage.setItem('timer', remainingTime);
  }, [remainingTime]);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = (event:any) => {
       event.preventDefault();
 return Swal.fire({
    title: "Are you sure you want to leave this page?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Leave",
    cancelButtonText: "Stay",
  }).then((result) => {
    if (result.isConfirmed) {
      localStorage.removeItem('storedQuestion')
      localStorage.removeItem('timer')
      navigate(-1); // Proceed with back navigation
    } else {
      // Keep the user on the page
      window.history.pushState(null, document.title, window.location.href);
    }
  }); 
    };
    const handleBeforeUnload = (event:any) => {
        event.preventDefault();
        event.returnValue = ''; // Trigger the browser unload dialog
    };
    window.addEventListener("popstate", handlePopState);
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
        window.removeEventListener("popstate", handlePopState);
        window.removeEventListener("beforeunload", handleBeforeUnload);
    };
}, []);
  const submitTest = async()=>{
    setLoading(true);
    localStorage.removeItem('storedQuestion')
    localStorage.removeItem('timer')
    let submit:any = await StudentTestSeriesSubmit(location.pathname.split("/")[location.pathname.split("/").length - 1],check[0].link.split("/")[4].split("?")[0],selectedQuestion)
    if(submit.status === "success"){
      setLoading(false)
      toast.success("Test Submitted Successfully")
      navigate(-1)
      // navigate(`/student/testseries/result/${location.pathname.split("/")[location.pathname.split("/").length - 1]}`)
    }
    setLoading(false)
  }
  const handleSubmit = async()=>{
    setLoading(true)
    if(selectedQuestion.length !== fetchData?.testSeries.length){
      let txt = "Look like you don\'t selecte all the questions, Still want to submit test ?";
      Swal.fire({
        title: txt,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true,
        preConfirm: async(value) => {
          setLoading(false)
              await submitTest()
        }
      })
    }else{
      setLoading(false)
      await submitTest()
    }
    setLoading(false)
  }
  if(remainingTime &&remainingTime === 1){
    Swal.fire({
      position: "top-end",
      icon: "warning",
      title: "Time is Over",
      showConfirmButton: false,
    });
    submitTest()
  }

 


  return  <>
   <Helmet>
            <title>{`Registration | ${window.location.hostname}`}</title>
        </Helmet>
        {
          pageLoad ? (
            <Loaders type="spinner" />
          ) : (
            
            <div className="max-h-screen max-w-full w-full h-screen overflow-hidden relative">
              <div className="bg-gray-300 p-2 h-[10vh] flex items-center justify-between">
                <div>
                  <img src={logo} className="h-[8vh]" alt="" />
                </div>
                <div className=" hidden sm:hidden md:block">
                  <p className="text-xl text-dark "> {fetchData?.name}'s Test</p>
                </div>
                <div>
                 
                  <CountDownTimer remaining={setRemainingTime} startTime={QuestionTimer} interval={1000} />
                </div>
              </div>
              <div className=" block sm:block md:hidden bg-gray-300">
                <p className="text-2xl text-dark text-center ">
                  {fetchData?.name}'s Test
                </p>
              </div> 
              <div className="grid grid-cols-12 gap-3">
                <div className="col-span-12 sm:col-span-12 md:col-span-9 p-3 overflow-auto">
                  <div className="question h-[75vh] max-h-[75vh]  overflow-auto  ">
                  {fetchData?.testSeries && fetchData?.testSeries[questionCount]?.description &&
                      parse(fetchData?.testSeries[questionCount]?.description)} <br/>
                    Q.{questionCount + 1}{" "}
                    {fetchData?.testSeries &&
                      parse(fetchData?.testSeries[questionCount]?.question)}
        <hr className="my-3" />
        <div className="flex flex-col overflow-auto gap-4 mt-2 mb-3">
                    <p>Select the Correct Option </p>
                    <div className="flex flex-row gap-2 items-center">
                      <input
                        id={`defaultradio1${fetchData?.testSeries && fetchData?.testSeries[questionCount].id}`}
                        checked={isOptionSelected(fetchData?.testSeries && fetchData?.testSeries[questionCount]?.testSeriesOptions[0]?.id)}
                        type="radio"
                        value={`${fetchData?.testSeries && fetchData?.testSeries[questionCount].id}::1::${fetchData?.testSeries && fetchData?.testSeries[questionCount]?.testSeriesOptions[0]?.id}`}
                        name="answer"
                        style={{ width: "30px", height: "30px" }}
                        onChange={handleQuesrtionSelection}
                      />
                      <label htmlFor={`defaultradio1${fetchData?.testSeries && fetchData?.testSeries[questionCount].id}`} className="">
                        {fetchData?.testSeries &&
                          parse(
                            fetchData?.testSeries[questionCount]?.testSeriesOptions[0]
                              .option
                          )}
                      </label>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                      <input
                        id={`defaultradio2${fetchData?.testSeries && fetchData?.testSeries[questionCount].id}`}
                        type="radio"
                        checked={isOptionSelected(fetchData?.testSeries && fetchData?.testSeries[questionCount]?.testSeriesOptions[1]?.id)}
                        value={`${fetchData?.testSeries && fetchData?.testSeries[questionCount].id}::2::${fetchData?.testSeries && fetchData?.testSeries[questionCount]?.testSeriesOptions[1]?.id}`}
                        style={{ width: "30px", height: "30px" }}
                        onChange={handleQuesrtionSelection}
                         name="answer"
                      />
                      <label htmlFor={`defaultradio2${fetchData?.testSeries && fetchData?.testSeries[questionCount].id}`} className="">
                        {fetchData?.testSeries &&
                          parse(
                            fetchData?.testSeries[questionCount]?.testSeriesOptions[1]
                              .option
                          )}
                      </label>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                      <input
                        id={`defaultradio3${fetchData?.testSeries && fetchData?.testSeries[questionCount].id}`}
                        type="radio"
                        checked={isOptionSelected(fetchData?.testSeries && fetchData?.testSeries[questionCount]?.testSeriesOptions[2]?.id)}
                        value={`${fetchData?.testSeries && fetchData?.testSeries[questionCount].id}::3::${fetchData?.testSeries && fetchData?.testSeries[questionCount]?.testSeriesOptions[2]?.id}`}
                        style={{ width: "30px", height: "30px" }}
                        onChange={handleQuesrtionSelection}
                         name="answer"
                      />
                      <label htmlFor={`defaultradio3${fetchData?.testSeries && fetchData?.testSeries[questionCount].id}`} className="">
                        {fetchData?.testSeries &&
                          parse(
                            fetchData?.testSeries[questionCount]?.testSeriesOptions[2]
                              .option
                          )}
                      </label>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                      <input
                        id={`defaultradio4${fetchData?.testSeries && fetchData?.testSeries[questionCount].id}`}
                        type="radio"
                        checked={isOptionSelected(fetchData?.testSeries && fetchData?.testSeries[questionCount]?.testSeriesOptions[3]?.id)}
                        value={`${fetchData?.testSeries && fetchData?.testSeries[questionCount].id}::4::${fetchData?.testSeries && fetchData?.testSeries[questionCount]?.testSeriesOptions[3]?.id}`}
                        style={{ width: "30px", height: "30px" }}
                        onChange={handleQuesrtionSelection}
                         name="answer"
                      />
                      <label htmlFor={`defaultradio4${fetchData?.testSeries && fetchData?.testSeries[questionCount].id}`} className="">
                        {fetchData?.testSeries &&
                          parse(
                            fetchData?.testSeries[questionCount]?.testSeriesOptions[3]
                              .option
                          )}
                      </label>
                    </div>
                  </div>
        
                  </div>
                  
                </div>
                <div className="col-span-3 hidden sm:hidden md:block ">
                      <div className="max-h-[90vh] h-[90vh] overflow-auto bg-gray-400">
                        <p className="text-dark text-2xl my-2 font-bold text-center">Questions</p>
                        <div className="mx-3 my-3">
                       {fetchData?.testSeries?.map((question: any, index: number) => {
                        const isSelected = selectedQuestion.some(
                          (item: any) => item.id === question.id
                        );
                        return (
                          <button
                            key={index}
                            onClick={() => handleQuestionChange(index)}
                            className={`px-2 font-bold w-[50px] h-[50px] p-2 rounded-lg m-1 ${
                              questionCount === index
                                ? "bg-purple-700 text-white"
                                : isSelected
                                ? "bg-green-700 text-white"
                                : "bg-gray-200 text-dark"
                            }`}
                          >
                            {index + 1}
                          </button>
                        );
                      })}
                        </div>
                      </div>
                    
                </div>
              </div>
              <div className=" absolute bottom-0 w-full h-[10vh] bg-dark-purple">
                <div className="flex justify-between h-[10vh] mx-1 sm:mx-2 md:mx-5 lg:mx-10 items-center">
                  <div>
                    <button
                      onClick={handlePrevQuestion}
                      disabled={questionCount === 0 ? true : false}
                      className="px-4 py-2 bg-gray-500  border-gray-400 border-2 rounded-xl text-white text-3xl font-bold font-mono"
                    >
                      PREVIOUS
                    </button>
                  </div>
                  <div></div>
                  <button
                  onClick={questionCount+1 === fetchData?.testSeries?.length ? handleSubmit :handleNextQuestion}
                      disabled={loading ? true : false}
                    className={`${questionCount+1 === fetchData?.testSeries?.length ? "px-4 bg-purple-700 border-purple-400" : "px-10 bg-green-700 border-green-400"}  py-2  border-2 rounded-xl text-white text-3xl font-bold font-mono`}
                  >
                    {
                      questionCount+1 === fetchData?.testSeries?.length ? "Submit" : "Next"
                    }
                    
                  </button>
                </div>
              </div>
            </div>
          )
        }
  </>
  
}
