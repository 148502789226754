import React, { useEffect, useState } from 'react'
import { getCoursePaymentMethods } from '../../../service/student/studentCourses.service';
import Loaders from '../../../components/loaders/Loaders';

export default function CoursePayment(props:{open:any,data:any}) {
  const [loading, setLoading] = useState(false);
  const [fetchData, setFetchData] = useState([])

  const fetch = async () => {
    setLoading(true)
    let get:any = await getCoursePaymentMethods()
    if(get?.status === "success"){
      setLoading(false)
      setFetchData(get?.message)
    }
    setLoading(false)
  }
  useEffect(() => {
    fetch()
  }, [])
  // 
    // const paymentMethod = [
    //     {id:1,name:"QR code", icon:}
    // ]

  return (
    <>
    {
      loading ?
      <Loaders type='spinner' /> 
      :
      <div>
        {
          fetchData.length > 0 ?
            fetchData.map((e:any,index:number)=>(
              <div key={index} className="flex flex-col gap-2 ">
               <button className='bg-purple-300 hover:bg-purple-600  px-3 py-2 my-2 rounded-lg flex items-center gap-3'>
                <img src={e.paymentMethodMaster?.icon} className='w-[50px] h-[50px] rounded-full' alt="" />
                <p className='text-lg font-semibold'>
                  {
                    e.name
                  }
                </p>
               </button>
              </div>
            ))
          : <p>No payment methods available</p>
        }
      </div>
    }
    </>
  )
}
