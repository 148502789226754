import React, { useEffect, useState } from 'react'
import { studentmakePaymentStatus } from '../../service/student/studentCourses.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

export default function PaymentConfirmation() {
    const [errorData, setErrorData] = useState<any>();
    const location = useLocation()
    const navigate = useNavigate()
    const checkPayment = async()=>{
        let get:any = await studentmakePaymentStatus(location.pathname.split('/')[4],location.pathname.split('/')[3]);
        if(get?.message?.code === "PAYMENT_SUCCESS"){
            navigate("/student/dashboard")
        }else{
            setErrorData(get?.message)
        }
    }
    useEffect(()=>{
        checkPayment()
    },[])
  return (
    <div className="w-[100vw] h-[100vh] bg-gray-300 flex items-center justify-center flex-col gap-3">
        <FaTimes className='text-[10rem] border-red-500 border-2  rounded-full p-2 text-red-500' />
        <p className=' font-bold text-red-600  text-2xl'>Your payment failed!</p>
        <button onClick={(()=>navigate("/student/dashboard"))} className='bg-purple-600 text-white px-5 py-3 rounded-xl text-xl'>Dashboard</button>
    </div>
  )
}
