import React, { useState } from 'react'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../components/button/CustomButton'
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid'
import Models from '../../../components/models/Models'
import AdminTestSeriesModulesCE from './AdminTestSeriesModulesCE'
import { Helmet } from 'react-helmet-async'

export default function AdminTestSeriesModules() {

    const [open, setOpen] = useState(false)

  return (
    <>
      <Helmet>
    <title>{` Test Series   | ${window.location.hostname}`}</title>
</Helmet>
    <BreadcrumbBack title="Test Series Modules">

    <CustomButton
        onClick={(()=>setOpen(!open))}
       title="Create Test Series Module"
       icon={true}
       iconName="create"
     />
   </BreadcrumbBack>
   <div className="my-2">
     <PaginationDataGrid name="adminTestSeriesModules" />
   </div>
    
   <Models  name="Create Test Series Module" isOpen={open} isClose={setOpen}  >
      <AdminTestSeriesModulesCE type='create' open={setOpen} />
   </Models>
 </>
  )
}
