import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { randomNumber } from '../../helpers/helpers';
import Input from '../../components/form/Input';
import FormButton from '../../components/button/FormButton';
import { generateRandomString } from '../../helpers/commonHelper';
import { phonePayPaymentCreate } from '../../service/admin/admin.service';

export default function PhonePayTest() {
    const [pageLoad, setPageLoad]= useState(false);
    const [fetchData, setFetchData] =useState<any>();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [pageContent, setPageContent] = useState({
        button:"Create New Batch",
    })
    const [phone, setPhone] = useState<any>()
    useEffect(()=>{
        setPhone(generateRandomString(10,true))
    },[])
      const { handleSubmit, control } = useForm<any>({
        values:{
            name:"Atul",
            phone:phone,
            price:100
        }
      });
      const onSubmit: SubmitHandler<any> = async(data) => {
        setLoading(true);
        let fetch:any;
        fetch = await phonePayPaymentCreate(data.name, data.phone, data.price);
        console.log(fetch)
        if(fetch?.status === "success"){
            window.location.href = fetch?.message;
        }
      setLoading(false)
      
      };    
      


  return (
    <>
     <div className="my-2 p-5">
    <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>
    <Input 
       name="name"
       control={control}
       label="Name"
       placeholder="Name"
      />
     <Input 
       name="phone"
       control={control}
       label="Phone"
       placeholder="Name"
      />
      <Input 
       name="price"
       control={control}
       label="price"
       placeholder="Name"
      />
        <br/>
        <FormButton loading={loading} title={pageContent.button} />

    </form>
    </div>
    </>
  )
}

