import React, { useEffect, useState } from 'react'
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../../components/button/CustomButton'
import { CiWarning } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import TableButton from '../../../../components/button/TableButton';
import { getpagingNumber, truncateString } from '../../../../helpers/helpers';
import { FaClock, FaFolder, FaUser, FaUserCircle, FaVideo } from 'react-icons/fa';
import folderIcon from "../../../../assets/icons/folder.png"
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import Loaders from '../../../../components/loaders/Loaders';
import FormButton from '../../../../components/button/FormButton';
import Swal from 'sweetalert2';
import Models from '../../../../components/models/Models';
import MultiSearchableSelect from '../../../../components/form/MultiSearchableSelect';
import AdminVimeoImport from './AdminVimeoImport';
import { Helmet } from 'react-helmet-async';
import { decryptCryptoData } from '../../../../helpers/encryptionKey';
export default function AdminVideoVimeo() {
  const [selectedVimeoAccount,setSelectedVimeoAccount] = useState<any>(undefined)
  const [vimeoData, setVimeoData] = useState<any>([])
  const [paging, setpaging] = useState<any>(null)
  const [videoLoading, setVideoLoading] = useState(false)
  const [vimeoPath, setVimeoPath] = useState<any>()
  const [selectData, setSelectData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [fetchOnlyVideos, setFetchOnlyVideos] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [modelData,setModelData] = useState<any>([]);
  const [modelLoading, setModelLoading] = useState(false)
  const navigate = useNavigate()  
  const [selectedVideo, setSelectedVideo] = useState<any>([])

  const fetchDirs = async(page:number)=>{
    setVimeoPath([
      {id:1,name:"Root",uri:"",folders:1,active:true,selected:false}
    ])
    setVideoLoading(true)
    let userId:any = decryptCryptoData(JSON.parse(localStorage.getItem(`vimoeAccount`)!)?.userId)
    const accessToken = decryptCryptoData(JSON.parse(localStorage.getItem(`vimoeAccount`)!)?.token);
    console.log(accessToken)
    const apiUrl = `https://api.vimeo.com/users/${userId}/folders/root?page=${page}&per_page=12&sort=date&direction=asc`;

    axios.get(apiUrl, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        let pages = {
          next : isNaN(parseInt(response.data.paging.next?.split('=')[1])) ? -1 : parseInt(response.data.paging.next?.split('=')[1]),
          previous : isNaN(parseInt(response.data.paging.previous?.split('=')[1])) ? -1 : parseInt(response.data.paging.previous?.split('=')[1]),
          first : parseInt(response.data.paging.first.split('=')[1]),
          last : parseInt(response.data.paging.last.split("=")[1]),
          current : parseInt(response.data.page),
          pageCount :getpagingNumber(2,parseInt(response.data.page),parseInt(response.data.paging.last.split("=")[1]))
        }
        setpaging(pages)
        let dirs = response.data.data;
      
          if(dirs.length !== 0){
            let dirData:any = [];
              dirs.map((dir:any) =>{
                if(dir.type === "folder"){
                  dirData.push({
                    type:dir.type,
                    name:dir.folder?.name,
                    uri:dir.folder?.uri,
                    folders:dir['folder']['metadata']['connections']['folders']["total"],
                    videos:dir['folder']['metadata']['connections']['videos']["total"],
  
                  })
                }else if(dir.type === "video"){
                  dirData.push({
                    type:dir.type,
                    name:dir.video?.name,
                    uri:dir.video?.uri,
                    duration:dir.video.duration,
                    thumbnail:dir?.video?.pictures?.base_link
    
                  })
                }
                
              })
              setVimeoData(dirData)
          }
          setVideoLoading(false)
    })
    .catch(error => {
      toast.error(error.message)
        setVideoLoading(false)
    });
  }
  const fetchMoreData = async(uri:any,dir:number,name?:any)=>{
    if(selectData.length !== 0){
      return Swal.fire({
        title: "You have selected folder and video, are you sure want to reset them?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setSelectData([])
          const updatedVimeoPath = vimeoData.map((item:any) => ({
            ...item,
            selected: false
          }));
          setVimeoData(updatedVimeoPath)
        } 
      });
    }
    let find:any = vimeoPath.filter((e:any)=>e.name === name)
    if(find.length === 0){
      const updatedVimeoPath = vimeoPath.map((item:any) => ({
        ...item,
        active: false
      }));
      setVimeoPath([...updatedVimeoPath, {
        id:vimeoPath.length+1,
          name:name,
          uri:uri,
          folders:dir,
          active:true,
          selected:false
        
      }])
    }else{
      let ids = find[0].id;
      let data = [];
      for(let i=1;i<=ids;i++){
      if(ids === i){
        data.push({
          id:i,
          name:vimeoPath[i-1].name,
          uri:vimeoPath[i-1].uri,
          folders:vimeoPath[i-1].folders,
          active:true,
          selected:false
        })
      }else{
        data.push({
          id:i,
          name:vimeoPath[i-1].name,
          uri:vimeoPath[i-1].uri,
          folders:vimeoPath[i-1].folders,
          active:vimeoPath[i-1].active,
          selected:false
        })
      }
      }
      setVimeoPath(data)
    }
    
    setVideoLoading(true)
    const accessToken = decryptCryptoData(JSON.parse(localStorage.getItem(`vimoeAccount`)!)?.token);
    const apiUrl = `https://api.vimeo.com${uri}/${dir > 0 ? 'items' : 'items'}?sort=date&direction=asc`;
    axios.get(apiUrl, {
      headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
      }
  })
  .then(response => {
      let pages = {
        next : isNaN(parseInt(response.data.paging.next?.split('=')[1])) ? -1 : parseInt(response.data.paging.next?.split('=')[1]),
        previous : isNaN(parseInt(response.data.paging.previous?.split('=')[1])) ? -1 : parseInt(response.data.paging.previous?.split('=')[1]),
        first : parseInt(response.data.paging.first.split('=')[1]),
        last : parseInt(response.data.paging.last.split("=")[1]),
        current : parseInt(response.data.page),
        pageCount :getpagingNumber(2,parseInt(response.data.page),parseInt(response.data.paging.last.split("=")[1]))
      }
      setpaging(pages)
      let dirs = response.data.data;
        if(dirs.length !== 0){
          let dirData:any = [];
            dirs.map((dir:any) =>{
              if(dir.type === "folder"){
                dirData.push({
                  type:dir.type,
                  name:dir.folder?.name,
                  uri:dir.folder?.uri,
                  folders:dir['folder']['metadata']['connections']['folders']["total"],
                  videos:dir['folder']['metadata']['connections']['videos']["total"],
  
                })
              }
              else if(dir.type === "video"){
               
                dirData.push({
                  type:dir.type,
                  name:dir.video?.name,
                  uri:dir.video?.uri,
                  duration:dir.video.duration,
                  thumbnail:dir?.video?.pictures?.base_link
  
                })
              }
            })
            setVimeoData(dirData)
        }
        setVideoLoading(false)
  })
  .catch(error => {
    toast.error(error.message)
        setVideoLoading(false)
  });
  }
  useEffect(() => {

    if(localStorage.getItem("vimoeAccount")){
      let get:any = JSON.parse(localStorage.getItem("vimoeAccount")!);
      let newData:any = {
        id:get?.id,
        userId:decryptCryptoData(get?.userId),
        token:decryptCryptoData(get?.token),
        identifier:decryptCryptoData(get?.identifier),
        secret:decryptCryptoData(get?.secret),
        name:decryptCryptoData(get?.name)
      }
      setSelectedVimeoAccount(newData)
      fetchDirs(1)
    }
   
    
}, []);

useEffect(()=>{
  let hs:any = localStorage.getItem('vimeoHistory')
  if(hs){
    let data:any = JSON.parse(hs)
    if(data?.length > 0){
      data.map((item:any) => {
        fetchMoreData(item.uri, item.dir)
      })
    }
    else{
      setVimeoPath([
        {id:1,name:"Root",uri:"",folders:1,active:true,selected:false}
      ])
    }
  }else{
    setVimeoPath([
      {id:1,name:"Root",uri:"",folders:1,active:true,selected:false}
    ])
  }
},[setVimeoPath])

const handleCheckboxClick = (e:any,d:any) => {
  if(e.split('/')[1] === "videos"){
    setSelectedVideo([...selectedVideo,d])
  }else{
    let check:any = selectData.filter((i:any) =>i === e)
    if(check.length === 0){
      setSelectData([...selectData,e])
    }else{
      let get:any = selectData.filter((i:any) =>i!==e)
      setVimeoData((prevState:any) => 
        prevState.map((user:any) => 
          user.uri === e ? { ...user, selectecd: true } : user
        )
      );
      setSelectData(get)
    }
  }
 
};

const getVimeoUriData = async (uri: string, page: number): Promise<any[]> => {
  const accessToken = decryptCryptoData(JSON.parse(localStorage.getItem(`vimoeAccount`)!)?.token);
  const apiUrl = `https://api.vimeo.com${uri}/items?page=${page}&sort=date&direction=asc`;
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'  
      }
    });
    const pageData = response.data.data;
    const pages = response.data.paging.last;
    const lastPage = parseInt(pages.split('=')[1]);
    let allVideos:any = [];
    if (pageData.length > 0) {
      const pushdata = pageData.map((e: any) => {
        if (e.type === "video") {
          return {
            name: e.video.name,
            uri: e.video.uri,
            duration: e.video.duration,
            thumbnail: e?.video.pictures?.base_link,
            parent:e?.video?.parent_folder.name
          };
        }
        return null;
      }).filter((e: any) => e !== null);

      allVideos = allVideos.concat(pushdata);
    }
    if (page < lastPage) {
      const promises = [];
      for (let i = page + 1; i <= lastPage; i++) {
        promises.push(getVimeoUriData(uri, i));
      }
      const results = await Promise.all(promises);
      results.forEach(result => {
        allVideos = allVideos.concat(result);
      });
    }
    return allVideos;
  } catch (error:any) {
    return [];
  }
};
  const handleSubmits = ()=>{
    setModelLoading(true)
    if(selectData.length === 0 && selectedVideo.length === 0){
      return toast.error("Please select folder or video")
    }
    let newData:any = []
    selectData.map(async(item:any) => {
        let data:any  = await getVimeoUriData(item,1)
         newData.push(data)
    })
    
    
   
     if(modelData.length > 0){
      setModelData([...modelData,newData])
     }else{ 
      setModelData(newData)
     }
     setOpen(!open)
     setModelLoading(false)

  }
  const handlePagination = (uri:any)=>{
    if(selectData.length !== 0){ 
      return Swal.fire({
        title: "You have selected folder and video, are you sure want to reset them?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setSelectData([])
          const updatedVimeoPath = vimeoData.map((item:any) => ({
            ...item,
            selected: false
          }));
          setVimeoData(updatedVimeoPath)
        } 
      });
    }
      fetchDirs(uri)

  }

 


  return (
    <>
      <Helmet>
    <title>{`Vimeo Videos   | ${window.location.hostname}`}</title>
</Helmet>
    <BreadcrumbBack title="Manage Vimeo Videos" >
    <CustomButton
          title="Manage Vimeo Account"
          onClick={(()=>navigate("/admin/videos/vimeo/account"))}
        />
         {
          selectData.length > 0 ? 
          
             <button disabled={loading ? true : false} onClick={handleSubmits} className=' px-3 py-2 rounded-lg bg-purple-700 text-white'  >Import Videos</button>
         
          :selectedVideo.length > 0 ?
          <button disabled={loading ? true : false} onClick={handleSubmits} className=' px-3 py-2 rounded-lg bg-purple-700 text-white'  >Import Videos</button>

          :null
        }
    </BreadcrumbBack>
    {
      selectedVimeoAccount && (
        <div className='my-2 bg-gray-200 p-2 rounded-lg flex flex-col sm:flex-col md:flex-row  justify-between'>
          <div className='flex flex-row'>
            {
              vimeoPath.length>0?
                vimeoPath.map((e:any,index:number)=>( 
                  index === 0 ?
                  <p key={index} className={`${e.active ? 'text-dark' : 'text-gray-600'}  hover:cursor-pointer `} onClick={(()=>fetchDirs(1))} >{e.name}&nbsp;</p>
                  : index+1 === vimeoPath.length ?
                   <p key={index}  className={`${e.active ? 'text-dark' : 'text-gray-600'}  hover:cursor-pointer` } onClick={(()=>fetchMoreData(e?.uri,e.folders,e.name))}> / {e.name}&nbsp; </p>
                  : <p key={index}  className={`${e.active ? 'text-dark' : 'text-gray-600'}  hover:cursor-pointer`} onClick={(()=>fetchMoreData(e?.uri,e.folders,e.name))}>/  {e.name}&nbsp;</p>
                ))
              :<p>Root</p>
            }
          </div>
          <h1 className='text-xl font-bold flex flex-row gap-1 items-center'><FaUserCircle className='text-purple-600'/> <span className='text-purple-600'>{selectedVimeoAccount?.name}</span></h1>
        </div>
      )
    }
    
    {
      selectedVimeoAccount === undefined ?(
        <div className=' min-h-[50vh] my-2 w-full flex items-center justify-center flex-col '>
          <CiWarning className=' text-[4rem] text-yellow-700' />
          <p className='mb-10 text-2xl text-red-600 font-bold'>No Account Selected</p>
          <CustomButton
          onClick={(()=>navigate("/admin/videos/vimeo/account"))}
          title="Select Account"
        />
        </div>
      ):null
    }
      {
            modelLoading ? <Loaders type='spinner' /> : 
            <Models  name='Import Video' isOpen={open} isClose={setOpen}  >
              <AdminVimeoImport isOpen={setOpen} data={modelData}  />
            </Models>
           }
    <div>
      <div className="my-2">
        {
          videoLoading ? 
            <div>
              <Loaders type='spinner' />
            </div>
           : 
           <>
              <div className="grid grid-cols-12 gap-3">
        {
          vimeoData?.length > 0 ? 
            vimeoData.map((e:any,index:number) =>(
              <div key={index}  className='col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-3'>
               
                  <div className='w-full border border-gray-400 p-2 rounded-lg flex flex-col justify-center items-center relative'  >
                  <input checked={e.selected} onClick={(()=>handleCheckboxClick(e?.uri,e))} className=' items-start absolute left-2 top-1' type="checkbox" />{e.selected}
                  {
                    e.type === "folder" ? 
                      <div className=' ' onClick={(()=>fetchMoreData(e?.uri,e?.folders,e?.name))}>
                        
                        <Tooltip title={e?.name}>
                          <button>
                            
                        <div className='flex flex-col w-full'>
                       
                        <div className='flex justify-center w-full'>  
                        <img src={folderIcon} className='w-[128px] h-[128px]' alt="" />
                        </div>
                        <p>{truncateString(e?.name,25)}</p>
                        <div className='flex flex-row justify-between px-1  w-full'>
                          <div className='flex flex-row gap-2 items-center'>
                          <FaFolder className='text-yellow-500 ' /> <small>{e.folders}</small>
                          </div>
                          <div className='flex flex-row gap-2 items-center'>
                          <FaVideo className='text-blue-500 ' /> <small>{e.videos}</small>
                          </div>
                        </div>
                        </div>
                      </button>
                      </Tooltip>
                      </div>
                    :null
                  }
                   {
                    e.type === "video" ? 
                      <div className=''>
                        <Tooltip title={e?.name}>
                          <button>
                        <div className='flex flex-col'>
                       
                        <div className='flex justify-center'>
                        <img src={e?.thumbnail} className='w-[150px] h-[128px]' alt="" />
                        {/* <FaVideo /> */}
                        </div>
                        <p>{truncateString(e?.name ? e?.name : "video",25)}</p>
                        <div className='flex flex-row justify-between px-1'>
                          <div className='flex flex-row gap-2 items-center'>
                          <FaClock className='text-purple-500 ' /> <small>{e?.duration}</small>
                          </div>
                         
                        </div>
                        </div>
                      </button>
                      </Tooltip>
                      </div>
                    :null
                  }
                  </div>
              </div>
            ))
          :null
        }
        </div>
        {
        paging !== null ? 
        <div className='flex flex-row gap-2 justify-center items-center  my-3'>
        
          {
            paging.previous ?
            <TableButton
              onClick={(()=>handlePagination(paging.previous))}
              disabled = {paging.previous === -1 ? true :false}
              title="previous"
              toottip='previous'
              icon={true}
              />
            :null
          }
            {
              paging.pageCount.map((page:any, index:number) => 
                 (
                  (
                    <button 
                      onClick={(()=>handlePagination(page))}
                    className={`${page === paging.current ? 'bg-purple-800 text-white' : 'bg-gray-300 text-dark'} px-3 py-1 rounded-lg`}
                      key={index}
                      >
                        {page}
                      </button>
                  )
                 )
              )
            }
           {
            paging.next ?
            <TableButton
            onClick={(()=>handlePagination(paging.next))}
              title="next"
              toottip='next'
              icon={true}
              />
            :null
          } 
         
        </div>
        :null
      }
           </>
        }
        
      </div>
     
    </div>
  
   
    </>
  )
}
