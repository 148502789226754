import React from 'react'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid'
import { Helmet } from 'react-helmet-async'

export default function AdminInstallments() {
  return (
    <>
     <Helmet>
    <title>{` Installments    | ${window.location.hostname}`}</title>
</Helmet>
    <BreadcrumbBack title={`Installments`}>
   </BreadcrumbBack>

    <div className="my-2">
      <PaginationDataGrid  name="adminInstallments" /> 
    </div>
    </>
  )
}
