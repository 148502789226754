import React from 'react'
import NodataIcon from '../../assets/icons/no_Data.svg'
import { FaAlignLeft, FaAngleDoubleLeft, FaExclamation, FaExclamationTriangle } from 'react-icons/fa'
import { FaLeftLong } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
export default function DataNotFound(props:{title?:any}) {
  const navigate = useNavigate()
  return (
    <>
    <div className=' flex  items-center flex-row justify-center w-full  lg:min-h-[81vh] min-h-[86vh]  bg-gray-300'>
        <p className='text-2xl font-bold flex flex-col justify-center items-center  '>
        <FaExclamationTriangle className='text-[10rem] text-gray-400' />
          <p className=' text-gray-500'>
            {
              props.title? props.title : 'No Data Found'
            }
          </p>
          <button onClick={(()=>navigate("/student/dashboard"))} className='text-gray-200 py-1 mt-5 px-3 rounded-lg text bg-gray-500 flex  items-center gap-3'>
            <FaAngleDoubleLeft /> DashBoard
          </button>
                </p>
            </div>
    </>
  )
}
