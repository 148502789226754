import Tooltip from "@mui/material/Tooltip";
import React from "react";
import {
  FaAngleLeft,
  FaAngleRight,
  FaDownload,
  FaEdit,
  FaEye,
  FaFileExcel,
  FaFilePdf,
  FaKey,
  FaLink,
  FaPlay,
  FaPlus,
  FaRegFileAlt,
  FaRegFileExcel,
  FaRupeeSign,
  FaStar,
  FaTrash,
  FaUpload,
  FaUsers,
  FaVideo,
} from "react-icons/fa";
import { FaBookAtlas } from "react-icons/fa6";

export default function TableButton(props: {
  toottip?: string;
  title: string;
  text?: string;
  disabled?:boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon?: boolean;
  bg?:any
}) {
  return (
    <>
    {props.title === "file" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
           disabled={props.disabled ? true : false}
            className={`text-white bg-purple-700 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaRegFileAlt className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "rupee" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
           disabled={props.disabled ? true : false}
            className={`text-white bg-purple-700 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaRupeeSign className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "edit" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
             disabled={props.disabled ? true : false}
            className={`text-white bg-purple-700 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaEdit className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "create" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
             disabled={props.disabled ? true : false}
            className={`text-white bg-purple2 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaPlus className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "delete" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
             disabled={props.disabled ? true : false}
            className={`text-white bg-red-600 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaTrash className="text-xl p-[3px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "users" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
             disabled={props.disabled ? true : false}
            className={`text-white bg-blue-600 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaUsers className="text-xl p-[1px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "course" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
             disabled={props.disabled ? true : false}
            className={`text-white bg-gray-600 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaBookAtlas className="text-xl p-[3px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "excel" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
             disabled={props.disabled ? true : false}
            className={` border  border-green-600 dark:border-green-400 dark:text-green-400   flex flex-row gap-2 items-center dark:hover:bg-green-500
            dark:hover:text-white text-green-600 hover:bg-green-600 hover:text-white p-2 px-3 rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaFileExcel className="text-xl p-[1px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "download" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
             disabled={props.disabled ? true : false}
            className={`border ${props.bg ? props.bg : 'border-[#5D3FD3] hover:bg-[#5D3FD3] text-[#5D3FD3]'} hover:text-white   flex flex-row gap-2 items-center p-2 px-3 rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaDownload className="text-xl p-[1px]  " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "upload" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
             disabled={props.disabled ? true : false}
            className={` border  border-blue-600 text-blue-600 hover:text-white hover:bg-blue-600 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaUpload className="text-xl  p-[1px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
       {props.title === "play" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
             disabled={props.disabled ? true : false}
            className={`text-white bg-purple-500 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaPlay className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "previous" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
             disabled={props.disabled ? true : false}
            className={`text-white bg-purple-500 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaAngleLeft className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
       {props.title === "next" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
            disabled={props.disabled ? true : false}
            className={`text-white bg-purple-500 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaAngleRight className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "link" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
            disabled={props.disabled ? true : false}
            className={`text-white bg-purple-900 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaLink className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "video" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
            disabled={props.disabled ? true : false}
            className={`text-white bg-purple-900 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaVideo className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "pdf" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
            disabled={props.disabled ? true : false}
            className={`text-white bg-purple-900 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaFilePdf className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
       {props.title === "watch" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
            disabled={props.disabled ? true : false}
            className={`text-white bg-purple-900 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaEye className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
      {props.title === "key" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
            disabled={props.disabled ? true : false}
            className={`text-white bg-purple-900 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaKey className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
       {props.title === "start" ? (
        <Tooltip
          arrow
          title={`${props.toottip ? props.toottip : props.title + " button"}`}
        >
          <button
            disabled={props.disabled ? true : false}
            className={`text-white bg-purple-900 p-2 px-3  flex flex-row gap-2 items-center rounded-xl`}
            onClick={props.onClick}
          >
            {props.icon ? <FaStar className="text-xl p-[2px] " /> : null}
            {props.text ? props.text : null}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  );
}

