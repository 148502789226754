import axios from "axios";
import { testvimeoURL } from "../../service/admin/admin.service";


export const getVimeoTimeandThumbnail = async(videoId: any)=>{
    const response = await fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`);
    const data = await response.json();
    return [
        data.thumbnail_url,
        data.duration

    ]
}

export const getVideoInfo = (url: string) => {
    // YouTube regex to match video URLs
    const youtubeRegex = /(?:https?:\/\/(?:www\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    
    // Vimeo regex to match video URLs
    const vimeoRegex = /(?:https?:\/\/(?:www\.)?vimeo\.com\/(\d+)|https?:\/\/player\.vimeo\.com\/video\/(\d+))/;
  
    
    const youtubeMatch = url.match(youtubeRegex);
    const vimeoMatch = url.match(vimeoRegex);
  
    if (youtubeMatch) {
      return {
        platform: "YouTube",
        videoId: youtubeMatch[1],
      };
    }
  
    if (vimeoMatch) {
      return {
        platform: "Vimeo",
        videoId: vimeoMatch[1] || vimeoMatch[2],
      };
    }
  
    return { platform: "Unknown", videoId: null };
  };

  export const fetchVimeoVideoHLS = async (videoId:any) => {
    try{
      const get:any = await testvimeoURL(videoId);
      if(get?.status === "success"){
        return get?.message
      }
      return null;
    }catch(e:any){
      return  false;
    }
  };