import React, { useEffect, useState } from 'react'
import { getStudentVideosCourses, getStudentVideosCoursesModule } from '../../../service/student/student.service'
import Loaders from '../../../components/loaders/Loaders'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import { FaEye, FaFolder, FaViadeoSquare, FaVideo } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaStopwatch } from 'react-icons/fa6'
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb'
import { BreadCrumRoutes } from '../../../helpers/helpers'
import DataNotFound from '../../../components/errors/DataNotFound'
import { Helmet } from 'react-helmet-async'

export default function StudentVideoModules() {

    const [pageLoad, setPageLoad] = useState(false)
    const [fetchData, setFetchData] = useState<any>([])
    const [bradCrumData, setBreadcrumbData] = useState<any>()
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate()
    const fetchVideos = async(id:string)=>{
        setPageLoad(true)
        let get:any = await getStudentVideosCoursesModule(id)
        if(get?.status==="success"){
            setPageLoad(false)
            setFetchData(get?.message)
        }
        setPageLoad(false)
    }

    useEffect(()=>{
        setBreadcrumbData(BreadCrumRoutes("Module",'/student/videos/modules/'+location.pathname.split("/")[location.pathname.split("/").length-1],"studentVideoModule",false))
        fetchVideos(location.pathname.split("/")[location.pathname.split("/").length-1])
    },[])

  return (
    pageLoad ? 
    <Loaders type="spinner" />
    :
    <>
     <Helmet>
    <title>{`Videos | ${window.location.hostname}`}</title>
</Helmet>
     <BreadCrumb routes={bradCrumData}  />
     <div className='my-2 grid grid-cols-12 gap-3'>
        {
            fetchData?.length > 0 ?
                fetchData.map((item:any, index:number)=>(
                    <div onClick={(()=>navigate(`/student/videos/modules/${item.id}/submodules`))}  key={index} className=' hover:cursor-pointer lg:col-span-3 col-span-12 sm:col-span-12 md:col-span-4   bg-gray-200 rounded-xl'>
                        <div className='flex flex-col  gap-2 border-l-4 rounded-lg p-3 border-purple-600'>     
                                <p className='text-2xl text-center font-semibold'>{item?.name}</p>
                                    <div className=' flex gap-3 px-3 mt-3 justify-between'>
                                        <p className='flex gap-2  items-center'>
                                            <FaVideo className='text-purple-700' /> {item?.watch}/{item?.totalVideo}
                                        </p>
                                        <p className='flex gap-2  items-center'>
                                            <FaFolder className='text-purple-700' /> {item?.totalDir}
                                        </p>
                                    </div>
                                <button onClick={(()=>navigate(`/student/videos/modules/${item.id}/submodules`))} className='px-4 w-full py-2 bg-purple-700 text-white rounded-lg'>
                                    Watch Video
                                </button>
                        </div>
                    </div>
                ))
            :
            <div className="col-span-12">
            <DataNotFound title="No Test Series Found" />
        </div>
 
        }
     </div>
    </>
  )
}
