import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import {  FaBookmark, FaPlus, FaSignInAlt, FaStar, FaTrash, FaVimeo } from 'react-icons/fa'
import { FaFolderTree } from 'react-icons/fa6'
import { PiBank } from 'react-icons/pi'

export default function CustomButton(props:{
    icon?:boolean,
    iconName?:string,
    title:string,
    loading?:boolean,
    bg?:string,
    onClick?:(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    
}) {
  return (
    <button onClick={props.onClick} disabled={props.loading} className={`${props.loading ? props.bg ? props.bg : 'hover-purple-gradient' :  props.bg ? props.bg : 'hover-purple-gradient'} px-3 py-2 rounded-xl  text-white flex gap-2 items-center  justify-center`}>
       {props.loading ?
        <CircularProgress sx={{
          color:"white"
        }} size={16}  /> : 
         props.icon ?
         <>
         {props.iconName === "Login" ?  <FaSignInAlt /> : null}
         {props.iconName === 'create' ?   <FaPlus /> : null}
         {props.iconName === 'delete' ? <FaTrash/> : null}
         {props.iconName === 'vimeo' ? <FaVimeo/> : null}
         {props.iconName === 'folderTree' ? <FaFolderTree/> : null}
         {props.iconName === 'bank' ? <PiBank/> : null}
         {props.iconName === 'star' ? <FaStar/> : null}
         {props.iconName === 'bookMark' ? <FaBookmark/> : null}
         </> 
         
            :null    
        }
          {props.title}
    </button>
  )
}
