import React, { useEffect, useState } from 'react'
import { BreadCrumRoutes } from '../../../helpers/helpers'
import Loaders from '../../../components/loaders/Loaders'
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb'
import Models from '../../../components/models/Models'
import StudentExamAlertCE from './StudentExamAlertCE'
import { StudentExamAlertSubject, StudentExamAlertSubjectDelete } from '../../../service/student/student.service'
import { FaDownload, FaPlus, FaTimesCircle, FaTrash } from 'react-icons/fa'
import TableButton from '../../../components/button/TableButton'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import contentUpdateSlice from '../../../redux/contentUpdateSlice'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { Helmet } from 'react-helmet-async'
import { Tooltip } from '@mui/material'
import Chart from "react-apexcharts";

export default function StudentExamAlert() {
    
    const [breadCrumData, setBreadCrumData] = useState<any>()
    const [pageLoad, setPageLoad] = useState<any>(false)
    const [open, setOpen] = useState(false)
    const [fetchData, setFetchData] = useState([])
    const fetchSubject = async()=>{
        setPageLoad(true)
        let get:any = await StudentExamAlertSubject()
        if(get?.status === "success"){
            setPageLoad(false)
            setFetchData(get?.message)
        }
        setPageLoad(false)
    }
    const contentUpdate = useSelector((state:any) => state.contentUpdateSlice)
    useEffect(()=>{
        fetchSubject()
        setBreadCrumData(BreadCrumRoutes("Exam Alert",'/student/exam-alert',"studentExamAlert",true))
    },[contentUpdate])
    const handleDelete = async(id:string)=>{
        Swal.fire({
            title: 'Are you sure want to delete ?',
            icon: "warning",
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: async() => {
                let del:any = await StudentExamAlertSubjectDelete(id)
                if(del?.status === "success"){
                    fetchSubject()
                return toast.success(del?.message)
                }
              return false;
            }
          })
      
    }
    const downloadPDF = (url:string) => {
        const link = document.createElement("a");
        link.href = url; // URL of your PDF
        link.download = "sample.pdf"; // Name of the downloaded file
        link.click();
      };

     const optionsRadial:any =  {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -20,
                show: true,
                color: "#888",
                fontSize: "13px"
              },
              value: {
                formatter: function (val:any) {
                  return val;
                },
                color: "#111",
                fontSize: "30px",
                show: true
              }
            }
          }
        },
        fill: {
            colors: ['#4c1d95']
        },
        stroke: {
          lineCap: "round"
        },
        labels: ["Remaining Days"]
      };
     const seriesRadial = [50];


  return (
    pageLoad ? 
    <Loaders type='spinner' />
    :
    <>
     <Helmet>
    <title>{`Exam Alert | ${window.location.hostname}`}</title>
</Helmet>
    <BreadCrumb routes={breadCrumData}  />
        <div className='my-2 bg-gray-300 rounded-lg flex items-center justify-between p-3'>
            <p className='text-xl font-bold'>Exam Alerts</p>
            <button onClick={(()=>setOpen(!open))} className='px-3 py-2 bg-purple-800 text-white flex gap-2 items-center  rounded-lg'>
               <FaPlus /> Create New Alert
            </button>
        </div>
        <div className="my-3">
            <div className='grid grid-cols-12 gap-2'>
               
                {
            fetchData.length > 0 ?
                fetchData.map((row:any,index:number) =>(
                    moment(row?.exam_date).diff(moment(), 'days') > 0 && 
                    <div key={index} className={`col-span-12 sm:col-span-12 md:col-span-4 w-full bg-white hover:cursor-pointer  p-2`}>
                        <div className="flex flex-wrap justify-between items-center">
                            <div>
                            <p className='text-xl font-bold'>{row?.examAlertSubject?.name}</p>
                            </div>
                            <div>
                            <Tooltip title="Remove Exam">
                    <button
                    onClick={(()=>handleDelete(row?.id))}
                      type="button"
                    >
                      <FaTimesCircle className=" text-2xl  text-red-600  hover:cursor-pointer" />
                    </button>
                  </Tooltip>
                            </div>
                        </div>
                        <div className=" flex items-center   justify-center  flex-col  ">
                        <Chart
                        options={optionsRadial}
                        series={[moment(row?.exam_date).diff(moment(), 'days')]}
                        type="radialBar"
                        width="280"
                        />
                        
                        </div>
                            {
                            row?.examAlertSubject?.pdf !== null ? 
                            <div className="grid grid-cols-12 ">
                            <div className="col-span-6 bg-purple-400 pb-2 ">
                            <small className='text-white font-bold pl-2 '>Exam Date </small>
                                <p className='text-white font-bold  text-center text-xl mt-1'>{moment(row?.exam_date).format("YYYY-MM-DD")}</p>
                            </div>
                            <div className="col-span-6 bg-purple-600 pb-2 ">
                            <small className='text-white font-bold pl-2'>Content</small>
                          <p className='text-white font-bold flex justify-center  text-center text-xl'>
                          <button className='flex gap-2 bg-purple-700 px-3 py-2 rounded-xl items-center'><FaDownload /> Download</button>
                          </p>
                            </div>
                        </div>
                            : <div className="grid grid-cols-12 ">
                            <div className="col-span-12 bg-purple-400 pb-2 ">
                            <small className='text-white font-bold pl-2 '>Exam Date </small>
                                <p className='text-white font-bold  text-center text-xl mt-1'>{moment(row?.exam_date).format("YYYY-MM-DD")}</p>
                            </div>
                           
                        </div>
                           }
                        
                    </div>
                ))
            :null
         }
                </div>
            </div>
        <Models name='Create New Center' isOpen={open} isClose={setOpen}  >
        <StudentExamAlertCE  open={setOpen} />
    </Models>
    </>
  )
}
