import { StudentTypes } from "../../types/StudentTypes";
import instance from "../instace";



export const getStudentCourseMaster = async()=>{
    try{
        const get:any = await instance.get(`/api/student/course/masters`)
        return get.data;
    }catch(e){
            return false;
    }
}
export const getStudentMasterCourses = async(slug:string)=>{
    try{
        const get:any = await instance.get(`/api/student/course/masters/courses/${slug}`)
        return get.data;
    }catch(e){
            return false;
    }
}
export const getStudentCourseDetails = async(slug:string)=>{
    try{
        const get:any = await instance.get(`/api/student/course/masters/courses/detail/${slug}`)
        return get.data;
    }catch(e){
            return false;
    }
}
export const freeStudentSubscribe = async(courseId:string)=>{
    try{
        const get:any = await instance.post(`/api/student/course/subscribe/free`,{
            course:courseId
        })
        return get.data;
    }catch(e){
            return false;
    }
}

export const getStudentSubscription = async()=>{
    try{
        const get:any = await instance.get(`/api/student/course/subscription`)
        return get.data;
    }catch(e){
            return false;
    }
}

export const getStudentSubscriptionPaymentHistory = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/student/course/subscription/payment/${id}`)
        return get.data;
    }catch(e){
            return false;
    } 
}
export const getCourseVideosList = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/student/course/video-list/${id}`)
        return get.data;
    }catch(e){
            return false;
    } 
}
export const getCourseTestSeriesList = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/student/course/test-series-list/${id}`)
        return get.data;
    }catch(e){
            return false;
    } 
}

export const getCoursePaymentMethods = async()=>{
    try{
        const get:any = await instance.get(`/api/student/course/payments/methods`)
        return get.data;
    }catch(e){
            return false;
    } 
}
export const getCourseBasicDetails = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/student/course/basic-detail/${id}`)
        return get.data;
    }catch(e){
            return false;
    } 
}
export const applyCouponCodeCourse = async(coupon:string,course:string)=>{
    try{
        const get:any = await instance.get(`/api/student/course/apply-coupon/${coupon}/${course}`)
        return get.data;
    }catch(e){
            return false;
    } 
}

export const studentmakePayment = async(coupon:string,course:string,method:string,img?:any)=>{
    try{
        const get:any = await instance.post(`/api/student/course/make-payment`,{
            coupon,
            course,
            method,
            img
        })
        return get.data;
    }catch(e){
            return false;
    } 
}

export const studentmakePaymentStatus = async(txtId:string,method:string)=>{
    try{
        const get:any = await instance.post(`/api/student/course/make-payment/status/${method}/${txtId}`)
        return get.data;
    }catch(e){
            return false;
    } 
}

export const studentCourseHoldPayments = async()=>{
    try{
        const get:any = await instance.get(`/api/student/course/payment/hold`)
        return get.data;
    }catch(e){
            return false;
    } 
}
