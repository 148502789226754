import React, { useEffect, useState } from 'react'
import BreadcrumbBack from '../../../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../../../components/button/CustomButton'
import { useLocation, useNavigate } from 'react-router-dom';
import Models from '../../../../../components/models/Models';
import AdminGroupTestSeriesCE from './AdminGroupTestSeriesCE';
import { randomNumber } from '../../../../../helpers/helpers';
import PaginationDataGrid from '../../../../../components/tables/PaginationDataGrid';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

export default function AdminGroupTestSeries() {
    
    const [open, setOpen] = useState(false);
        const location = useLocation();
        const query = new URLSearchParams(location.search)
        const [moduleType, setModuleType] = useState<any>()
        const [change, setChange] = useState<any>(null)
        const navigate = useNavigate()
        let moduleId = location.pathname.split("/")[4]
        let subMOd = query.get("md")
       useEffect(() => {
        if(query.get('cat') === "nmod"){
          setModuleType("nmod")
          setChange(randomNumber(100))
        }else{
          setModuleType("smod")
        }
       },[setModuleType,location,moduleType,setChange])
  return (
   <>
    <Helmet>
    <title>{`Group Test Series   | ${window.location.hostname}`}</title>
</Helmet>
   <BreadcrumbBack title={`${query.get('bredcrum')} Group Test Series `}>
    <CustomButton
        onClick={(()=>setOpen(!open))}
    title="Create Group Sub Module"
    icon={true}
    iconName="create"
    />
   </BreadcrumbBack>
   <div className='my-2'>
   <div className="my-2">
      {
        change ? 
          moduleType !== "smod" ?
          <PaginationDataGrid name="adminTestSeriesNSubModules" otherId={[query.get('md'),query.get('cat')]} dataObject="group" dataId={subMOd} id={query.get('md')} />
          :null
        :null

      }
      {
        moduleType === "smod" ? 
        <PaginationDataGrid name="adminTestSeriesSubModules" otherId={[query.get('md'),query.get('cat')]}  dataObject="group" dataId={subMOd} />
        :null
      }
      </div>
   </div>
   <Models  name="Create Test Series Sub Module" isOpen={open} isClose={setOpen}  >
      <AdminGroupTestSeriesCE category={moduleType} type='create' open={setOpen} data={{mod:moduleId,submod:subMOd}  } />
   </Models>

    </>
  )
}
