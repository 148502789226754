import React, { useEffect, useState } from 'react'
import BreadcrumbBack from '../../../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../../../components/button/CustomButton'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PaginationDataGrid from '../../../../../components/tables/PaginationDataGrid'
import Models from '../../../../../components/models/Models'
import { showAdminVideosModule } from '../../../../../service/admin/adminVideoes.service'
import VideoSubModuleCE from '../../videoSubModules/VideoSubModuleCE'
import { Helmet } from 'react-helmet-async'

export default function VimeoSubModules() {
    const [open,setOpen] = useState(false)
    const [moduleDate,setModuleDate] = useState<any>()
    const [modelData,setModelData] = useState<any>()

    const location = useLocation();
    let moduleId = location.pathname.split('/')[location.pathname.split('/').length - 1];
    let moduleName = new URLSearchParams(location.search)
    useEffect(()=>{
        setModelData({
            module:moduleId
        })
    },[])

  return (
    <>
      <Helmet>
    <title>{` Videos   | ${window.location.hostname}`}</title>
</Helmet>
    <BreadcrumbBack title={`${(moduleName.get("name"))}'s SubModules`}>
        <CustomButton
                onClick={(()=>setOpen(!open))}
          title="Create New SubModule"
          icon={true}
          iconName="create"
        />
    </BreadcrumbBack>

    <div className="my-2">
        <PaginationDataGrid name="AdminVideoModuleSubModules" dataId={moduleId} />
    </div>
    <Models  name="Create New SubModule" isOpen={open} isClose={setOpen}  >
    <VideoSubModuleCE type='create' isOpen={setOpen} data={modelData}  />
    </Models>
    </>
  )
}
