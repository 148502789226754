import React from 'react'
import { FaExclamation, FaHome } from 'react-icons/fa'

export default function PluginError() {
    const handleBack = () => {
        window.location.href = '/student/dashboard'
    }

  return (
    <div className='h-screen w-full flex items-center justify-center flex-col bg-red-400'>
        <div className='text-center flex items-center justify-center flex-col gap-5'>
            <FaExclamation className='text-gray-100 text-[6rem]' />
            <p className='text-gray-100 text-3xl font-bold'> An external plugin has been detected, <br/> kindly  deactivate or uninstalle It.</p>
            <button onClick={handleBack} className='px-5 py-3 bg-purple-600 text-white text-2xl flex gap-2 items-center rounded-2xl'>
                Go Home
            </button>
        </div>
    </div>
  )
}
