import React, { useEffect, useState } from 'react'
import { createAdminPaymethodMethodMaster, getPaymethodMethodMaster } from '../../../service/admin/adminpayments.service';
import Loaders from '../../../components/loaders/Loaders';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';


export default function AdminPaymentMaster(props:{open:any}) {

    const [fetchData, setFetchData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchApi = async()=>{
        setLoading(true)
        const get:any = await getPaymethodMethodMaster();
        if(get?.status === "success"){
            setLoading(false)
            setFetchData(get?.message);
        }
        setLoading(false)
    }
    useEffect(()=>{
        fetchApi()
    },[])
    const handleAdd = async(id:string,name:string)=>{
        Swal.fire({
            icon:"question",
            title: `Do you want to Add <br /> ${name}`,
            showDenyButton: true,
            confirmButtonText: "Yes",
          }).then(async(result) => {
            let cr:any = await createAdminPaymethodMethodMaster(id,name,true)
            if(cr.status === "success"){
                props.open(false)
               return toast.success(cr.message)
            }
           
          });
    }
   
  return (
    loading ? 
    <Loaders type='spinner' />
    :
    <>
        <div className="relative overflow-x-auto rounded-lg  max-h-[300px] min-h-[100px]">
    <table className="w-full   text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs rounded-lg text-gray-200 uppercase  bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
            <tr>
            <th scope="col" className="py-2 px-2">S.no</th>
            <th scope="col" className="py-2 px-2">Name</th>
            
            <th scope="col" className="py-2 px-2"></th>
            </tr>
        </thead>
        <tbody>
         {
            fetchData.length > 0 ?
                fetchData.map((row:any,index:number) =>(
                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 text-center even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td className='px-2 py-2 text-center'>
                            {index+1}
                        </td>
                        <td className='px-2 py-2 text-center'>
                            <p className='flex flex-row gap-2 items-center'>
                               <img src={row.icon} className='w-[30px] h-[30px] rounded-full ' alt="" />
                               <b>{row.name}</b>
                            </p>
                        </td>
                        
                        <td className='px-2 py-2 text-center'>
                         {
                            row._count.admin_payment_master == 0 ?
                                <button onClick={(()=>handleAdd(row.id,row.name))} className=' bg-purple-900 text-white font-bold  px-5 py-2  rounded-full'>Add</button>
                            :
                            <button disabled={true} className=' bg-green-600 text-white font-bold  px-5 py-2  rounded-full'>Already Added</button>

                             
                         }
                        </td>
                    </tr>
                ))
            :null
         }
        </tbody>
    </table>
</div>
    </>
  )
}
