import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { StudentExamAlertSubject } from '../../../types/StudentTypes';
import SearchableSelect from '../../../components/form/SearchableSelect';
import formValidationRules from '../../../helpers/formvalidationRules';
import FormButton from '../../../components/button/FormButton';
import Input from '../../../components/form/Input';
import { StudentExamAlertSubjectCreate, StudentExamAlertSubjectList } from '../../../service/student/student.service';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setContentUpdate } from '../../../redux/contentUpdateSlice';

export default function StudentExamAlertCE(props:{open:any}) {
    const [loading, setLoading] = useState(false);
    const [subjectList, setSubjectList] = useState([])
    const [selectedSubject, setSelectedSubject] = useState<any>()
    const [selectedSubjectDate, setSelectedSubjectDate] = useState()
    const { handleSubmit, control } = useForm<any>({
        values:{
            date:selectedSubjectDate,
            subject:selectedSubject

        }
    });
    const dispatch = useDispatch()
    const onSubmit: SubmitHandler<any> = async(data:any) => {
        setLoading(true);
        console.log(data)
        let send:any = await StudentExamAlertSubjectCreate(data.subject, data.date)
        if(send?.status === "success"){
            setLoading(false);
            props.open(false)
            dispatch(setContentUpdate({
                id:Math.random() * (100000 - 111111)
              }))
            return toast.success(send?.message)
        }
        setLoading(false);
    };
    const fetchSubject = async()=>{
        let get:any = await StudentExamAlertSubjectList();
        let data:any = [];
          get.message.map((item:any)=>{
            data.push({value:item.id,label:`${item.name} -  ${moment(item?.exam_date).format("YYYY-MM-DD")}`})
          })
          setSubjectList(data)
    }
    useEffect(() => {
        fetchSubject();
       
    }, [])
    const handleSelect = (e:any)=>{
        let dt:any = `${e.label.split('-')[1]}-${e.label.split('-')[2]}-${e.label.split('-')[3]}`
        dt = dt.replace(/\s+/g, '');
        setSelectedSubjectDate(dt)
        setSelectedSubject(e.value)
    }   
  return (
    <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>
                 <SearchableSelect
                name="subject"
                control={control}
                options={subjectList}
                rules={formValidationRules.required()}
                onFetch={handleSelect}
                label="Select subject"
                placeholder="subject"
      />

       <Input 
       name="date"
       type='date'
       defaultValue={selectedSubjectDate}
       control={control}
       rules={{
        ...formValidationRules.required(),
        ...formValidationRules.minLength(3)
       }}
       label="Date"
       placeholder="Name"
      />
        <br/>
        <FormButton loading={loading} title={"Create"} />

    </form>
  )
}
