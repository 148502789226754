import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getStudentVideosPlay, studentVideoWatchEvent } from '../../../service/student/student.service'
import Loaders from '../../../components/loaders/Loaders'
import { BreadCrumRoutes, containsHTMLTags, truncateString } from '../../../helpers/helpers'
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb'
import Cookies from 'js-cookie'
import DataNotFound from '../../../components/errors/DataNotFound'
import { Helmet } from 'react-helmet-async'
import Swal from 'sweetalert2'
import VimeoPlayer2 from '../../../components/player/VimeoPlayer2'
import { vimeoHSLSecureURL } from '../../../service/admin/adminVimeo.service'
import HlsPlayer from '../../../components/player/HlsPlayer'

export default function StudentVideoPlay() {

  const [pageLoad, setPageLoad] = useState(false)
  const [fetchData, setFetchData] = useState<any>()
  const [screenWidth, setScreenWidth] = useState(0);
  const [url, setUrl] = useState<any>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const fetchVideos = async(id:string)=>{
      setPageLoad(true)
      let routes:any =  JSON.parse(Cookies.get('routes')!)
      let courseId:any = routes[1].link?.split('/')[4]
      let get:any = await getStudentVideosPlay(id,courseId)
      if(get?.status==="success"){
          if(get?.message?.videoType?.name === "Vimeo"){
              let ids = get?.message?.url.split('/')[get?.message?.url.split('/').length -1];
              let newurl = await vimeoHSLSecureURL(ids,get?.message?.fk_account_id)
              if(!newurl){
                navigate(-1)
              }
              setUrl(newurl)
              setPageLoad(false)
          }else{
            setUrl(get?.message?.url)
            setPageLoad(false)
          }
          setFetchData(get?.message)
          setPageLoad(false)
      }else{
        Swal.fire({
          title: get?.message,
          confirmButtonText: "ok",
        }).then((result) => {
          navigate(-1)
        });
       
      }
      setPageLoad(false)
  }
  const fetchWatchTime = async(id:string,time:any)=>{
    let routes:any =  JSON.parse(Cookies.get('routes')!)
    let courseId:any = routes[1].link?.split('/')[4]
    const get:any = await studentVideoWatchEvent(id,time,courseId)
    if(get?.status === "success"){
      // console.log(get?.message)
    }
  }
  useEffect(()=>{
      fetchVideos(location.pathname.split("/")[4])
  },[])
  const handleWatchTime = (data:any)=>{
    fetchWatchTime(location.pathname.split("/")[4],data)
  }

 

  
  let getR = BreadCrumRoutes("Video",'/student/videos/play/'+location.pathname.split('/')[3],"studentVideoPLay",false)
  return (
    pageLoad ? 
    <Loaders type="spinner" />
    :
    <>
     <Helmet>
    <title>{` ${fetchData?.name} | ${window.location.hostname}`}</title>
</Helmet>
    <BreadCrumb routes={getR}  />
     
        {
            fetchData ?
                containsHTMLTags(fetchData?.description)  === true ? 
                    <div className=' grid grid-cols-12 gap-2  p-3'>
                      <div className="col-span-12 sm:col-span-12 md:col-span-7 lg:col-span-7 w-full">
                        <div className=''>
                        {
                          fetchData.videoType?.name === "Vimeo" && 
                            <VimeoPlayer2 type={fetchData.videoType?.name} data={fetchData.fk_account_id} lastWatch={fetchData?.studentVideoWatch[0]?.last_watch} watchTime={handleWatchTime}  url={url} />
                            // <HlsPlayer data={fetchData.fk_account_id} playlistUrl={url} />
                        }
                        {
                          fetchData.videoType?.name === "Youtube" && 
                            <VimeoPlayer2 type={fetchData.videoType?.name}  lastWatch={fetchData?.studentVideoWatch[0]?.last_watch} watchTime={handleWatchTime}  url={url}  />
                        }
                        </div>
                      </div>
                      <div className="col-span-12 sm:col-span-12 md:col-span-5 lg:col-span-5">
                        <div className='bg-gray-300 p-2 rounded-lg'>
                          <p className='text-purple-500 font-bold text-xl'>Overview</p>
                          <div className="my-2">
                               { <div dangerouslySetInnerHTML={{ __html: fetchData?.description }} /> }
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                      <div className='w-full my-4'>
                        {
                          fetchData.videoType?.name === "Vimeo" && 
                            <VimeoPlayer2 type={fetchData.videoType?.name} data={fetchData.fk_account_id}  lastWatch={fetchData?.studentVideoWatch[0]?.last_watch} watchTime={handleWatchTime}  url={url} />
                            // <HlsPlayer data={fetchData.fk_account_id} playlistUrl={url} />
                        }
                        {
                          fetchData.videoType?.name === "Youtube" && 
                            <VimeoPlayer2 type={fetchData.videoType?.name} lastWatch={fetchData?.studentVideoWatch[0]?.last_watch} watchTime={handleWatchTime}  url={url}  />
                        }
                        </div>
            :
            <div className="col-span-12">
              <p>loading..</p>
            {/* <DataNotFound title={fetchData} /> */}
        </div>
 
        }
     
    </>
  )
}
