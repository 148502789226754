import { AdminStudentTypes, BatchStuSubsciptionTypes, studentPaymentTypes } from "../../types/AdminTypes";
import instance from "../instace";

export const createAdminStudent = async(data:AdminStudentTypes)=>{
    try{
        const get:any = await instance.post('/api/admin/student/create',{
           name:data.name,
           email:data.email,
           phone:data.phone,
           dob:data.dob,
           status:data.status,
           gender_id:data.gender_id,
        })
        return get.data;
     }catch(e){
        return false;
    }
}
export const getAdminStudents = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/student/get?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const getAdminPaidStudents = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/student/get/paid?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const getAdminFreeStudents = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/student/get/free?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showAdminStudent = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/student/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAdminStudent = async(id:string,data:AdminStudentTypes)=>{
    try{
        const get:any = await instance.put(`/api/admin/student/edit/${id}`,{
            name:data.name,
           email:data.email,
           phone:data.phone,
           dob:data.dob,
           status:data.status,
           gender_id:data.gender_id,
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const updateStatusAdminStudent = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/student/status/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteAdminStudent = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/student/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const getAllCenterStudents = async()=>{
    try{
       
        const get:any = await instance.get(`/api/admin/student/all/downloads`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const getPaidCenterStudents = async()=>{
    try{
       
        const get:any = await instance.get(`/api/admin/student/paid/downloads`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const getFreeCenterStudents = async()=>{
    try{
       
        const get:any = await instance.get(`/api/admin/student/free/downloads`)
        return get.data;
    }catch(e){
        return false;
    }
}

export const createSubscription = async(data:BatchStuSubsciptionTypes)=>{
    try{
        const get:any = await instance.post(`/api/admin/subscription/create`,{
            fk_student_id:data.fk_student_id,
            fk_course_id:data.fk_course_id,
            exp_date:data.exp_date,
            total_amount:data.total_amount,
            inst_amount:data.inst_amount,
            pending_amount:data.pending_amount,
            complete:data.complete
        })
        return get.data;
     }catch(e){
        return false;
    }
}

export const getpaymentsHistory = async(courseId:string,studentid:string)=>{
    try{
       
        const get:any = await instance.get(`/api/admin/subscription/get/payemts/history/${courseId}/${studentid}`)
        return get.data;
    }catch(e){
        return false;
    }
}

export const createInvocePayments = async(data:studentPaymentTypes)=>{
    try{

     const get:any = await instance.post(`/api/admin/subscription/payment/emi/create`,{
        fk_subscription_id:data.fk_subscription_id,
        exp_date:data.exp_date,
        inst_amount:data.inst_amount,
        pending_amount:data.pending_amount,
        complete:data.complete,
     })
        return get.data;
    }catch(e){
        return false;
    }
}
export const getStudentCourses = async(stu:string)=>{
    try{
        
        const get:any = await instance.get(`/api/admin/student/user-subscription/${stu}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const adminResetStudentPassword = async(stu:string)=>{
    try{
        
        const get:any = await instance.put(`/api/admin/student/reset-password/${stu}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const getAdminStudentSubjects = async(stu:string)=>{
    try{

     const get:any = await instance.get(`/api/admin/student/profile/subjects/${stu}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const getAdminStudentSubjectsTest = async(stu:string,subject:string)=>{
    try{

     const get:any = await instance.get(`/api/admin/student/profile/subjects/test-series/${stu}/${subject}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const getAdminStudentSubjectsVideo = async(stu:string,subject:string)=>{
    try{

     const get:any = await instance.get(`/api/admin/student/profile/subjects/video/${stu}/${subject}`)
        return get.data;
    }catch(e){
        return false;
    }
}