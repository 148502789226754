import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getStudentVideosList } from '../../../service/student/student.service'
import Loaders from '../../../components/loaders/Loaders'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import { BreadCrumRoutes, truncateString } from '../../../helpers/helpers'
import { FaPlay, FaStopwatch } from 'react-icons/fa'
import moment from 'moment'
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb'
import DataNotFound from '../../../components/errors/DataNotFound'
import { Helmet } from 'react-helmet-async'
import Cookies from 'js-cookie'

export default function StudentVideosList() {

    const [pageLoad, setPageLoad] = useState(false)
    const [fetchData, setFetchData] = useState<any>([])
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const navigate = useNavigate()
    const fetchVideos = async(id:string,sub:string)=>{
        setPageLoad(true)
        let routes:any =  JSON.parse(Cookies.get('routes')!)
        let courseId:any = routes[1].link?.split('/')[4]
        let get:any = await getStudentVideosList(id,sub,courseId)
        if(get?.status==="success"){
            setPageLoad(false)
            setFetchData(get?.message)
        }
        setPageLoad(false)
    }

    useEffect(()=>{
        fetchVideos(location.pathname.split("/")[4],location.pathname.split("/")[6])
    },[])
    let getR = BreadCrumRoutes("Videos",'/student/videos/modules/'+location.pathname.split('/')[4]+'/submodules/'+location.pathname.split('/')[6],"studentVideoModuleSubVideos",false)
  return (
    pageLoad ? 
    <Loaders type="spinner" />
    :

    <>
     <Helmet>
    <title>{`Videos List | ${window.location.hostname}`}</title>
</Helmet>
     <BreadCrumb routes={getR}  />
     <div className='my-2 grid grid-cols-12 gap-3'>
        {
            fetchData?.length > 0 ?
                fetchData.map((item:any, index:number)=>(
                    <div onClick={(()=>navigate(`/student/videos/play/${item.id}`))}  key={index} className='lg:col-span-3 col-span-12 sm:col-span-12 md:col-span-4 hover:cursor-pointer  my-3 bg-gray-200 rounded-xl'>
                        <div className='flex flex-col  gap-2 border-l-4 relative rounded-lg  '>
                        <div className='relative w-full'>
                            <FaPlay  className=' text-[2rem] absolute top-[40%] right-[50%] z-10 text-purple-400' />
                        <img src={item?.thumbnail} className='h-[200px] w-full rounded-lg brightness-50' alt="" />   
                        <div className="w-full absolute bottom-0  rounded-full h-1 bg-gray-400 dark:bg-gray-700">
                        <div className="bg-blue-600 h-1 rounded-full " style={{width:`${item?.lastWatch ? item.lastWatch > 100 ? 100 : item?.lastWatch : 0}%`}}></div>
                        </div>  
                        </div> 
                        
                                <p className='text-lg text-center font-semibold'>{truncateString(item?.name,25)}</p>
                                <p className='flex gap-2  justify-center items-center'>
                                            <FaStopwatch className='text-purple-700' /> {moment.utc(item?.length * 1000).format('HH:mm:ss')}
                                        </p>
                                {/* <button onClick={(()=>navigate(`/student/videos/play/${item.id}`))} className='px-4 w-full py-2 bg-purple-700 text-white rounded-lg'>
                                    Watch
                                </button> */}
                        </div>
                    </div>
                ))
            :
            <div className="col-span-12">
            <DataNotFound title="No Test Series Found" />
        </div>
 
        }
     </div>
    </>
  )
}
