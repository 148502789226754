import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getStudentVideosCoursesModuleSubModules } from '../../../service/student/student.service'
import { FaStopwatch, FaVideo } from 'react-icons/fa'
import Loaders from '../../../components/loaders/Loaders'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import moment from 'moment'
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb'
import { BreadCrumRoutes } from '../../../helpers/helpers'
import DataNotFound from '../../../components/errors/DataNotFound'
import { Helmet } from 'react-helmet-async'

export default function StudentVideoSubModules() {
    const [pageLoad, setPageLoad] = useState(false)
    const [fetchData, setFetchData] = useState<any>([])
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const navigate = useNavigate()
    const fetch = async(id:string)=>{
        setPageLoad(true)
        const get:any = await getStudentVideosCoursesModuleSubModules(id)
        if(get?.status === "success"){
            setPageLoad(false)
            setFetchData(get?.message)
        }
        setPageLoad(false)
    }
    useEffect(()=>{
        fetch(location.pathname.split('/')[4])
    },[])
    
    let getR = BreadCrumRoutes("Submodule",'/student/videos/modules/'+location.pathname.split('/')[4]+'/submodules',"studentVideoModuleSub",false)
  return (
    pageLoad ? 
    <Loaders type="spinner" />
    :
    <>
     <Helmet>
    <title>{`Videos | ${window.location.hostname}`}</title>
</Helmet>
     <BreadCrumb routes={getR}  />
     <div className='my-2 grid grid-cols-12 gap-3'>
        {
            fetchData?.length > 0 ?
                fetchData.map((item:any, index:number)=>(
                    <div onClick={(()=>navigate(`${location.pathname}/${item?.id}`))} key={index} className='lg:col-span-3 hover:cursor-pointer col-span-12 sm:col-span-12 md:col-span-4   bg-gray-200 rounded-xl'>
                        <div className='flex flex-col  gap-2 border-l-4 rounded-lg p-3 border-purple-600'>     
                                <p className='text-2xl text-center font-semibold'>{item?.name}</p>
                                    <div className=' flex gap-3  mt-3 justify-between'>
                                        <p className='flex gap-2  items-center'>
                                            <FaVideo className='text-purple-700' /> {item?.watch}/{item?.totalVideo}
                                        </p>
                                        <p className='flex gap-2  items-center'>
                                            <FaStopwatch className='text-purple-700' /> {moment.utc(item?.totalLength * 1000).format('HH:mm:ss')}
                                        </p>
                                    </div>
                                <button onClick={(()=>navigate(`${location.pathname}/${item?.id}`))} className='px-2 w-full py-2 bg-purple-700 text-white rounded-lg'>
                                    Watch Video
                                </button>
                        </div>
                    </div>
                ))
            :
            <div className="col-span-12">
            <DataNotFound title="No Test Series Found" />
        </div>
 
        }
     </div>
    </>
  )
}
