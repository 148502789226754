import React, { useEffect, useState } from 'react'
import { editAdminCouponMaster, getDiscountTypes, showAdminCouponMaster, updateStatusAdminCouponMaster } from '../../../../service/admin/adminCoupon.service'
import { SubmitHandler, useForm } from 'react-hook-form'
import { adminCouponMasterType } from '../../../../types/AdminTypes'
import { statusList, toBoolean } from '../../../../helpers/helpers'
import { setContentUpdate } from '../../../../redux/contentUpdateSlice'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Input from '../../../../components/form/Input'
import formValidationRules from '../../../../helpers/formvalidationRules'
import SearchableSelect from '../../../../components/form/SearchableSelect'
import RadioButton from '../../../../components/form/RadioButton'
import FormButton from '../../../../components/button/FormButton'
import moment from 'moment'

export default function AdminCouponMasterEdit(props:{id:string,open:any}) {
    const [discountTypeList, setDiscountTypeList] = useState([])
    const [loading, setLoading] = useState(false)
    const [editData, setEditData] = useState<any>()

    const fetchDiscountType = async()=>{
        const get:any = await getDiscountTypes();
        if(get?.status === "success"){
            let newData:any = []
            get?.message.map((item:any)=>{
                newData.push({ value: item.id, label: item.name })
            })
            setDiscountTypeList(newData)
        }
    }
    const fetchEditData = async(id:string)=>{
        const get:any = await showAdminCouponMaster(id);
        if(get?.status === "success"){
            setEditData(get?.message)
        }
    }
    useEffect(()=>{
        fetchDiscountType()
        fetchEditData(props.id)
    },[])
    const dispatch = useDispatch()
    const { handleSubmit, control } = useForm<any>({
        values:{
            name:editData?.name,
            max_uses:editData?.max_uses,
            start_date:moment(editData?.start_date).format("YYYY-MM-DD"),
            end_date:moment(editData?.end_date).format("YYYY-MM-DD"),
            fk_discount_type_id:editData?.fk_discount_type_id,
            discount_value:editData?.discount_value,
            status:`${editData?.status}`,
        }
    });
    const onSubmit: SubmitHandler<adminCouponMasterType> = async (data:adminCouponMasterType) => {
      setLoading(true)
      let newData:any= {
          name:data.name,
          max_uses:data.max_uses,
          start_date:data.start_date,
          end_date:data.end_date,
          fk_discount_type_id:data.fk_discount_type_id?.value ? data.fk_discount_type_id?.value : editData?.fk_discount_type_id,
          discount_value:data.discount_value,
          status: data.status ? toBoolean(data.status) : editData?.status,
      }
      console.log(newData)
      let send:any = await editAdminCouponMaster(props.id,newData);
      if(send?.status === "success"){
          setLoading(false)
          dispatch(setContentUpdate({
              id:Math.random() * (100000 - 111111)
            }))
            props.open(false)
          return toast.success(send.message)

      }
      setLoading(false)
    }
    const today:any = new Date().toISOString().split("T")[0];
  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
    <Input
          name="name"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="Coupon"
          placeholder="coupon"
        />
        <div className="grid gap-2 grid-cols-12">
            <div className=" col-span-12 sm:col-span-12 md:col-span-6">
            <Input
          name="start_date"
          type='date'
          control={control}

          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.min(today)
          }}
          label="Start Date"
          placeholder=""
        />
            </div>
            <div className=" col-span-12 sm:col-span-12 md:col-span-6">
            <Input
          name="end_date"
          type='date'
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.min(today)
          }}
          label="End Date"
          placeholder=""
        />
            </div>
            <div className=" col-span-12 sm:col-span-12 md:col-span-6">
            <SearchableSelect
                name="fk_discount_type_id"
                control={control}
                options={discountTypeList}
                rules={formValidationRules.required()}
                label="Select Discount Type"
                placeholder="discount type"
                
                 />
            </div>
            <div className=" col-span-12 sm:col-span-12 md:col-span-6">
            <Input
          name="discount_value"
          type='number'
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.min(1),
          }}
          label="Discount"
          placeholder="discount"
        /> 
            </div>
            <div className=" col-span-12 sm:col-span-12 md:col-span-6">
            <Input
          name="max_uses"
          type='number'
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.min(1),
          }}
          label="Max Uses"
          placeholder="max uses"
        /> 
            </div>
            <div className=" col-span-12 sm:col-span-12 md:col-span-6">
            <RadioButton
                name="status"
                control={control}
                rules={{...formValidationRules.required()}}
                options={statusList}
                label="status"
            />
            </div>
            <div className=" col-span-12 sm:col-span-12 md:col-span-12">
            <FormButton loading={loading} title="Edit Coupon" />
            </div>
        </div>
    </form>
    </>
  )
}
