import { Spinner } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
export default function   YoutubePlayer(props:{url:string,width?:number,watchTime?:any}) {
    const [isReady, setIsReady] = useState(false);
    const [isBuffering, setIsBuffering] = useState(true);
    const [playTime, setPlayTime] = useState(0);
    const handleReady = () => {
        setIsBuffering(false);
        setIsReady(true);
      };
    
      const handleBuffer = () => {
        setIsBuffering(true);
      };
      const handleProgress = (state:any) => {
        console.log(state.playedSeconds)
        setPlayTime(state.playedSeconds)
      };
     
  return (
    <div className=' '>
      {!isReady && 
      <div className='w-[100%] min-h-[450px] grid place-content-center'>
<Spinner/>
      </div>
      }
       <div style={{ display: isReady ? 'block' : 'none' }}> 
        <ReactPlayer
          url={props.url}
          onReady={handleReady}
          onProgress={handleProgress}
          controls
          style={{
            overflow:"hidden"
          }}
          progressInterval={1000}
          width={`100%`}
          height={"440px"}
          playing
        />
      </div>
 </div>
  )
}
