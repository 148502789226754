// Input.tsx
import React from "react";
import { Control, useController } from "react-hook-form";
import { inputError, inputField, inputFieldReadOnly } from "../../helpers/Classes";

interface InputProps {
  name: string;
  control: Control<any>;
  rules?: any;
  defaultValue?: string;
  placeholder?: string;
  type?: string;
  label: string;
  onChange?:any;
  onBlur?:any;
  readonly?: boolean;
}

const Input: React.FC<InputProps> = ({
  name,
  control,
  rules,
  defaultValue,
  label,
  readonly = false,
  ...props
}) => {
  const {
    field,
    fieldState: { error },

  } = useController({
    
    name,
    control,
    rules,
    defaultValue,
    
    
  });

  const handleChange = (e:any) => {
    field.onChange(e.target.value); 
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      props.onBlur(e.target.value);
    }
  };
  const isRequired = rules && rules?.required;
  return (
    <div className="flex flex-col mb-3 ">
      <label htmlFor={name} className={`${error ? 'text-red-500' : 'text-gray-600 dark:text-gray-400'} ml-1`}>
        {label} {isRequired ? <sup className="text-red-500">*</sup> : null}
      </label>
      <input
        className={`${readonly ? inputFieldReadOnly : inputField} ${error ? inputError : null} w-full `}
        id={name}
        {...field}
        maxLength={rules?.min && rules?.maxLength?.value}
        minLength={rules?.min && rules?.minLength?.value}
        min={rules?.min && rules?.min?.value}
        max={rules?.max && rules?.max?.value}
        pattern={rules?.pattern && rules?.pattern?.value}
        readOnly={readonly}
        {...props}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {error && <small className="text-red-500 ml-2">{error.message}</small>}
    </div>
  );
};

export default Input;
