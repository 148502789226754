export const inputField:any = 'dark:bg-gray-700 bg-gray-50 p-3 rounded-lg dark:text-gray-200 text-gray-700  focus-visible:outline-0 focus:ring-inset focus:ring-blue-800 focus:border-0  focus:ring-2 border-0  ring-[1.5px] ring-inset ring-purple-700  ';
export const inputFieldReadOnly:any = 'dark:bg-gray-700 bg-gray-50 p-3 rounded-lg dark:text-gray-200 text-gray-700  focus-visible:outline-0 focus:ring-inset focus:ring-gray-800 focus:border-0  focus:ring-2 border-0  ring-[1.5px] ring-inset ring-gray-700  ';

export const checkbox:any = ' w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 focus-visible:outline-0 ';

export const inputError:any = 'focus:ring-red-500  ring-red-500 dark:ring-red-500'

export const reactSelect:any = ' text-dark';

export const button:any = ' bg-gradient text-white bg-blue-900 px-3 py-2 rounded-lg flex items-center justify-center text-center gap-1'
export const inputRadioField:any = 'dark:bg-gray-700 bg-gray-50 p-3 rounded-lg dark:text-gray-200 text-gray-700  focus-visible:outline-0 focus:ring-inset  ';
export const inputCheckboxField:any = 'dark:bg-gray-700 bg-gray-50  rounded-lg dark:text-gray-200 text-gray-700  focus-visible:outline-0 ';