import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet-async'
import Swal from 'sweetalert2'
import { getStudentVideosPlay, studentVideoWatchEvent } from '../service/student/student.service'
import { TestvimeoHSLSecureURL, vimeoHSLSecureURL } from '../service/admin/adminVimeo.service'
import { BreadCrumRoutes, containsHTMLTags } from '../helpers/helpers'
import Loaders from '../components/loaders/Loaders'
import BreadCrumb from '../components/breadcrumb/BreadCrumb'
import VimeoPlayer2 from '../components/player/VimeoPlayer2'
export default function TestPlayer() {
 
  const [pageLoad, setPageLoad] = useState(false)
  const [fetchData, setFetchData] = useState<any>()
  const [fetchReqDetect, setFetchReqDetect] = useState<any>(null)
  const [screenWidth, setScreenWidth] = useState(0);
  const [url, setUrl] = useState<any>(null)
  const [playTime, setPlayTime] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const fetchVideos = async(id:string)=>{
      setPageLoad(true)
      let newurl = await TestvimeoHSLSecureURL("835123161","d2aa8610-54ff-414a-b4b7-9af089cf399f")
              // if(!newurl){
              //   // navigate(-1)
              // }
              setUrl(newurl?.secureUrl)
              setPageLoad(false)
  }
  useEffect(()=>{
      fetchVideos("647af717-2a04-4796-8167-466a5997f032")
  },[])
  const handleWatchTime = (data:any)=>{
    // fetchWatchTime("647af717-2a04-4796-8167-466a5997f032",data)
  }
  let getR = BreadCrumRoutes("Video",'/student/videos/play/'+location.pathname.split('/')[3],"studentVideoPLay",false)
  return (
    pageLoad ? 
    <Loaders type="spinner" />
    :
    <>
     <Helmet>
    <title>{` ${fetchData?.name} | ${window.location.hostname}`}</title>
</Helmet>
   <p>Testing player with load</p>
     {
                containsHTMLTags(fetchData?.description)  === true ? 
                    <div className=' grid grid-cols-12 gap-2  p-3'>
                      <div className="col-span-12 sm:col-span-12 md:col-span-7 lg:col-span-7 w-full">
                        <div className=''>
                        <VimeoPlayer2 type="Vimeo" data="d2aa8610-54ff-414a-b4b7-9af089cf399f" lastWatch="0" watchTime={handleWatchTime}  url={url} />
                        </div>
                      </div>
                      <div className="col-span-12 sm:col-span-12 md:col-span-5 lg:col-span-5">
                        <div className='bg-gray-300 p-2 rounded-lg'>
                          <p className='text-purple-500 font-bold text-xl'>Overview</p>
                          <div className="my-2">
                               { <div dangerouslySetInnerHTML={{ __html: fetchData?.description }} /> }
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                      <div className='w-full my-4'>
                      <VimeoPlayer2 type="Vimeo" data="d2aa8610-54ff-414a-b4b7-9af089cf399f" lastWatch="0" watchTime={handleWatchTime}  url={url} />
                        </div>
}
    </>
  )
}
