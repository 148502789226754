import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../../components/button/CustomButton';
import Models from '../../../../components/models/Models';
import { getAdminCourseCategoryTab, slugAdminCourseMaster } from '../../../../service/admin/adminCourses.service';
import PaginationDataGrid from '../../../../components/tables/PaginationDataGrid';
import { Helmet } from 'react-helmet-async';
import CourseCategoryTabCE from './CourseCategoryTabCE';
import MUITableGrid from '../../../../components/tables/MUITableGrid';
import CourseCategoryTabOrders from './CourseCategoryTabOrders';

export default function CourseCategoryTab() {
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [slugData, setSlugData] =useState<any>();
    const fetchSlugData = async()=>{
        const get =  await getAdminCourseCategoryTab()
        if(get?.status === "success"){
            setSlugData(get.message)

        }
    }
    useEffect(() =>{
        fetchSlugData()
    },[]) 
  return (
    <>
      <Helmet>
    <title>{`Course Tabs | ${window.location.hostname}`}</title>
</Helmet>
    <BreadcrumbBack title={`Course Tabs `}>
    <CustomButton onClick={(()=>setOpen(!open))}  title='Create New Tab' icon={true} iconName='create'    />
    <CustomButton onClick={(()=>setOpen1(!open1))}  title='Order' bg='bg-purple-900' icon={true} iconName='star'    />
    </BreadcrumbBack>
    <div className="my-2">
    <MUITableGrid name="AdminCourseCategoryTabs"  />
    </div>
    <Models name='Create New Tab' isOpen={open} isClose={setOpen}  >
        <CourseCategoryTabCE type='create' open={setOpen} data={{id:"",master:slugData?.id}} />
    </Models>
    <Models name='Tabs Order' width='md:w-[50vw]' isOpen={open1} isClose={setOpen1}  >
        <CourseCategoryTabOrders />
    </Models>
    </>
  )
}
