import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { FaAngleDown } from 'react-icons/fa';
export default function AccordianMUI(props:{id:string,title:string,children:any}) {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
        };
  return (
    <div> 
      <Accordion 
       sx={{
        marginY:1,
         boxShadow:0,
        border:0,
      
        background:'#e5e7eb',
        '&.Mui-disabled': {
            backgroundColor: '#c4b5fd',
            opacity:1
            },
        
    }}
      expanded={expanded === `${props.id}`} onChange={handleChange(`${props.id}`)}>
      <AccordionSummary
          expandIcon={<FaAngleDown />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
           <b> <div dangerouslySetInnerHTML={{ __html: props.title }}></div></b>
            {/* {props.title}</b> */}
        </AccordionSummary>
        <AccordionDetails>
            
          {
           props.children
          }
        </AccordionDetails>
        </Accordion>
    </div>
  )
}
