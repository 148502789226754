import React, { useEffect, useState } from "react";
import Loaders from "../../../components/loaders/Loaders";
import { Helmet } from "react-helmet-async";
import { FaArrowLeft, FaArrowRight, FaClock, FaHome, FaRupeeSign } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { inputField } from "../../../helpers/Classes";
import { applyCouponCodeCourse, getCourseBasicDetails, getCoursePaymentMethods, studentmakePayment } from "../../../service/student/studentCourses.service";
import Swal from "sweetalert2";
import Models from "../../../components/models/Models";
import QRCode from 'react-qr-code';
import DropZoneUploadImages from "../../../components/dropzone/DropZoneUploadImages";

export default function CourseCheckout() {
  const [pageLoad, setPageLoad] = useState(false);
  const [fetchData, setFetchData] = useState<any>([]);
  const [courseData, setCourseData] = useState<any>()
  const [activeMethod,setActiveMethod] = useState(null);
  const [open, setOpen] = useState(false);
  const [QRcodeURL, setQRCodeURL] = useState<any>(null)
  const [activeTab, setActiveTab] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState(0)
  const [discountCoupon, setDiscountCoupon] = useState<any>(null)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [url,setUrl] = useState<any>();
  const [checkUploadedFilesStatus ,setCheckUploadedFilesStatus] = useState()
  const location = useLocation();


  const stepper = [
    {id:0, title:"Instructions"},
    {id:1, title:"Payment"},
    {id:2, title:"Confirmation"}
  ]
  const fetch = async () => {
    setPageLoad(true)
    let get:any = await getCoursePaymentMethods()
    if(get?.status === "success"){
      setPageLoad(false)
      setFetchData(get?.message)
    }
    setPageLoad(false)
  }
  const fetchCourse = async()=>{
    let get = await getCourseBasicDetails(location.pathname.split("/")[location.pathname.split("/").length -1])
    if(get?.status === "success"){
      setCourseData(get?.message)
      setTotalAmount(get?.message?.amount)
      return get?.message?.amount;
    }
    return false;
  }
  useEffect(() => {
    fetch()
    fetchCourse()
  }, [])
  const handlePayment = async()=>{
    if(activeMethod === null){
      return Swal.fire({
        icon:"error",
        text:"Please select payment method "
      })
    }
    let check:any = fetchData.filter((item:any)=>item.name === activeMethod);
    if(check[0].name === "QRcode"){
      const upi:any = check[0]?.admin_payment_setup[0]?.mechant_id;
      const upiUrl:any = `upi://pay?pa=${upi}&pn=${'Atul'}&am=${totalAmount}&cu=${'INR'}`;
      setQRCodeURL(upiUrl)
      setOpen(true)
    }else{
     let get:any = await studentmakePayment(discountCoupon,location.pathname.split("/")[location.pathname.split("/").length -1],check[0].id)
     if(get?.status === "success"){
      window.location = get?.message
     }
    }
  }
  const handleDiscount = async()=>{
    if(discountCoupon === null){
      return Swal.fire({
        icon:"error",
        text:"Please enter coupon code "
      })
    }
    let checks:any = await fetchCourse()
    if(checks){
      let check:any = await applyCouponCodeCourse(discountCoupon,location.pathname.split("/")[location.pathname.split("/").length -1])
      if(check?.status === "success" && check?.message?.status === true){
        if(check?.message?.discount_type.name === "Amount"){
          setDiscount(check?.message?.discount_value)
          setTotalAmount(checks - check?.message?.discount_value)
        }else if(check?.message?.discount_type.name === "Percent"){
          setDiscount(checks * check?.message?.discount_value / 100)
          setTotalAmount(checks - (checks * check?.message?.discount_value / 100))
        }
    }else{
      setDiscount(0)
    }
    
      // setTotalAmount(totalAmount-discount)
    }
    // console.log(totalAmount)
  }
  const handleQrPaymentNextBtn = ()=>{
    setActiveTab(stepper.length === activeTab+1 ? stepper.length : activeTab+1)
  }
  const handleQrPayment = async()=>{
    let check:any = fetchData.filter((item:any)=>item.name === activeMethod);
    let paymentId:any = null;
    if(check[0].name === "QRcode"){
      paymentId = check[0].id
    }
    let send:any = await studentmakePayment(discountCoupon,location.pathname.split("/")[location.pathname.split("/").length -1],paymentId,url)
    if(send?.status === "success"){
      return Swal.fire({
        icon:"success",
        title:send?.message,
        showConfirmButton:false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          setTimeout(() => {
            Swal.hideLoading()
            navigate("/student/dashboard")
          }, 1000)
        }
      })
    }
  }
  return (
    <>
      {pageLoad ? (
        <Loaders type="spinner" />
      ) : (
        <div>
          <Helmet>
            <title>{`Course checkout | ${window.location.hostname}`}</title>
          </Helmet>
          <div className="p-4 dark:bg-dark-purple1 bg-gray-200 gap-5  rounded-lg flex flex-wrap flex-col sm:flex-col md:flex-col   ">
           <p className="text-4xl  font-bold">Checkout</p>
           <div className="flex flex-row gap-2 items-center text-lg">
           <FaHome onClick={(()=>navigate("/student/dashboard"))} className={`text-gray-500  hover:cursor-pointer`} /> /
            <p className="font-semibold hover:cursor-pointer text-gray-500" onClick={(()=>navigate("/student/courses"))}>Courses</p> /
            <p className="font-semibold">checkout</p>
           </div>
          </div>
          <div className="my-3">
            <div className="grid grid-cols-12 gap-3">
              <div className=" col-span-12 sm:col-span-12 md:col-span-7 ">
                <div className="flex gap-2 items-center border border-gray-300 p-3 rounded-xl">
                  <div className="w-full">
                    <label className="text-gray-600" htmlFor="">have a coupon ?</label><br/>
                    <input type="text" className={`${inputField} w-full`} onChange={((e:any)=>setDiscountCoupon(e.target.value))} placeholder="coupon" />
                  </div>
                  <button onClick={(()=>handleDiscount())} className="px-3 py-2 bg-purple-600 text-white text-lg rounded-lg mt-5">Apply</button>
                </div>
                <hr />
                <div className="mt-4 border border-gray-300 p-3 rounded-xl">
                  <p className="font-semibold text-gray-800 text-lg">Select payment method</p>
                  <div className="my-4">
                  {
                      fetchData.length > 0 ?
                        fetchData.map((e:any,index:number)=>(
                          <div key={index} className="flex flex-col gap-2 ">
                          <button onClick={(()=>setActiveMethod(e.name))} className={` hover:bg-purple-500  px-3 py-2 my-2 rounded-lg flex items-center gap-3 ${activeMethod === e.name ? 'bg-purple-500' : 'bg-gray-300'}`}>
                            <img src={e.paymentMethodMaster?.icon} className='w-[50px] h-[50px] rounded-full' alt="" />
                            <p className='text-lg font-semibold'>
                              {
                                e.name
                              }
                            </p>
                          </button>
                          </div>
                        ))
                      : <p>No payment methods available</p>
                    }
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-12 md:col-span-5">
              <div className="border border-gray-300 p-3 rounded-xl ">
              <p className="font-semibold text-gray-800 text-lg">Order Summary</p>
              <div className="my-2 flex flex-row gap-3 relative border border-gray-400 p-2 rounded-xl">
                    <img src={courseData?.image} className="w-[100px] h-[100px] rounded-xl" alt="" />
                    <div className="w-full ">
                      <p className=" text-lg text-gray-800">{courseData?.name}</p>
                      <div className="absolute w-[60%]  bottom-[12px]">
                      <div className="flex justify-between items-center ">
                       <p className="flex  items-center  text-gray-700"> <FaRupeeSign />{courseData?.amount}</p>
                        <p className="flex  items-center  text-gray-700"><FaClock />{courseData?.duration} days</p>
                      </div>
                    </div>
                    </div>
              </div>
              <div className="w-full p-3 mt-3">
                <p className="flex items-center justify-between text-lg text-gray-700 my-2">
                  <b>Price</b> <p className="flex  items-center  text-gray-700"> <FaRupeeSign /> {courseData?.amount}</p>
                </p>
                <p className="flex items-center justify-between text-lg text-gray-700 my-2">
                  <b>Discount</b> <p className="flex  items-center  text-gray-700"> <FaRupeeSign /> {discount}</p>
                </p>
                <hr className="bg-gray-800 h-[1px] my-3" />
                <p className="flex items-center justify-between text-lg text-gray-700 my-2">
                  <b>Total</b> <p className="flex  items-center  text-gray-700"> <FaRupeeSign /> {totalAmount} </p>
                </p>
                <button onClick={handlePayment} className="bg-purple-700 text-white rounded-xl w-full py-3 mt-3">Pay</button>
              </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
       <Models name='Scan to pay' isOpen={open} isClose={setOpen}  >
        <div className=" ">
          <ol className=" hidden md:flex overflow-y-auto  items-center  w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 rtl:space-x-reverse">
            {
              stepper.length > 0 &&
                stepper.map((item:any,index:number)=>(
                  index === 0 ?
                  <li onClick={(()=>setActiveTab(index))} className={`flex hover:cursor-pointer items-center  ${activeTab === index ? 'dark:text-blue-500 text-blue-500':''} `}>
                  <span className={`${activeTab === index ? 'dark:border-blue-500 border-blue-600' : 'dark:border-gray-500 border-gray-600'} flex items-center justify-center w-5 h-5 me-2 text-xs border  rounded-full shrink-0 `}>
                      {index+1}
                  </span>
                 {item.title}
                 <svg className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 9 4-4-4-4M1 9l4-4-4-4"/>
                  </svg>
              </li>
                  :
                  index === stepper.length -1 ?
                  <li onClick={(()=>setActiveTab(index))} className={`flex hover:cursor-pointer items-center  ${activeTab === index ? 'dark:text-blue-500 text-blue-500':''} `}>
        <span className={`${activeTab === index ? 'dark:border-blue-500 border-blue-600' : 'dark:border-gray-500 border-gray-600'} flex items-center justify-center w-5 h-5 me-2 text-xs border  rounded-full shrink-0 `}>
            {index+1}
        </span>
        {item.title}
    </li>
                  :
                  <li onClick={(()=>setActiveTab(index))} className={`flex hover:cursor-pointer items-center  ${activeTab === index ? 'dark:text-blue-500 text-blue-500':''} `}>
                  <span className={`${activeTab === index ? 'dark:border-blue-500 border-blue-600' : 'dark:border-gray-500 border-gray-600'} flex items-center justify-center w-5 h-5 me-2 text-xs border  rounded-full shrink-0 `}>
                      {index+1}
                  </span>
                  {item.title}
                  <svg className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 9 4-4-4-4M1 9l4-4-4-4"/>
                  </svg>
              </li>

                  

                ))
            }
          </ol>
            <div className="min-h-[350px] h-[350px] overflow-auto  relative">
            {
              activeTab === 0 &&
              <>
              <p className=" font-semibold text-xl text-red-500 p-2"> Instructions </p>
          <ol className="p-2">
            <li className="my-2">1. This is a manual process, after making payment, you need to submit of the payment receipt to us.</li>
            <li className="my-2">2. Once admin approve after that you will be grant access</li>
          </ol>
          
         
         
              </>
            }
            {
              activeTab === 1 && 
              <div>
                 <p className=" font-semibold text-xl text-red-500 p-2"> Scan to Pay </p>
                <div className="flex justify-center items-center p-3 ">
                <QRCode style={{height:"200px"}}     value={QRcodeURL}   />
                </div> 
              </div>
            }
            {
              activeTab === 2 && 
              <div>
                 <p className=" font-semibold text-xl text-red-500 p-2"> Submit payment screenshot/slip </p>
                 <DropZoneUploadImages heigth='min-h-[25vh]' title="Upload  Image" isRequired={true} maxFile={1} data={setUrl} url={url} status={setCheckUploadedFilesStatus} />
              </div>
              
            }
           <div className="absolute bottom-0 w-full">
           <div className="flex gap-4 items-center justify-between">
          <button onClick={(()=>setActiveTab(activeTab === 0 ? 0 : activeTab-1))} disabled={activeTab === 0 ? true : false} className="bg-blue-600  text-white font-semibold text-xl flex gap-2 items-center  rounded-xl p-3 text-center w-full justify-center">
             <FaArrowLeft/> Previous 
          </button> 
          <button onClick={stepper.length === activeTab+1 ? handleQrPayment :handleQrPaymentNextBtn }   className="bg-purple-600  text-white font-semibold text-xl flex gap-2 items-center  rounded-xl p-3 text-center w-full justify-center">
              {
                stepper.length === activeTab+1 ?
                "Submit"
                :
                <>
                Next <FaArrowRight/>
                </>
              }
          </button>
          </div>
          </div>
          </div>
        </div>
        
        </Models>
    </>
  );
}
