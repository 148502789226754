import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import  Cookies  from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import {  inputField } from '../../helpers/Classes';
import LoginButton from '../../components/button/LoginButton';
import Logo from "../../assets/icons/logo.jpg"
import { SuperAdminLogin } from '../../service/superAdmin/superAdminAuth.service';
import { setAuthToken } from '../../service/AuthConfig';
import { instituteLogin, schoolLogin, studentLogin, teacherLogin } from '../../redux/loginSlice';
import { AdminLoginService } from '../../service/admin/admin.service';
import { StudentLoginService } from '../../service/student/student.service';
import { Helmet } from 'react-helmet-async';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
export default function LoginPublic() {

    const [email,setEmail] = useState(undefined);
    const [password,setPassword] = useState(undefined);
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const dispatch = useDispatch(); 
    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(()=>{
        if(location.pathname.split('/')[1] === "admin"){
            setActiveTab(1)
        }else if(location.pathname.split('/')[1] === "super-admin"){
            setActiveTab(2)
        }else{
            setActiveTab(0)
        }
    Cookies.remove("token");
  Cookies.remove("role");
  Cookies.remove("login");
        document.title = 'Login | LMS';
    },[]);

    const handleSubmit = async(e:any)=>{
        e.preventDefault()
        setLoading(true)
        if(email === undefined){
            return Swal.fire({
                icon: "error",
                text: "login Id required",
              });
        }
        if(password === undefined){
            return Swal.fire({
                icon: "error",
                text: "password required",
              });
        }
        let login:any
        if(activeTab === 0) {
            login = await StudentLoginService(email,password);
        }else if(activeTab === 1){
            login = await AdminLoginService(email,password);
        }else if(activeTab === 2){
             login = await SuperAdminLogin(email,password);
        }else{
            return Swal.fire({
                icon: "error",
                text: "Please select role first",
              });
        }
        
        if(login.status==="error"){
          return  setLoading(false)
          }
          if(login.status==="success"){
            Cookies.set(`role`, login.message.role);
            Cookies.set("token", login.message.token);
            Cookies.set("login",JSON.stringify(login.message));
            setAuthToken(login.message.token)
           
           if(activeTab === 0){
            dispatch(studentLogin())
            Cookies.set('role','student')
            if(login.message.password_update === false){
                return navigate("/student/login-password/update")
             }
            navigate("/student/dashboard")
           }else if(activeTab === 1){
            dispatch(schoolLogin())
            Cookies.set('role','admin')
            if(login.message.password_update === false){
                return navigate("/admin/login-password/update")
             }
            navigate("/admin/dashboard")
           
           }else if(activeTab === 2){
            Cookies.set('role','superAdmin')
            dispatch(instituteLogin())
            navigate("/super-admin/dashboard")
           }
            setLoading(false)
              return toast.success(login.message);    
          }
        setLoading(false)

        
    }

  return (
        <>
         <Helmet>
            <title>{`Login | ${window.location.hostname}`}</title>
        </Helmet>
        <div className='container-login100'>
                <div className=' lg:min-w-[25vw]'>
                    <div className='bg-white rounded-3xl shadow-lg p-5 sm:p-5 md:p-10 lg:p-10 w-full'>
                        <div className="flex justify-center mb-4">
                            <img src={Logo} alt="chahal Academy Logo" />
                        </div>
                        <p className=' text-gray-800 '>Login as </p>
                        <div className=" flex gap-2 bg-gray-300   p-1 rounded-xl justify-between  ">
                            <button onClick={(()=>setActiveTab(0))} className={`px-4 py-2 rounded-lg  font-bold ${activeTab === 0  ? 'bg-purple-700 text-white ' : ' bg-gray-300 text-dark'}`}>Student</button>
                            {/* <button>Teacher</button> */}
                            <button onClick={(()=>setActiveTab(1))} className={`px-4 py-2 rounded-lg  font-bold ${activeTab === 1  ? 'bg-purple-700 text-white' : ' bg-gray-300 text-dark'}`}>Admin</button>
                            <button onClick={(()=>setActiveTab(2))} className={`px-4 py-2 rounded-lg  font-bold ${activeTab === 2  ? 'bg-purple-700 text-white' : ' bg-gray-300 text-dark'}`}>Super Admin</button>
                        </div>
                        <form className='my-5' onSubmit={handleSubmit}>
                        <div className="flex mb-2 flex-row gap-3 ">
                        <div className="flex flex-col mb-3 w-[100%] ">
                            <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Email <sup className='text-red-600'>*</sup></label>
                            <input type="email"
                            required
                            onChange={((e:any)=>setEmail(e.target.value))}
                            className={`${inputField} `}  placeholder='Login Id'   />
                            </div>
                        </div>
                        <div className="flex mb-3 flex-col ">
                        <div className="flex flex-col mb-3 w-[100%] relative">
                            <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Password <sup className='text-red-600'>*</sup></label>
                            <input type={showPassword ? 'text':'password'} 
                            required
                            onChange={((e:any)=>setPassword(e.target.value))}
                            className={`${inputField} `}  placeholder='password'   />
                              <div className=' absolute  text-gray-600 right-5 bottom-4 hover:cursor-pointer ' onClick={(()=>setShowPassword(!showPassword))}>
                                {
                                    showPassword ? 
                                    <FaEye />
                                    :
                                    <FaEyeSlash className=' '/>
                                }
                            </div>
                            </div>
                         
                            {
                                activeTab === 0  ? 
                                <label onClick={(()=>navigate("/student/forget-password"))} className='text-red-500 hover:cursor-pointer text-right ml-1 ' >Forgot Password?</label>
                                :null
                            }
                        </div>
                        
                        <div className="flex mb-3 flex-row gap-3 ">
                        <div className="flex flex-col mb-3 w-[100%] ">
                          
                        <LoginButton icon={true} title='Login'  loading={loading} />
                            </div>
                        </div>
                        </form>
                        {
                                activeTab === 0  ? 
                                <p onClick={(()=>navigate("/student/register"))} className='text-dark font-semibold hover:cursor-pointer  text-center ml-1 ' >Don't have Account? Create New</p>
                                :null
                            }
                    </div>
                </div>
        </div>
        </>
  )
}
