import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AdminCourseMastersTypes } from "../../../types/AdminTypes";
import { statusList, toBoolean } from "../../../helpers/helpers";
import { useDispatch } from "react-redux";
import { setContentUpdate } from "../../../redux/contentUpdateSlice";
import { toast } from "react-toastify";
import Input from "../../../components/form/Input";
import formValidationRules from "../../../helpers/formvalidationRules";
import Upload from "../../../components/uploads/Upload";
import ImageUpload from "../../../components/uploads/ImageUpload";
import DropZoneUploadImages from "../../../components/dropzone/DropZoneUploadImages";
import RadioButton from "../../../components/form/RadioButton";
import FormButton from "../../../components/button/FormButton";
import { createAdminCourseMaster, editAdminCourseMaster, showAdminCourseMaster } from "../../../service/admin/adminCourses.service";

export default function AdminCourseMasterCE(props: {
  type: string;
  data?: any;
  open: any;
}) {
    const [pageLoad, setPageLoad]= useState(false);
  const [fetchData, setFetchData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [url, setUrl] = useState<any>();
  const [checkUploadedFilesStatus, setCheckUploadedFilesStatus] = useState<any>(false);
  const [pageContent, setPageContent] = useState({
    button:"Create Course Master",
})

    
    const fetchBatchDetail = async(id:string)=>{
        const get:any = await showAdminCourseMaster(id)
      if(get?.status === "success"){
        setUrl(get.message?.image)
        setFetchData(get.message)
      }
    }
    useEffect(()=>{ 

        setPageLoad(true)
        if(props.type === "edit"){
            fetchBatchDetail(props.data.id)
            setPageContent({
                button:"Update Course Master"
            })
        }
        // fetchInstituteCenter()
        setPageLoad(false)
      },[])

  const { handleSubmit, control } = useForm<AdminCourseMastersTypes>({
    values: {
      name: fetchData?.name,
      status: `${fetchData?.status ? fetchData?.status : true}`,
      on_app: `${fetchData?.on_app ? fetchData?.on_app :  true}`,
      on_web: `${fetchData?.on_web ? fetchData?.on_web : true}`,
      image: fetchData?.image,
    },
  });
  const onSubmit: SubmitHandler<AdminCourseMastersTypes> = async (data:AdminCourseMastersTypes) => {
    setLoading(true);
    let status_: any = toBoolean(data.status);
    let onApp_: any = toBoolean(data.on_app);
    let onWeb_: any = toBoolean(data.on_web);
    let fetch: any;
   data.on_app = onApp_
   data.on_web = onWeb_
   data.status = status_
   data.image = url

       if(props.type==="create"){

        fetch = await createAdminCourseMaster(data);
       }
       if(props.type === "edit"){
        fetch = await editAdminCourseMaster(fetchData?.id,data);
       }
    if (fetch?.status === "success") {
      setLoading(false);
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      props.open(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };

  
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        <Input
          name="name"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="Name"
          placeholder="Name"
        />
        
        
        <DropZoneUploadImages title="Upload Course Master Image" isRequired={true} maxFile={1} data={setUrl} url={url} status={setCheckUploadedFilesStatus} />
        <div className="my-3">
        <RadioButton
        name="on_web"
        control={control}
        rules={{...formValidationRules.required()}}
        options={statusList}
        label="Website"
      />
       </div>
       <div className="my-3">
       <RadioButton
        name="on_app"
        control={control}
        rules={{...formValidationRules.required()}}
        options={statusList}
        label="Application"
      />
      </div>
      <div className="my-3">
       <RadioButton
        name="status"
        control={control}
        rules={{...formValidationRules.required()}}
        options={statusList}
        label="status"
      />
       </div>
       <br/>
       <FormButton loading={loading} title={pageContent.button} />
      </form>
    </>
  );
}
