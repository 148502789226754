import { AdminStudentTypes } from "../../types/AdminTypes";
import { StudentTypes } from "../../types/StudentTypes";
import instance from "../instace";

export const StudentLoginService = async(email: string, password: string)=>{
    try{
        const get:any = await instance.post('/api/student/login',{
            email, password,
        })
        return get.data;
    }catch(e){
        return false;
    }
}

export const RegisterStudents = async(data:StudentTypes)=>{
    try{
        const get:any = await instance.post('/api/student/register',{
            name:data.name,
            email:data.email,
            phone:data.phone,
            dob:data.dob,
            center_id:data.center_id,
            gender_id:data.gender_id,
            password:data.password,
            confirmPassword:data.confirmPassword
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const checkStudentEmail = async(email:string)=>{
    try{
        const get:any = await instance.post('/api/student/check-email-otp',{
            email:email,
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const studentForgotPasswordVerifyOTP = async(student_id:string,otp:any)=>{
    try{
        const get:any = await instance.post('/api/student/forget-password/verify-otp',{
            student_id:student_id,
            otp:otp
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const studentResetPasswordVerifyOTP = async(student_id:string,otp:any,password:any,confirmPassword:any)=>{
    try{
        const get:any = await instance.post('/api/student/update-password',{
            student_id,
            otp,
            password,
            confirmPassword
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const updateStudentPasswordService = async(password:string,confirmPassword:string)=>{
    try{
        const get:any = await instance.put(`/api/student/login/password`,{
            password:password,
            confirmPassword:confirmPassword,
            type:"login"
        })
        return get.data;
    }catch(e){
            return false;
    }
}

export const getStudentLiveClasses = async()=>{
    try{
        const get:any = await instance.get(`/api/student/live-classes`);
        return get.data;
   }catch(e){
            return false;
    }
}

export const getStudentVideosCourses = async()=>{
    try{
        const get:any = await instance.get(`/api/student/videos`);
        return get.data;
   }catch(e){
            return false;
    }
}
export const getStudentVideosCoursesModule = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/student/videos/modules/${id}`);
        return get.data;
   }catch(e){
            return false;
    }
}
export const getStudentVideosCoursesModuleSubModules = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/student/videos/modules/${id}/submodules`);
        return get.data;
   }catch(e){
            return false;
    }
}
export const getStudentVideosList = async(id:string,sub:string,courseId:string)=>{
    try{
        const get:any = await instance.get(`/api/student/videos/modules/${id}/submodules/${sub}/${courseId}`);
        return get.data;
   }catch(e){
            return false;
    }
}
export const getStudentVideosPlay = async(id:string,course:string)=>{
    try{
        const get:any = await instance.get(`/api/student/videos/play/${id}/${course}`);
        return get.data;
   }catch(e){
            return false;
    }
}
export const studentVideoWatchEvent = async(id:string,time:any,courseId:any)=>{
    try{
        const get:any = await instance.post(`/api/student/videos/watchEvent/${id}`,{
            lastWatchTime:Math.ceil(time),
            courseId:courseId
        });
        return get.data;
   }catch(e){
            return false;
    }
}
export const studentVideoWatchDefaultInterval = async()=>{
    try{
        const get:any = await instance.get(`/api/student/videos/watch-interval`);
        return get.data;
   }catch(e){
            return false;
    }
}

export const getStudentTestSeriesCourses = async()=>{
    try{
        const get:any = await instance.get(`/api/student/test-series`);
        return get.data;
   }catch(e){
            return false;
    }
}
export const getStudentTestSeriesCoursesModule = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/student/test-series/modules/${id}`);
        return get.data;
   }catch(e){
            return false;
    }
}

export const getStudentTestSeriesCoursesSubModules = async(id:string,courseId:string)=>{
    try{
        const get:any = await instance.get(`/api/student/test-series/submodules/${courseId}/${id}`);
        return get.data;
   }catch(e){
            return false;
    }
}
export const getStudentTestSeriesCoursesNSubModules = async(id:string,courseId:string)=>{
    try{
        const get:any = await instance.get(`/api/student/test-series/submodules-n/${courseId}/${id}`);
        return get.data;
   }catch(e){
            return false;
    }
}
export const getStudentTestSeriesQuestions = async(id:string,courseId:string)=>{
    try{
        const get:any = await instance.get(`/api/student/test-series/questions/${courseId}/${id}`);
        return get.data;
   }catch(e){
            return false;
    }
}

export const StudentTestSeriesAttempt = async(submoduleId:string,courseId:string)=>{
    try{
        const get:any = await instance.get(`/api/student/test-series/attempt/${courseId}/${submoduleId}`);
        return get.data;
   }catch(e){
            return false;
    }
}
export const StudentTestSeriesSubmit = async(testsubmodule:string,courseId:string,question:any)=>{
    try{
        const get:any = await instance.post(`/api/student/test-series/submit`,{
          
            testsubmodule,
            courseId,
            question
        });
        return get.data;
   }catch(e){
            return false;
    }
}
export const StudentTestSeriesBasicResult = async(testsubmodule:string,courseId:string,attempt?:any)=>{
    try{
        const get:any = await instance.get(`/api/student/test-series/basic/result/${courseId}/${testsubmodule}/${attempt ? attempt:1}`);
        return get.data;
   }catch(e){
            return false;
    }
}
export const StudentDashboardFeeds = async()=>{
    try{
        const get:any = await instance.get(`/api/student/dashboard/feeds`);
        return get.data;
   }catch(e){
            return false;
    }
}
export const StudentshowAdminFeed = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/student/feed/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const StudentCourseList = async()=>{
    try{
        const get:any = await instance.get(`/api/student/course/list`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const StudentCourseVideoStats = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/student/course/video/stats/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const StudentCourseTestStats = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/student/course/test/stats/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const StudentExamAlertSubjectList = async()=>{
    try{
        const get:any = await instance.get(`/api/student/exam-alert/subject/active`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const StudentExamAlertSubjectCreate = async(subject:string,date:any)=>{
    try{
        const get:any = await instance.post(`/api/student/exam-alert/create`,{
            subject,date
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const StudentExamAlertSubject = async()=>{
    try{
        const get:any = await instance.get(`/api/student/exam-alert`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const StudentExamAlertSubjectDelete = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/student/exam-alert/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const getStudentProfile = async()=>{
    try{
        const get:any = await instance.get(`/api/student/profile`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const StudentProfileUpdate = async(data:AdminStudentTypes)=>{
    try{
        const get:any = await instance.put(`/api/student/profile/edit`,{
            name:data.name,
            email:data.email,
            phone:data.phone,
            dob:data.dob,
            status:data.status,
            gender_id:data.gender_id,
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const StudentProPasswordUpdate = async(password:string,newPassword:string,confirmPassword:string)=>{
    try{
        const get:any = await instance.put(`/api/student/profile/password/edit`,{
            password,
            newPassword,
            confirmPassword
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const StudentProFileImageUpdate = async(url:string)=>{
    try{
        const get:any = await instance.put(`/api/student/profile/image/edit`,{
            profileImg:url,
        });
        return get.data;
    }catch(e){
            return false;
    }
}