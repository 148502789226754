import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function ReactBeautiful(props:{data:any,newOrder:any}) {
    const [fetchData, setFetchData] = useState(props.data);
    const handleOnDragEnd = (result:any) => {
        if (!result.destination) return; // If dropped outside a droppable area, do nothing
    
        const reorderedItems = Array.from(fetchData);
        const [movedItem] = reorderedItems.splice(result.source.index, 1); // Remove the dragged item
        reorderedItems.splice(result.destination.index, 0, movedItem); // Insert it at the new position
        props.newOrder(reorderedItems)
        setFetchData(reorderedItems);
      };
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
    <Droppable droppableId="droppable">
      {(provided:any) => (
        <ul
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ padding: 0, listStyle: 'none' }}
        >
          {fetchData.map((item:any, index:number) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided:any) => (
                <li
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    ...provided.draggableProps.style,
                    padding: '16px',
                    marginBottom: '8px',
                    backgroundColor: '#f4f4f4',
                    borderRadius: '4px',
                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                    cursor: 'grab',
                  }}
                >
                  <div className=' flex flex-nowrap overflow-hidden items-center gap-3'>
                    <p>{index+1}</p>
                    <p className='flex flex-nowrap overflow-hidden items-center gap-2'>
                      {
                        item?.image &&
                        <img src={item?.image} className='w-[50px] h-[50px] rounded-full' alt="" />
                      }
                      <p>{item?.name}</p>
                    </p>
                  </div>
                </li>
              )}
            </Draggable>
          ))}
          {provided.placeholder} {/* Placeholder for spacing during dragging */}
        </ul>
      )}
    </Droppable>
  </DragDropContext>
  )
}
