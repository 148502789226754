import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { toLowerString } from '../../../helpers/helpers';
import Input from '../../../components/form/Input';
import formValidationRules from '../../../helpers/formvalidationRules';
import FormButton from '../../../components/button/FormButton';
import { createAdminPaymethodMethodSetup, showAdminPaymethodMethodSetup } from '../../../service/admin/adminpayments.service';
import { toast } from 'react-toastify';

export default function AdminPaymentMethodSetup(props:{data:any,open:any}) {
    const [loading, setLoading] = useState(false)
    const [fetchData, setFetchData] = useState<any>()
    const fetch = async()=>{
        let get:any = await showAdminPaymethodMethodSetup(props.data.id);
        if(get?.status === "success"){
            setFetchData(get?.message)
        }
    }
    useEffect(()=>{
        fetch()
    },[])
    const { handleSubmit, control } = useForm<any>({
        values:{
            upi:fetchData?.mechant_id,
            mechant_id:fetchData?.mechant_id,
            mechant_user_id:fetchData?.mechant_user_id,
            salt_key:fetchData?.salt_key,
            salt_index:fetchData?.salt_index,
        }
    });
    const onSubmit: SubmitHandler<any> = async(data:any) => {
        let submitData = {
            fk_admin_payment_master_id:props.data.id,
            mechant_id:toLowerString(props.data.name) === "qrcode" ? data?.upi : data?.mechant_id,
            mechant_user_id:data?.mechant_user_id,
            salt_key:data?.salt_key,
            salt_index:data?.salt_index,
        }
        let cr:any = await createAdminPaymethodMethodSetup(submitData);
        if(cr?.status === "success"){
            props.open(false);
            return toast.success(cr.message)
        }
    }

  return (
    <div className='my-2 '>
       
        <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>
            {
                toLowerString(props.data.name) === "qrcode" &&
                <Input
                name="upi"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                  ...formValidationRules.minLength(3),
                }}
                label="UPI"
                placeholder="UPI"
              />
            }
            {
                toLowerString(props.data.name) !== "qrcode" &&
                    <>
                 <Input
                name="mechant_id"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                  ...formValidationRules.minLength(3),
                }}
                label="Mechant Id"
                placeholder="mechant id"
              />
               <Input
                name="mechant_user_id"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                }}
                label="Mechant User Id"
                placeholder="mechant user id"
              />
               <Input
                name="salt_key"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                }}
                label="Salt Key"
                placeholder="salt_key"
              />
               <Input
                name="salt_index"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                }}
                label="Salt Index"
                placeholder="salt_index"
              />
                    </>
            }
             <div className="my-3">
                <FormButton loading={loading} title="submit" />
                </div>
        </form>
    </div>
  )
}
