import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import { useLocation, useNavigate } from 'react-router-dom'
import AdminStudentProfileDetails from './AdminStudentProfileDetails'
import AdminStudentProfileSubjects from './AdminStudentProfileSubjects'
import { getAdminStudentSubjects, getAdminStudentSubjectsTest, getAdminStudentSubjectsVideo } from '../../../service/admin/adminStudent.service'
import { Accordion, AccordionDetails, AccordionSummary, Skeleton } from '@mui/material'
import { FaAngleDown, FaCalendarCheck, FaCalendarTimes, FaRupeeSign } from 'react-icons/fa'
import moment from 'moment'
import { IoNewspaper } from 'react-icons/io5'

export default function AdminStudentProfile() {
  const [activeTab, setActiveTab] = useState<any>(0)
  const [subjects, setSubjects] = useState([])
  const [courseStats, setCourseStats] = useState<any>([])
  const [courseStatsLoading, setCOurseStatsLoading] = useState(false)
  const [courseDataLoading, setCourseDataLoading] = useState(false)
  const [courseList,setCourseList] = useState<any>([])
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const fetchCourseStats = async(id:string,index:number,subject:string)=>{
      setCOurseStatsLoading(true)
      let get:any;
      if(index === 0){
         get = await getAdminStudentSubjectsTest(id,subject);
      }else if(index === 1){
        get = await getAdminStudentSubjectsVideo(id,subject);
      }
      if(get?.status === "success"){
        setCOurseStatsLoading(false)
        setCourseStats(get?.message)
      }
      setCOurseStatsLoading(false)
    }
    const fetchSubjects = async(id:string)=>{
      setCourseDataLoading(true)
      let get:any = await getAdminStudentSubjects(id);
        if(get?.status === "success"){
          setCourseDataLoading(false)
          setCourseList(get?.message)
        }
        setCourseDataLoading(false)
    }
   
    useEffect(()=>{
      fetchSubjects(location.pathname.split('/')[4])
    },[])
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleChange =
          (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
            setActiveTab(0)
          };
    
        
          const tablist = [
            {id:0,name:"Test Series",title:"testSeries"},
            {id:1,name:"Videos",title:"Videos"},
            {id:2,name:"Payments",title:"payments"},
          ]
  return (
    <>
    <Helmet>
        <title>{` ${params.get('name')} Profile   | ${window.location.hostname}`}</title>
    </Helmet>
    <BreadcrumbBack title={`${params.get('name') ? params.get('name') : "Student Profile"}`} />
    <div className="my-2">
    {
              courseDataLoading ? <Skeleton variant="rounded"  animation="wave" sx={{
                width: "100%",
                marginTop:2
              }} height={140} /> :
              courseList.length > 0 ?
              <div className="mt-3">
                   <p className='text-xl  font-semibold  text-gray-800'>Courses</p>
                {
                  courseList.map((item:any, index:number)=>(
                    <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}
                    sx={{
                        marginB:2,
                         boxShadow:0,
                        borderLeft: 1,
                        borderLeftWidth:4,
                        borderLeftColor:"#9333ea",
                      
                        background:'rgb(229 231 235)',
                        '&.Mui-disabled': {
                            backgroundColor: 'rgb(229 231 235)',
                            opacity:1
                            },
                        
                    }}
                    >
        <AccordionSummary
            onClick={(()=>fetchCourseStats(location.pathname.split('/')[4],0,item?.id))}
          expandIcon={<FaAngleDown />} 
          aria-controls={`panel${index+1}-content`}
          id={`panel${index+1}-header`}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            '&.Mui-disabled': {
                opacity:1
                },
            
        }}
        >
          <div className=' hover:cursor-pointer  w-[100%] ' >
                            <div className="!flex  lg:flex-row  flex-col md:flex-col sm:flex-col justify-between " >
                            <div className='flex items-center gap-2 '>
                                {
                                    moment(item?.studentSubscription[0]?.expiry_date).isBefore(new Date()) ? 
<p className='bg-red-500 px-4 py-1 rounded-lg mr-5 text-white'>Expired</p>
                                    :
<p className='bg-green-500 px-4 py-1 rounded-lg mr-5 text-white'>Active</p>
                                }
                             <img src={item?.image} className='h-[50px] rounded-lg' alt="" /> <p className='text-lg font-bold'>{item?.name} </p>
                            </div>
                           <div className='flex gap-4 mr-0 sm:mr-0 md:mr-10 mt-5 sm:mt-5 md:mt-0 sm:flex-col flex-col md:flex-row  '>
                           <div className='flex gap-2 items-center'>
                                    <FaRupeeSign className=' bg-purple-900 text-white p-2 text-[2.2rem] rounded-lg' />
                                    <div>
                                        <p className=' font-bold text-sm'>Amount</p>
                                        <p className='text-sm'>{item.course_amount === "0" ? "FREE" : item.course_amount}</p>
                                    </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <FaCalendarCheck className=' bg-purple-600 text-white p-2 text-[2.2rem] rounded-lg' />
                                    <div>
                                        <p className=' font-bold text-sm'>Started On</p>
                                        <p className='text-sm'>{moment(item?.studentSubscription[0]?.start_date).format("DD MMM YYYY")}</p>
                                    </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <FaCalendarTimes className=' bg-red-600 text-white p-2 text-[2.2rem] rounded-lg' />
                                    <div>
                                        <p className=' font-bold text-sm'>Expires On</p>
                                        <p className='text-sm'>{moment(item?.studentSubscription[item?.studentSubscription.length -1]?.expiry_date).format("DD MMM YYYY")}</p>
                                    </div>
                                </div>
                           </div>
                            </div>
                            </div>
        </AccordionSummary>
        <AccordionDetails>
        <div className="my-2">
            <div className="">
                <div className=" ">
                    {
                      tablist?.length > 0 ? 
                      <>
                      <div className="flex flex-row gap-2 items-center justify-between">
                      <div className="flex flex-row gap-4 items-center ">
                      {
                        tablist.map((e:any)=>(
                          <>  
                            <button onClick={(()=>{setActiveTab(e.id);fetchCourseStats(location.pathname.split('/')[4],e.id,item?.id)})} className={`${activeTab === e.id ? 'hover-purple-gradient text-white' : 'bg-gray-200 dark:bg-secondary'} p-2 font-semibold rounded-md hover:shadow-xl`}>
                              {e.name}
                            </button>
                          
                          </>
                        ))
                        
                        
                      }
                      </div>
                       </div>
                          {
                        activeTab === 0 ? 
                        <div className="mt-2 grid grid-cols-12 gap-2">
                          {
                             courseStatsLoading ? <p className="ml-2 font-bold text-lg">Loading...</p> :
                            courseStats?.length > 0 ?
                              courseStats?.map((e:any, index:number)=>(
                                <div key={index} className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                  <div className="bg-white p-2 rounded-lg flex flex-row justify-between items-center">
                                    <div>
                                      <IoNewspaper className="text-purple-800 text-[3rem]" />
                                    </div>
                                    <div>
                                        <p className="font-bold text-lg">{e.name}</p>
                                        <p className="flex  justify-end">
                                          <p className="text-xl font-bold">
                                          {
                                            e.attempt 
                                          } 
                                          </p>
                                          <p className="text-3xl font-bold">/</p>
                                          <p className="text-2xl font-bold pt-2">
                                         
                                          {
                                            e.totalTest
                                          }
                                          </p>
                                        </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                              :
                            <div className="col-span-12">
                              <p className="text-xl font-bold">No Test Series Found</p>
                            </div>
                          }
                      </div>
                      :null
                      }
                      {
                        activeTab === 1 ? 
                        <div className="mt-2 grid grid-cols-12 gap-2">
                        {
                          courseStatsLoading ? <p className="ml-2 font-bold text-lg">Loading...</p> :
                          courseStats?.length > 0 ?
                            courseStats?.map((e:any, index:number)=>(
                              <div key={index} className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                <div className="bg-white p-2 rounded-lg flex flex-row justify-between items-center">
                                  <div>
                                    <IoNewspaper className="text-purple-800 text-[3rem]" />
                                  </div>
                                  <div>
                                      <p className="font-bold text-lg">{e.name}</p>
                                      <p className="flex  justify-end">
                                        <p className="text-xl font-bold">
                                        {
                                          e.watched 
                                        } 
                                        </p>
                                        <p className="text-3xl font-bold">/</p>
                                        <p className="text-2xl font-bold pt-2">
                                       
                                        {
                                          e.totalVideos
                                        }
                                        </p>
                                      </p>
                                  </div>
                                </div>
                              </div>
                            ))
                            :
                          <div className="col-span-12">
                            <p className="text-xl font-bold">No Videos Found</p>
                          </div>
                        }
                    </div>
                      :null
                      }
                      {
                        activeTab === 2 ? 
                        <div className="mt-2 grid grid-cols-12 gap-2">
                        {
                          courseStatsLoading ? <p className="ml-2 font-bold text-lg">Loading...</p> :
                          courseStats?.length > 0 ?
                            courseStats?.map((e:any, index:number)=>(
                              <div key={index} className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                <div className="bg-white p-2 rounded-lg flex flex-row justify-between items-center">
                                  <div>
                                    <IoNewspaper className="text-purple-800 text-[3rem]" />
                                  </div>
                                  <div>
                                      <p className="font-bold text-lg">{e.name}</p>
                                      <p className="flex  justify-end">
                                        <p className="text-xl font-bold">
                                        {
                                          e.watched 
                                        } 
                                        </p>
                                        <p className="text-3xl font-bold">/</p>
                                        <p className="text-2xl font-bold pt-2">
                                       
                                        {
                                          e.totalVideos
                                        }
                                        </p>
                                      </p>
                                  </div>
                                </div>
                              </div>
                            ))
                            :
                          <div className="col-span-12">
                            <p className="text-xl font-bold">No Videos Found</p>
                          </div>
                        }
                    </div>
                      :null
                      }
                   
                        
                       
                      
                     
                     </>
                      :null
                    }
                </div>
            </div>
      </div>           
        </AccordionDetails>
      </Accordion>
                  ))
                }
              </div>
               :
               <p className='text-xl font-bold'>No Courses Found</p>
            }
           
    </div>
    </>
  )
}
